export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const externalLinks = {
  userProfile: `${process.env.REACT_APP_CLIENT_URL}/athlete/`,
  teamProfile: `${process.env.REACT_APP_CLIENT_URL}/team/`,
  addLeague: `${process.env.REACT_APP_ADMIN_URL}/dashboard/leagues/new`,
  addTeam: `${process.env.REACT_APP_ADMIN_URL}/dashboard/teams/new`,
  addClub: `${process.env.REACT_APP_ADMIN_URL}/dashboard/clubs/new`,
  addNews: `${process.env.REACT_APP_ADMIN_URL}/dashboard/news/new`,
};
