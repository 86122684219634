import { useQuery } from '@apollo/client';
import type { Theme } from '@material-ui/core';
import { Autocomplete, Box, Button, CircularProgress, Drawer, IconButton, List, ListItem, ListItemText, Menu, TextField, Tooltip, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FileCopy } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Scrollbar from 'src/components/Scrollbar';
import { getPlatformLink, USER_ROLES } from 'src/constants';
import { COUNT, SIDEBAR_TOURNAMENTS } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import { useResourceTitle } from 'src/hooks/useResourceTitle';
import { useTournamentPreferences } from 'src/hooks/useTournamentPreferences';
import ChevronDown from 'src/icons/ChevronDown';
import ExternalLink from 'src/icons/ExternalLink';
import ChevronLeftIcon from '../../icons/ChevronLeft';
import ChevronRightIcon from '../../icons/ChevronRight';
import { PrimaryButton, WhiteButton } from '../shared/Buttons';
import CopyModal from './CopyModal';
import LanguagePopover from './LanguagePopover';
import SidebarItem from './SidebarItem';
import CustomButton from '../CustomComponents/CustomButton';
import CustomFormInput from '../CustomComponents/CustomFormInput';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = ({ onMobileClose, openMobile }) => {
  const navigate = useNavigate();
  const { tournamentId } = useAuth();
  const location = useLocation();
  const { user, setTournamentId } = useAuth();
  const id = new URLSearchParams(location.search).get('tournament');

  const auth = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState<any>('');
  const [searchTitle, setSearchTitle] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const debouncedSearch = useDebouncedTerm(searchTitle, 600);
  const preselectTournamentId = id ? parseInt(id) : auth.tournamentId;
  const { t } = useTranslation();
  const [filtersParams, setFiltersParams] = useSearchParams();

  const { data, loading, refetch } = useQuery(SIDEBAR_TOURNAMENTS, {
    variables: {
      page,
      limit: 10,
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
      },
    },
    onCompleted: (data) => {
      const tournamentData = data?.allTournamentNames?.tournaments ?? [];
      setHasMore(tournamentData?.length > 0);
      setTournaments((prevTournaments) => {
        const newTournaments = tournamentData?.filter((tournament) => !prevTournaments.find((prevTournament) => prevTournament?.id === tournament?.id));
        return [...prevTournaments, ...newTournaments];
      });
      if (page === 0 && tournamentData?.length > 0 && !auth.tournamentId) {
        setTournamentId(tournamentData?.[0]?.id, tournamentData?.[0]?.league?.league?.id);
        setSelectedTournament(tournaments?.[0]);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: isLoadingParamTournament } = useQuery(SIDEBAR_TOURNAMENTS, {
    // Skip if tournament is not included in tournaments list, add it
    skip: !preselectTournamentId || tournaments.length === 0 || tournaments?.find((tournament: any) => tournament?.id === preselectTournamentId),
    variables: {
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
        id: preselectTournamentId,
      },
    },
    onCompleted: (data) => {
      setTournaments((prevTournaments) => [...prevTournaments, ...(data?.allTournamentNames?.tournaments ?? [])]);
    },
    fetchPolicy: 'cache-and-network',
  });

  const resourceTitle = useResourceTitle();
  const allowedRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.MANAGER];
  const {
    enableCourts,
    enableStages,
    isEnableRounds,
    displayDraws,
    displayGroups,
    displayLiveStandings,
    displayOrderPlay,
    displayTournamentResults,
    displayListResults,
    displayPendingResults,
    isEnablePairingStandings,
    isEnablePairings,
    isLoadingPreferences,
  } = useTournamentPreferences();

  const findLeague = useMemo(() => {
    return (tournaments, id) => {
      return tournaments?.find((tournament: any) => tournament?.id === id)?.league?.league?.id;
    };
  }, []);

  useEffect(() => {
    refetch();
    setTournaments([]);
    setHasMore(true);
    if (location.pathname === '/' || location.pathname === '') {
      navigate('/dashboard/');
    }
  }, []);

  const { data: countData } = useQuery(COUNT, {
    variables: {
      tournament: Number(auth.tournamentId),
    },
  });

  const tokenParam = new URLSearchParams(window.location.search).get('token');

  let tournamentIdParam = auth.tournamentId ? `?tournament=${auth.tournamentId}` : '';

  const handleGetId = (id: number | string, index?: number) => {
    id && location.pathname !== '/dashboard/all-tournaments/new' && navigate(`${location.pathname}?tournament=${id}`);
    setTournamentId(id, findLeague(tournaments, id));
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        setTournamentId(parseInt(id), findLeague(tournaments, parseInt(id)));
        setSelectedTournament(tournaments?.find((tournament: any) => tournament.id === parseInt(id)));
      }, 500);
    }

    if (tokenParam) {
      setTournamentId(parseInt(id), findLeague(tournaments, parseInt(id)));
    }

    if (auth.tournamentId) {
      setSelectedTournament(tournaments?.find((tournament: any) => tournament.id === auth.tournamentId));
      auth.tournamentId ? setTournamentId(auth.tournamentId, findLeague(tournaments, auth.tournamentId)) : setTournamentId(id, findLeague(tournaments, parseInt(id)));
      if (!id && location.pathname !== '/dashboard/all-tournaments/new') {
        const currentParams = Object.fromEntries(filtersParams.entries());
        setFiltersParams({ tournament: String(auth.tournamentId), ...currentParams });
      }
    }

    if (data?.allTournamentNames?.tournaments?.length === 0 && tournaments?.length === 0) {
      navigate('/dashboard/control-panel/instructions');
    } else if (!auth.tournamentId && !id && location.pathname !== '/dashboard/all-tournaments/new') {
      navigate('/dashboard/all-tournaments');
    }

    if (location.pathname === '/dashboard/all-tournaments/new' && !id) {
      setSelectedTournament(null);
      setTournamentId(null, null);
    }
  }, [auth.tournamentId, id, tokenParam, tournaments]);

  const [sideBarWidth, setSideBarWidth] = useState(250);
  const [openSideBarState, setOpenSideBarState] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [copyModal, setCopyModal] = useState(false);

  const handleOpenSideBar = () => {
    setOpenSideBarState(!openSideBarState);
    if (!openSideBarState) {
      setSideBarWidth(250);
    } else {
      setSideBarWidth(88);
    }
  };

  const handleCopyTournament = () => {
    setCopyModal(true);
  };

  const observer: any = useRef();

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading],
  );

  const content = (
    <Fragment>
      {/* SideBar header */}
      <Box
        sx={{
          position: 'sticky',
          display: 'block',
          background: '#ffff',
          top: 0,
          zIndex: 9,
          px: '24px',
          pt: lgUp ? '24px' : '8px',
        }}
      >
        <CustomButton
          variant="outline"
          size="medium"
          shape="circle"
          sx={{
            position: 'absolute',
            right: '-15px',
            top: 10,
            maxWidth: '28px',
            maxHeight: '28px',
            minWidth: '28px',
            minHeight: '28px',
            padding: '6px',
            borderRadius: '8px',
            gap: '8px',
            display: lgUp ? 'flex' : 'none',
            border: '1px solid #EFEFF0',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
          }}
          onClick={handleOpenSideBar}
        >
          {!openSideBarState ? <ChevronRightIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
        </CustomButton>
        <RouterLink to="/dashboard">
          <Box
            sx={{
              pb: 2,
              borderBottom: '1px solid #EFEFF0',
              mb: 2,
              overflow: 'hidden',
            }}
          >
            {openSideBarState ? <ReactSVG src="/images/darkLogo.svg" /> : <ReactSVG src="/images/tournated-icon.svg" />}
          </Box>
        </RouterLink>
      </Box>

      {/* SideBar Items */}
      <Scrollbar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 4.5,
            alignItems: '',
            padding: openSideBarState ? '0 24px' : '24px 8px',
            justifyContent: 'space-between',
            height: 'calc(100vh - 135px)',
            // overflowY: "auto",
            // px:1
          }}
        >
          <Box>
            {selectedTournament && (
              <CustomButton
                variant="primary"
                size="small"
                sx={{
                  width: '100%',
                }}
                onClick={() => window.open(`${getPlatformLink(selectedTournament?.platform?.id)}/tournament/${auth.tournamentId}?tab=general`)}
              >
                {openSideBarState ? t('Preview Tournament') : <ExternalLink style={{ color: '#86909F' }} />}
              </CustomButton>
            )}

            {openSideBarState ? (
              <Autocomplete
                id="combo-box-demo"
                options={tournaments}
                loading={loading}
                disableClearable
                sx={{
                  mt: '12px',
                }}
                noOptionsText="No tournament found"
                onChange={(e, value) => {
                  if (!value?.id) {
                    setTournamentId(null, null);
                    setSelectedTournament(null);
                    location.pathname !== '/dashboard/all-tournaments/new' && navigate('/dashboard/all-tournaments');
                    return;
                  }
                  navigate(`${location.pathname}?tournament=${value?.id}`);
                  handleGetId(value?.id);
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                value={selectedTournament || null}
                getOptionLabel={(option) => `${option?.title} [${moment.utc(option?.startDate).format('DD MMMM YYYY')} - ${moment.utc(option?.endDate).format('DD MMMM YYYY')}]`}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id} ref={lastOptionElementRef}>
                    {`${option?.title} [${moment.utc(option?.startDate).format('DD MMMM YYYY')} - ${moment.utc(option?.endDate).format('DD MMMM YYYY')}]`}
                  </li>
                )}
                renderInput={(params) => (
                  <CustomFormInput
                    placeholder="Select Tournament"
                    variant="outlined"
                    onChange={(e) => {
                      if (e.target.value === '') setHasMore(true);
                      setPage(0);
                      setSearchTitle(e.target.value);
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading || isLoadingParamTournament ? (
                            <CircularProgress
                              color="inherit"
                              size={16}
                              sx={{
                                background: '#ffff',
                                zIndex: '99',
                                marginRight: '-14px',
                                borderRadius: '50%',
                              }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <Fragment>
                <Button
                  sx={{
                    width: '100%',
                    mt: 3,
                    background: open ? '#FF5733' : '#FAFAFA',
                    border: '1px solid #EFEFF0',
                    borderRadius: '8px',
                  }}
                  id="tournaments-menu"
                  aria-controls="tournaments-menu2"
                  onClick={() => setOpen(true)}
                >
                  <ChevronDown style={{ color: open ? '#FFFF' : '#86909F' }} />
                </Button>
                <Menu
                  id="tournaments-menu2"
                  aria-labelledby="tournaments-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      left: '86px !important',
                      padding: '10px',
                    },
                  }}
                >
                  {tournaments?.map((item, index) => (
                    <ListItem
                      key={index}
                      ref={lastOptionElementRef}
                      button
                      sx={{
                        '& .MuiTypography-root': {
                          fontWeight: '500',
                          color: item.id === selectedTournament?.id ? '#FF5733' : '#86909F',
                        },
                      }}
                      // onClick={() => handleGetId(item.id) , handleGetCount(item.id)}
                      onClick={() => {
                        handleGetId(item.id);
                      }}
                    >
                      <ListItemText
                        primary={`${item.title} [${moment(item?.startDate).format('DD MMMM YYYY')} - ${moment(item?.endDate).format('DD MMMM YYYY')}]`}
                        sx={{
                          color: '#0A2540',
                          fontWeight: '500',
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                          paddingLeft: '16px',
                          textTransform: 'none',
                          width: '100%',
                        }}
                      />
                    </ListItem>
                  ))}
                  {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                      <CircularProgress size={18} />
                    </Box>
                  )}
                </Menu>
              </Fragment>
            )}

            {/* SideBar Dropdown Items */}
            {selectedTournament ? (
              <List>
                <SidebarItem
                  icon="/images/control-panel.svg"
                  text={t('Control Panel')}
                  open={openSideBarState}
                  subitems={[
                    {
                      open: openSideBarState,
                      text: t('Instructions'),
                      path: `/dashboard/control-panel/instructions${tournamentIdParam}`,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('All Tournaments'),
                      path: `/dashboard/all-tournaments${tournamentIdParam}`,
                      isShow: true,
                    },
                  ]}
                />
                <SidebarItem
                  icon="/images/settings-icon.svg"
                  text={t('Settings')}
                  open={openSideBarState}
                  subitems={[
                    {
                      open: openSideBarState,
                      text: t('General Data'),
                      path: `/dashboard/general-data${tournamentIdParam}`,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('Category Settings'),
                      path: `/dashboard/configure-settings${tournamentIdParam}`,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: `${resourceTitle} ${t('Settings')}`,
                      path: `/dashboard/resource-settings${tournamentIdParam}`,
                      // isShow: resourceTitle === 'Stage' ? enableStages : resourceTitle === 'Round' ? isEnableRounds : enableCourts,
                      isShow: resourceTitle === 'Stage' ? enableStages : enableCourts,
                    },
                  ]}
                />
                <SidebarItem
                  icon="/images/entries-icon.svg"
                  text={t('Entries')}
                  open={openSideBarState}
                  subitems={[
                    {
                      open: openSideBarState,
                      text: t('Requests'),
                      path: `/dashboard/requests${tournamentIdParam}`,
                      count: countData?.registrationCount?.pendingRequests,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('Entries'),
                      path: `/dashboard/entries${tournamentIdParam}`,
                      count: countData?.registrationCount?.confirmedRequests,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('Withdrawals'),
                      path: `/dashboard/withdrawals${tournamentIdParam}`,
                      count: countData?.registrationCount?.withdrawnRequests,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('Participants'),
                      path: `/dashboard/participants${tournamentIdParam}`,
                      count: countData?.registrationCount?.confirmedRequests,
                      isShow: true,
                    },
                  ]}
                />
                <SidebarItem
                  icon="/images/matches-icon.svg"
                  text={t('Matches')}
                  open={openSideBarState}
                  isLoading={isLoadingPreferences}
                  subitems={[
                    {
                      open: openSideBarState,
                      text: t('Pairings'),
                      path: `/dashboard/pairings${tournamentIdParam}`,
                      isShow: isEnablePairings,
                    },
                    {
                      open: openSideBarState,
                      text: t('Standings'),
                      path: `/dashboard/pairings-standings${tournamentIdParam}`,
                      isShow: isEnablePairingStandings,
                    },
                    {
                      open: openSideBarState,
                      text: t('Custom Metrics'),
                      path: `/dashboard/custom-metrics${tournamentIdParam}`,
                      isShow: displayListResults,
                    },
                    {
                      open: openSideBarState,
                      text: t('List & Results'),
                      path: `/dashboard/list-results${tournamentIdParam}`,
                      isShow: displayListResults,
                    },
                    {
                      open: openSideBarState,
                      text: t('Groups'),
                      path: `/dashboard/groups${tournamentIdParam}`,
                      isShow: displayGroups,
                    },
                    {
                      open: openSideBarState,
                      text: t('Draws'),
                      path: `/dashboard/draws${tournamentIdParam}`,
                      isShow: displayDraws,
                    },
                    {
                      open: openSideBarState,
                      text: t('Order Of Play'),
                      path: `/dashboard/order-of-play${tournamentIdParam}`,
                      isShow: displayOrderPlay,
                    },
                    {
                      open: openSideBarState,
                      text: t('Live Standings'),
                      path: `/dashboard/live-standings${tournamentIdParam}`,
                      isShow: displayLiveStandings,
                    },
                    {
                      open: openSideBarState,
                      text: t('Final Standings'),
                      path: `/dashboard/tournament-results${tournamentIdParam}`,
                      isShow: displayTournamentResults,
                    },
                  ]}
                />
                <SidebarItem
                  icon="/images/tournament-data-icon.svg"
                  text={t('Tournament Data')}
                  open={openSideBarState}
                  subitems={[
                    // {
                    //   open: openSideBarState,
                    //   text: t('Participants'),
                    //   path: `/dashboard/participants${tournamentIdParam}`,
                    //   isShow: true,
                    // },
                    {
                      open: openSideBarState,
                      text: t('Documents'),
                      path: `/dashboard/documents${tournamentIdParam}`,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: t('Partners'),
                      path: `/dashboard/partners${tournamentIdParam}`,
                      isShow: true,
                    },
                  ]}
                />
                {/* <SidebarItem
                  icon="/images/extra-icon.svg"
                  text="Extras"
                  open={openSideBarState}
                  subitems={[
                    {
                      open: openSideBarState,
                      text: 'Extra 1',
                      path: `/dashboard/extra${tournamentIdParam}`,
                      isShow: true,
                    },
                    {
                      open: openSideBarState,
                      text: 'Extra 2',
                      path: `/dashboard/extra${tournamentIdParam}`,
                      isShow: true,
                    },
                  ]}
                /> */}
              </List>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: '#86909F',
                  fontWeight: 500,
                  my: 20,
                  textAlign: 'center',
                }}
              >
                {t('No tournament selected')}
              </Typography>
            )}
          </Box>
          {selectedTournament && (
            <Tooltip title={t('Make a copy of selected tournament')} placement="top" arrow>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {openSideBarState ? (
                  <WhiteButton onClick={handleCopyTournament} fullWidth sx={{ height: '36px !important', minHeight: '36px !important' }} startIcon={<FileCopy fontSize="small" />}>
                    {t('Copy Tournament')}
                  </WhiteButton>
                ) : (
                  <IconButton onClick={handleCopyTournament} sx={{ color: '#86909F', padding: '8px' }}>
                    <FileCopy fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          )}
          <Box
            sx={{
              display: {
                lg: 'none',
                xs: 'block',
              },
              width: '100%',
              border: '1px solid #eee',
              borderRadius: '6px',
              mt: 2,
            }}
          >
            <LanguagePopover />
          </Box>
        </Box>
      </Scrollbar>
      <CopyModal isOpen={copyModal} setIsOpen={setCopyModal} />
    </Fragment>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={openSideBarState}
        PaperProps={{
          sx: {
            // backgroundColor: "#171819",
            position: 'relative',
            zIndex: 1101,
            overflowY: 'initial',
            borderRight: '1px solid #EFEFF0',

            // height: "calc(100% - 64px) !important",
            // top: "80px !Important",
            // width: 280,
          },
        }}
        sx={{ width: sideBarWidth }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          // backgroundColor: "#171819",
          position: 'relative',
          width: 280,
          py: 2,
          borderRight: '1px solid #EFEFF0',
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
