import { useQuery } from '@apollo/client';
import useAuth from './hooks/useAuth';
import { TOURNAMENT_PREFERENCES } from './graphql/queries';
import { TournamentPreferences } from './types/tournamentPreferences';
import moment from 'moment';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const SPORTS = {
  FISHING: 'Fishing',
};

export const USER_ROLES = {
  SUPER_ADMIN: 'superAdmin',
  USER: 'user',
  ADMIN: 'admin',
  ORGANIZER: 'organizer',
  REFEREE: 'referre',
  ATHLETE: 'athlete',
  FEDERATION: 'federation',
  COACH: 'coach',
  MANAGER: 'manager',
  MEMBER: 'Member',
};

export const formatSeed = (player, segment) => {
  if (!player) return '';

  const { seed, assignedSegment, additionalSegment } = player;

  function getShort(wildCard) {
    switch (wildCard) {
      case 'MDWC':
        return 'WC';
      case 'QWC':
        return 'WC';
      case 'QW':
        return 'Q';
      default:
        return wildCard;
    }
  }

  let result = '';
  let seedSegment = assignedSegment;
  let seedValue = seed;

  if (segment === 'MD' && segment !== 'Q' && additionalSegment) {
    seedSegment = additionalSegment;
    seedValue = additionalSegment === 'QW' ? '' : seed;
  } else if (segment === 'Q' && assignedSegment === 'QW') {
    seedSegment = '';
  } else {
    seedSegment = assignedSegment !== 'Q' && assignedSegment !== 'MD' ? assignedSegment : '';
  }

  if (seedValue || assignedSegment) {
    result += seedValue || seedSegment ? ` [${seedValue || ''}${seedValue && seedSegment ? ', ' : ''}${getShort(seedSegment) || ''}]` : '';
  }

  return result;
};

export const processLongName = (name) => {
  if (name && name.length > 22) {
    const words = name?.trim()?.split(' ');
    return `${words[0][0]}. ${words[words.length - 1]}`;
  } else {
    return name;
  }
};

export const generateDateArray = (start, end) => {
  const startDate = moment.utc(start);
  const endDate = moment.utc(end);

  let currentDate = startDate;
  const dateArray = [];

  while (currentDate <= endDate) {
    dateArray.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'days');
  }
  return dateArray;
};

export const ALL_SPORTS = [
  {
    value: 'Tennis',
    label: 'Tennis',
  },
  {
    label: 'Beach Tennis',
    value: 'Beach Tennis',
  },
  {
    label: 'Beach Volleyball',
    value: 'Beach Volleyball',
  },
  {
    label: 'Padel',
    value: 'Padel',
  },
  {
    label: 'Fishing',
    value: 'Fishing',
  },
];

export const Status = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];
export const LoginSocialIcons = [
  {
    key: 'google',
    path: '/images/loginGoogle.svg',
  },
  {
    key: 'facebook',
    path: '/images/loginFb.svg',
  },
  {
    key: 'twitter',
    path: '/images/loginTwitter.svg',
  },
];

const prodPlatforms = {
  1: 'https://play.tournated.com/',
  // 2: 'At makskri',
  3: 'https://rankiaopr.com/',
  // 4: 'Estonian',
  5: 'https://play.geotennis.ge/',
  // 6: 'Israel Tennis & Education',
  7: 'https://play.teniss.lat/',
};

export const isProd = window.location.host === 'manager.tournated.com';
export const isDev = window.location.host === 'manager.spadasoft.com';

export const getPlatformLink = (platformId: number): string => {
  return isProd ? prodPlatforms[platformId] ?? 'https://play.tournated.com/' : 'https://dev.tournated.com/';
};

export const rowsPerPageOptions = [25, 50, 100, 150];

export const statusOptions = [
  { value: 'Specific Time', label: 'Specific Time' },
  { value: 'Followed By', label: 'Followed By' },
  { value: 'Not Before', label: 'Not Before' },
  { value: 'Not before / After rest', label: 'Not before / After rest' },
  { value: 'Time to be announced / After rest', label: 'Time to be announced / After rest' },
  // { value: 'In Progress', label: 'In Progress' },
];
