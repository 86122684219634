import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Preview Tournament': 'Preview Tournament',
      'Control Panel': 'Control Panel',
      Instructions: 'Instructions',
      'All Tournaments': 'All Tournaments',
      Settings: 'Settings',
      'General Data': 'General Data',
      'Category Settings': 'Category Settings',
      Entries: 'Entries',
      Requests: 'Requests',
      Withdrawals: 'Withdrawals',
      Matches: 'Matches',
      Pairings: 'Pairings',
      Standings: 'Standings',
      'Custom Metrics': 'Custom Metrics',
      'List & Results': 'List & Results',
      Groups: 'Groups',
      Draws: 'Draws',
      'Order Of Play': 'Order Of Play',
      'Live Standings': 'Live Standings',
      'Final Standings': 'Final Standings',
      'Tournament Data': 'Tournament Data',
      Participants: 'Participants',
      Documents: 'Documents',
      Partners: 'Partners',
      'No tournament selected': 'No tournament selected',
      'Copy Tournament': 'Copy Tournament',
      'Make a copy of selected tournament': 'Make a copy of selected tournament',
      Completed: 'Completed',
      Upcoming: 'Upcoming',
      Active: 'Active',
      Total: 'Total',
      'Tournaments list': 'Tournaments list',
      'Tournament Title': 'Tournament Title',
      Search: 'Search',
      'Create new Tournament': 'Create new Tournament',
      Delete: 'Delete',
      Organizer: 'Organizer',
      Location: 'Location',
      'List of categories': 'List of categories',
      'Start - End': 'Start - End',
      Dashboard: 'Dashboard',
      'Athlete Portal': 'Athlete Portal',
      Profile: 'Profile',
      Logout: 'Logout',
      Create: 'Create',
      'Add Tournament': 'Add Tournament',
      'Add League': 'Add League',
      'Add Club': 'Add Club',
      'Add News': 'Add News',
      'Add Team': 'Add Team',
      'Edit Tournament': 'Edit Tournament',
      'Create Tournament': 'Create Tournament',
      editAddDesc: 'Configure general settings, appearance, fact sheet, extra settings, management, and more.',
      'Configure Categories': 'Configure Categories',
      configureCategoriesDesc: 'Specify personal category settings: max number of participants, qualifiers, WC’s, seeds, payment methods, and more.',
      'Manage Entries': 'Manage Entries',
      manageEntriesDesc: 'Confirm/decline registered entries, import entries from CSV template, assign rankings and seeding, update / copy / delete entries and more',
      'Go to Requests': 'Go to Requests',
      'Go to Entries': 'Go to Entries',
      'Create Groups, Draws, and Live Standings': 'Create Groups, Draws, and Live Standings',
      groupsDrawsLiveStandingsDesc:
        'Add and generate groups, draws, and live standings in a couple of clicks. It can be generated automatically based on the selected algorithm or simply created manually from the entry list.',
      'Go to Groups': 'Go to Groups',
      'Go to Draws': 'Go to Draws',
      'Go to Live Standings': 'Go to Live Standings',
      'Go to Order of Play': 'Go to Order of Play',
      'Generate Order of Play': 'Generate Order of Play',
      generateOrderOfPlayDesc:
        "After creating groups/draws, the last step before publishing the matches is the generation of matches (order of play). Identify groups/draws, rounds, time, and courts; generate in a couple of seconds or add matches manually. Your choice. It's easier to work with matches via the draws/groups page.",
      'Publish data': 'Publish data',
      publishDataDesc:
        'To make data available for public you need  ensure that groups, draws, live standings and order of play are published. If you see button “Hide” it means that data is already live and you are good to go.',
      'Submit Tournament Results': 'Submit Tournament Results',
      submitTournamentResultsDesc:
        'The last step to conclude the tournament is to submit tournament results and assign points based on the points table (if the rankings feature is enabled). It can be done automatically via live standings or manually via the tournament results screen.',
      'Add Results Manually': 'Add Results Manually',
      instructionsPageTitle: 'Dashboard: Instructions',
      controlPanelDesc: 'Run the tournament step by step from this control panel',
      General: 'General',
      'Dates & Location': 'Dates & Location',
      Appearance: 'Appearance',
      'Tournament Registration': 'Tournament Registration',
      Extras: 'Extras',
      Management: 'Management',
      'Fact Sheet': 'Fact Sheet',
      'Select League': 'Select League',
      'Select Categories': 'Select Categories',
      'Payment methods': 'Payment methods',
      'Payment type': 'Payment type',
      Currency: 'Currency',
      'Online Entry Fee': 'Online Entry Fee',
      'Entry Fee': 'Entry Fee',
      'Onsite Entry Fee': 'Onsite Entry Fee',
      'Number of Rounds': 'Number of Rounds',
      'Number of Courts': 'Number of Courts',
      'Number of Stages': 'Number of Stages',
      'Tournament timezone': 'Tournament timezone',
      'Registration Start Date': 'Registration Start Date',
      'Start Date': 'Start Date',
      'End Date': 'End Date',
      'Entry Deadline': 'Entry Deadline',
      'Withdrawal Deadline': 'Withdrawal Deadline',
      'Select Club': 'Select Club',
      'No clubs found in selected league': 'No clubs found in selected league',
      'Select Country': 'Select Country',
      City: 'City',
      Address: 'Address',
      Logo: 'Logo',
      logoDesc: 'We recommend an image that is 256 x 256 pixels',
      'Tournament title': 'Tournament title',
      'Enter tournament title': 'Enter tournament title',
      Description: 'Description',
      tournamentDesc: 'Write a description about tournament',
      'Video URL': 'Video URL',
      videoLink: 'Insert a video link here',
      tourWebUrl: 'Tournament Website URL',
      'Tournament Poster': 'Tournament Poster',
      'Upload Poster': 'Upload Poster',
      'Cover Banner': 'Cover Banner',
      bannerDesc: 'We recommend an image that is 2400 x 600 pixels.',
      'Upload Banner': 'Upload Banner',
      Regulations: 'Regulations',
      'Upload Regulations': 'Upload Regulations',
      'No Image': 'No Image',
      dragDropDesc: 'Drag and Drop file here, or click to Select file',
      dragDropDesc2: 'Drag and Drop files here, or click to Select files',
      'Select File': 'Select File',
      'Additional Questions': 'Additional Questions',
      'Add New Question': 'Add New Question',
      'Terms and Conditions': 'Terms and Conditions',
      'Start Typing': 'Start Typing',
      'External tournament registration link': 'External tournament registration link',
      'Automatically confirm entries': 'Automatically confirm entries',
      confirmEntriesInfo: 'Tournament registrations entries will directly go to entries not to requests',
      closeRegistration: 'Close registration for all tournament categories',
      'Edit this question': 'Edit this question',
      'Add new question': 'Add new question',
      'Question updated successfully!': 'Question updated successfully!',
      'Question added successfully!': 'Question added successfully!',
      Question: 'Question',
      'Field Type': 'Field Type',
      Price: 'Price',
      'Field Option': 'Field Option',
      removeQuestionError: 'At least 1 field option is required for field type select',
      Remove: 'Remove',
      'Add new option': 'Add new option',
      questionInfo: 'Ask this question to each athlete',
      Submit: 'Submit',
      isFeatured: 'Make this tournament featured',
      partnerSearch: 'Enable partner search',
      enablePracticeCourtsBooking: 'Enable practice courts booking',
      showTournamentChessManagerCalendar: "Show this tournament on chess manager's calendar",
      localRatedTournament: 'Local rated tournament',
      fideRatedTournament: 'FIDE rated tournament',
      enableTransferBooking: 'Enable transfer booking',
      enableLiveStream: 'Enable live stream (coming soon)',
      enableLiveScoring: 'Enable live scoring (coming soon)',
      'Select Organizer': 'Select Organizer',
      'No Organizer available': 'No Organizer available',
      'Select main referee': 'Select main referee',
      'Select referee': 'Select referee',
      'No referee available': 'No referee available',
      'Select additional referees': 'Select additional referees',
      'Tournament status': 'Tournament status',
      'Make tournament private': 'Make tournament private',
      'Set Password': 'Set Password',
      'New Password': 'New Password',
      'Confirm Password': 'Confirm Password',
      'No facts found': 'No facts found',
      Save: 'Save',
      Back: 'Back',
      'Field Title': 'Field Title',
      'Dashboard: Stage Settings': 'Dashboard: Stage Settings',
      'Edit tournament': 'Edit tournament',
      'Change Settings': 'Change Settings',
      Title: 'Title',
      Status: 'Status',
      'General Limit': 'General Limit',
      'Individual metric limit': 'Individual metric limit',
      'Allow participants to add results': 'Allow participants to add results',
      'Attachments status': 'Attachments status',
      'There is no available': 'There is no available',
      'Public for everyone': 'Public for everyone',
      'Public only for participants': 'Public only for participants',
      Hidden: 'Hidden',
      'Hide other participant results': 'Hide other participant results',
      'Individual metric limit applied': 'Individual metric limit applied',
      'Attachments Status': 'Attachments Status',
      'Public to participants only': 'Public to participants only',
      Saving: 'Saving',
      'General data': 'General data',
      'Category settings': 'Category settings',
      'Dashboard: Category Settings': 'Dashboard: Category Settings',
      'There is no available category!': 'There is no available category!',
      Category: 'Category',
      'Online Fee': 'Online Fee',
      'Onsite Fee': 'Onsite Fee',
      'VAT Amount': 'VAT Amount',
      'Payment Type': 'Payment Type',
      'Payment Method': 'Payment Method',
      Rounds: 'Rounds',
      Time: 'Time',
      'Time per move': 'Time per move',
      'Time after move': 'Time after move',
      'Extra time': 'Extra time',
      Pairing: 'Pairing',
      entries: 'entries',
      'Registration Status': 'Registration Status',
      'Additional service fee': 'Additional service fee',
      'Registration Limit': 'Registration Limit',
      Edit: 'Edit',
      'Registrations Limit': 'Registrations Limit',
      'Include VAT amount?': 'Include VAT amount?',
      displayClubInfo: 'Display athlete/team club name in entry details',
      Open: 'Open',
      Closed: 'Closed',
      categorySettingsInfo: 'Set data only which you want to update for selected categories',
      'Select category color': 'Select category color',
      'Dashboard: Requests': 'Dashboard: Requests',
      'No category found': 'No category found',
      'Add New Request': 'Add New Request',
      Actions: 'Actions',
      Confirming: 'Confirming',
      'Confirm Selected Requests': 'Confirm Selected Requests',
      NR: 'NR',
      Team: 'Team',
      Player: 'Player',
      Rating: 'Rating',
      Email: 'Email',
      'Phone Number': 'Phone Number',
      'Reg. time': 'Reg. time',
      Paid: 'Paid',
      'ADDITIONAL QUESTIONS': 'ADDITIONAL QUESTIONS',
      'Show Answer': 'Show Answer',
      'Download requests list': 'Download requests list',
      Decline: 'Decline',
      'Confirm Decline': 'Confirm Decline',
      requestDeclineWarning: 'Are you sure you want to decline the selected requests?',
      'Copy Selected Requests': 'Copy Selected Requests',
      'No questions found': 'No questions found',
      'Answer/Files': 'Answer/Files',
      Download: 'Download',
      'Edit Entries successfully': 'Edit Entries successfully',
      'Tournament data or category not available': 'Tournament data or category not available',
      'Please mark the Checkbox': 'Please mark the Checkbox',
      'Confirming...': 'Confirming...',
      'Confirmed successfully!': 'Confirmed successfully!',
      'Please enter both player emails': 'Please enter both player emails',
      'Please enter player email': 'Please enter player email',
      'Step 1: Select category': 'Step 1: Select category',
      'Athlete email (user account)': 'Athlete email (user account)',
      'Player is not registered in the platform': 'Player is not registered in the platform',
      'First athlete email (user account)': 'First athlete email (user account)',
      'First player is not registered in the platform': 'First player is not registered in the platform',
      'Second athlete email (user account)': 'Second athlete email (user account)',
      'Second player is not registered in the platform': 'Second player is not registered in the platform',
      Update: 'Update',
      'No Team': 'No Team',
      'Athlete email is required': 'Athlete email is required',
      'First athlete email is required': 'First athlete email is required',
      'Second athlete email is required': 'Second athlete email is required',
      'Add Entries successfully': 'Add Entries successfully',
      'Select category': 'Select category',
      'No category exists': 'No category exists',
      Registration: 'Registration',
      Register: 'Register',
      'There is no available requests!': 'There is no available requests!',
      'Dashboard: Entries Data': 'Dashboard: Entries Data',
      'Request Updated Successfully!': 'Request Updated Successfully!',
      'Import CSV File': 'Import CSV File',
      'Import Entries': 'Import Entries',
      'Add New Entry': 'Add New Entry',
      'Entries deleted successfully.': 'Entries deleted successfully.',
      'Confirm Deletion': 'Confirm Deletion',
      deleteEntriesWarning: 'Are you sure you want to delete the selected entries?',
      'PLAYER 1': 'PLAYER 1',
      'PLAYER 2': 'PLAYER 2',
      Rank: 'Rank',
      'Total Ranks': 'Total Ranks',
      Priority: 'Priority',
      Assign: 'Assign',
      'Limit reached': 'Limit reached',
      'Assigning...': 'Assigning...',
      'Removing...': 'Removing...',
      'Moving...': 'Moving...',
      'Updated successfully': 'Updated successfully',
      'Please enter a value': 'Please enter a value',
      'Updating...': 'Updating...',
      'Please enter a rating': 'Please enter a rating',
      'Edit Rating': 'Edit Rating',
      'Please enter a rank': 'Please enter a rank',
      'Edit Rank': 'Edit Rank',
      'Change Category': 'Change Category',
      'Move selected entries to Requests': 'Move selected entries to Requests',
      'Withdraw selected entries': 'Withdraw selected entries',
      'Delete rankings': 'Delete rankings',
      'Update rankings & seeding': 'Update rankings & seeding',
      'Download entry List': 'Download entry List',
      withdrawEntriesWarning: 'Are you sure you want to withdraw selected entries?',
      'File uploaded & entries created successfully': 'File uploaded & entries created successfully',
      'Please select a file to Upload': 'Please select a file to Upload',
      'Import entries via CSV template': 'Import entries via CSV template',
      'Download CSV template for Singles format category': 'Download CSV template for Singles format category',
      'CSV file Downloaded': 'CSV file Downloaded',
      'Download CSV template for Doubles format category': 'Download CSV template for Doubles format category',
      'Upload & create entries': 'Upload & create entries',
      'There is no available entries!': 'There is no available entries!',
      'Entry Fee is Required Field': 'Entry Fee is Required Field',
      'Type is Required Field': 'Type is Required Field',
      'Payment Information Removed Successfully': 'Payment Information Removed Successfully',
      'Paid Information Added Successfully': 'Paid Information Added Successfully',
      'Update Paid information': 'Update Paid information',
      'Paid information': 'Paid information',
      'Amount of entry fee': 'Amount of entry fee',
      Type: 'Type',
      'Remove payment': 'Remove payment',
      'Drag and Drop a file here, or click to Select file': 'Drag and Drop a file here, or click to Select file',
      'Supported file': 'Supported file',
      'Dashboard: Withdrawals Data': 'Dashboard: Withdrawals Data',
      'Entries Deleted Successfully!': 'Entries Deleted Successfully!',
      'Deleting...': 'Deleting...',
      'There is no available withdrawals!': 'There is no available withdrawals!',
      deleteWithdrawalsWarning: 'Are you sure you want to delete selected entries?',
      'Move selected entries to Entry list': 'Move selected entries to Entry list',
      'Dashboard: Groups Data': 'Dashboard: Groups Data',
      'Back to tournament': 'Back to tournament',
      'Submit results to Live standings': 'Submit results to Live standings',
      'Please select at least one group': 'Please select at least one group',
      'Live standings submitted successfully': 'Live standings submitted successfully',
      'Submit Live Standings': 'Submit Live Standings',
      'Select Category': 'Select Category',
      'Select Groups...': 'Select Groups...',
      'Please select at least one Group.': 'Please select at least one Group.',
      'Standings Title': 'Standings Title',
      Sorting: 'Sorting',
      'Removing groups...': 'Removing groups...',
      'Groups removed successfully': 'Groups removed successfully',
      Hiding: 'Hiding',
      Publishing: 'Publishing',
      Group: 'Group',
      Successfully: 'Successfully',
      Published: 'Published',
      Hide: 'Hide',
      Publish: 'Publish',
      'Updating scores...': 'Updating scores...',
      'Scores updated successfully': 'Scores updated successfully',
      'Deleting group...': 'Deleting group...',
      'Group Deleted successfully': 'Group Deleted successfully',
      'Deleting entries...': 'Deleting entries...',
      'Entries deleted successfully': 'Entries deleted successfully',
      'Delete Selected Entries': 'Delete Selected Entries',
      'Delete Selected Teams': 'Delete Selected Teams',
      'Publish this group': 'Publish this group',
      'Hide this group': 'Hide this group',
      'Print/Download this group': 'Print/Download this group',
      Recover: 'Recover',
      'this group': 'this group',
      'Add team to this group': 'Add team to this group',
      'Add team': 'Add team',
      'Update places for entries': 'Update places for entries',
      'Update Places': 'Update Places',
      'Generate OOP For This Group Only': 'Generate OOP For This Group Only',
      'Create Matches for this group': 'Create Matches for this group',
      'Create Matches': 'Create Matches',
      POINTS: 'POINTS',
      SR: 'SR',
      PR: 'PR',
      PLACE: 'PLACE',
      Place: 'Place',
      'Match Time Conflict': 'Match Time Conflict',
      'Winner is required when score is provided': 'Winner is required when score is provided',
      'Match cannot be unfinished when score is provided': 'Match cannot be unfinished when score is provided',
      'Space at the end is not allowed': 'Space at the end is not allowed',
      'Please follow the pattern': 'Please follow the pattern',
      'Date is required Field': 'Date is required Field',
      'Time is required Field': 'Time is required Field',
      'Add Score Successfully': 'Add Score Successfully',
      'Select Winner': 'Select Winner',
      Athlete: 'Athlete',
      'Additional match status options': 'Additional match status options',
      Deselect: 'Deselect',
      Date: 'Date',
      'Start Time': 'Start Time',
      'Match Status': 'Match Status',
      'Delete match from oop': 'Delete match from oop',
      'This Group is Deleted': 'This Group is Deleted',
      groupRecoverInfo: 'If you think the group was deleted by mistake, you can recover it by clicking on the recover button in only 1 day',
      'There is no available groups': 'There is no available groups',
      DATE: 'DATE',
      COURT: 'COURT',
      TEAM1: 'TEAM1',
      TEAM2: 'TEAM2',
      RESULT: 'RESULT',
      Court: 'Court',
      Score: 'Score',
      'Add Score': 'Add Score',
      'Delete group Match from oop': 'Delete group Match from oop',
      'Edit Match': 'Edit Match',
      'Match Updated Successfully': 'Match Updated Successfully',
      'Edit Match Date & Time': 'Edit Match Date & Time',
      'Match Date Updated Successfully': 'Match Date Updated Successfully',
      'Date is Required Field': 'Date is Required Field',
      'Time is Required Field': 'Time is Required Field',
      'Order of Play': 'Order of Play',
      'Please select at least one court': 'Please select at least one court',
      'Generate OOP Successfully': 'Generate OOP Successfully',
      'Generate order of play for': 'Generate order of play for',
      'Number of teams': 'Number of teams',
      'Number of matches': 'Number of matches',
      'Add Rounds...': 'Add Rounds...',
      'Please select at least one Round.': 'Please select at least one Round.',
      Add: 'Add',
      'Please select at least one.': 'Please select at least one.',
      'Approximate match duration': 'Approximate match duration',
      'in minutes': 'in minutes',
      GENERATE: 'GENERATE',
      'Group Edited Successfully': 'Group Edited Successfully',
      'Edit Group': 'Edit Group',
      'Group Name': 'Group Name',
      'Display order of play under the group': 'Display order of play under the group',
      'Display list of matches under the group': 'Display list of matches under the group',
      Comment: 'Comment',
      'Group Name is Required Field': 'Group Name is Required Field',
      'Entry is required': 'Entry is required',
      'Adding Team...': 'Adding Team...',
      'Team Added Successfully': 'Team Added Successfully',
      'Add team to group': 'Add team to group',
      'OOP generated successfully': 'OOP generated successfully',
      'Matches created successfully': 'Matches created successfully',
      'Generate OOP': 'Generate OOP',
      Duration: 'Duration',
      'No courts available': 'No courts available',
      'Update Entry': 'Update Entry',
      'Update Entry successfully': 'Update Entry successfully',
      Cancel: 'Cancel',
      deleteGroupWarning: 'Are you sure you want to delete this Group?',
      deleteGroupEntryWarning: 'Are you sure you want to delete this entry?',
      deleteGroupEntriesWarning: 'Are you sure you want to delete these Entries?',
      'Groups deleted successfully.': 'Groups deleted successfully.',
      'Edit Selected Groups': 'Edit Selected Groups',
      'Delete Selected Groups': 'Delete Selected Groups',
      deleteGroupsWarning: 'Are you sure you want to delete the selected groups?',
      'Select Entries': 'Select Entries',
      'PDF being generated...': 'PDF being generated...',
      'Failed to generate PDF': 'Failed to generate PDF',
      'Generating PDF...': 'Generating PDF...',
      'Please wait...': 'Please wait...',
      'All groups Published successfully': 'All groups Published successfully',
      'All groups Unpublished successfully': 'All groups Unpublished successfully',
      'PUBLISH GROUPS': 'PUBLISH GROUPS',
      'HIDE GROUPS': 'HIDE GROUPS',
      'No segment found': 'No segment found',
      'All Segments': 'All Segments',
      'All Categories': 'All Categories',
      'Generate OOP for all groups': 'Generate OOP for all groups',
      'Add Group': 'Add Group',
      'Download PDF': 'Download PDF',
      'Export matches for DUPR': 'Export matches for DUPR',
      'Publish All Groups': 'Publish All Groups',
      'Unpublish All Groups': 'Unpublish All Groups',
      'Please select at least one Court.': 'Please select at least one Court.',
      'Select Groups': 'Select Groups',
      'All Groups': 'All Groups',
      'Match duration is Required Field': 'Match duration is Required Field',
      'Round is Required Field': 'Round is Required Field',
      'Courts is Required Field': 'Courts is Required Field',
      'Print/Download All group': 'Print/Download All group',
      'Category is Required Field': 'Category is Required Field',
      'Segment is a Required Field': 'Segment is a Required Field',
      'Group Type is Required Field': 'Group Type is Required Field',
      'Groups Name is Required Field': 'Groups Name is Required Field',
      'Groups Naming is Required Field': 'Groups Naming is Required Field',
      'Please enter a 2-digit number.': 'Please enter a 2-digit number.',
      'No of groups is Required Field': 'No of groups is Required Field',
      'Algorithm is Required Field': 'Algorithm is Required Field',
      'Generate Groups is Required Field': 'Generate Groups is Required Field',
      'Add Group Successfully': 'Add Group Successfully',
      'Add Groups': 'Add Groups',
      'Select segment': 'Select segment',
      'Groups type': 'Groups type',
      'Number of groups': 'Number of groups',
      'Groups naming': 'Groups naming',
      'Select Round': 'Select Round',
      'Generate Groups': 'Generate Groups',
      'Select Algorithm': 'Select Algorithm',
      'Tournament copied successfully': 'Tournament copied successfully',
      'Title is required': 'Title is required',
      'Start date is required': 'Start date is required',
      'End date cannot be before start date': 'End date cannot be before start date',
      'Entry deadline is required': 'Entry deadline is required',
      'Withdrawl deadline is required': 'Withdrawl deadline is required',
      'Dashboard: Partners': 'Dashboard: Partners',
      'Add New Partners': 'Add New Partners',
      'No Partners data!': 'No Partners data!',
      'Showing dates': 'Showing dates',
      Text: 'Text',
      Link: 'Link',
      'Dashboard: Documents Data': 'Dashboard: Documents Data',
      'League Regulations': 'League Regulations',
      'No regulations uploaded yet': 'No regulations uploaded yet',
      'Tournament Regulations': 'Tournament Regulations',
      'Uploading...': 'Uploading...',
      'Regulations uploaded successfully': 'Regulations uploaded successfully',
      'Error uploading regulations': 'Error uploading regulations',
      'Dashboard: Participants Data': 'Dashboard: Participants Data',
      'Status: ': 'Status: ',
      'Show All': 'Show All',
      'No status found': 'No status found',
      'Export User Emails': 'Export User Emails',
      'Export Users': 'Export Users',
      'PLAYER NAME': 'PLAYER NAME',
      COUNTRY: 'COUNTRY',
      'There is no participants data!': 'There is no participants data!',
      'Dashboard: Final Standings': 'Dashboard: Final Standings',
      'Add Final Results Manually': 'Add Final Results Manually',
      'There is no available tournament results': 'There is no available tournament results',
      'Final results added successfully': 'Final results added successfully',
      Points: 'Points',
      'Select place': 'Select place',
      'Result deleted successfully': 'Result deleted successfully',
      'Duplicate entries & assign points to another category': 'Duplicate entries & assign points to another category',
      'Duplicate & Assign': 'Duplicate & Assign',
      'Hide Results': 'Hide Results',
      'Publish Results': 'Publish Results',
      'Delete selected entries': 'Delete selected entries',
      'Delete results block': 'Delete results block',
      Entry: 'Entry',
      'Download List': 'Download List',
      'Add new result': 'Add new result',
      'Entry Deleted Successfully': 'Entry Deleted Successfully',
      'Points is required': 'Points is required',
      'Place is required': 'Place is required',
      'Please select a entry': 'Please select a entry',
      'Updated Successfully': 'Updated Successfully',
      'Edit Entry': 'Edit Entry',
      'Delete Entry': 'Delete Entry',
      deleteRankingEntryWarning: 'Are you sure you want to delete this Entry?',
      'Select Pair': 'Select Pair',
      'Select Player': 'Select Player',
      'No ranking points found': 'No ranking points found',
      'Select Ranking Point block': 'Select Ranking Point block',
      'To Category': 'To Category',
      'From Category': 'From Category',
      'Duplicate entries & points to another category': 'Duplicate entries & points to another category',
      'Entries & points copied successfully': 'Entries & points copied successfully',
      'From Category is required': 'From Category is required',
      'To Category is required': 'To Category is required',
      'Ranking Point is required': 'Ranking Point is required',
      deleteResultsWarning: 'Are you sure you want to delete these results?',
      'Dashboard: Live Standings Data': 'Dashboard: Live Standings Data',
      'Segment: ': 'Segment: ',
      'No segments found': 'No segments found',
      'Add Live Standings': 'Add Live Standings',
      'Submit final Results': 'Submit final Results',
      'There is no available live standings': 'There is no available live standings',
      'Groups is Required Field': 'Groups is Required Field',
      'Standings Title is Required Field': 'Standings Title is Required Field',
      'Sorting is Required Field': 'Sorting is Required Field',
      'Live standings added successfully': 'Live standings added successfully',
      'Live Standing is Required Field': 'Live Standing is Required Field',
      'Results added successfully': 'Results added successfully',
      'Submit Results to Rankings': 'Submit Results to Rankings',
      'Select Live Standing': 'Select Live Standing',
      'No Live Standings found': 'No Live Standings found',
      'Deleting live standings...': 'Deleting live standings...',
      'Live standings deleted successfully.': 'Live standings deleted successfully.',
      'Delete Selected Live Standings': 'Delete Selected Live Standings',
      deleteLivesStandingsWarning: 'Are you sure you want to delete the selected live standings?',
      'Generating CSV...': 'Generating CSV...',
      'CSV being generated...': 'CSV being generated...',
      'Failed to generate CSV': 'Failed to generate CSV',
      'Dashboard: Order Of Play': 'Dashboard: Order Of Play',
      Grid: 'Grid',
      List: 'List',
      "Bird's View": "Bird's View",
      'Hide Order of Play': 'Hide Order of Play',
      'Publish Order of Play': 'Publish Order of Play',
      'Hide OOP': 'Hide OOP',
      'Publish OOP': 'Publish OOP',
      'Enter Name': 'Enter Name',
      'There is no available order of play': 'There is no available order of play',
      'Export OOP for DUPR': 'Export OOP for DUPR',
      'Deleting Matches...': 'Deleting Matches...',
      'Matches Deleted Successfully!': 'Matches Deleted Successfully!',
      'Delete Selected Matches': 'Delete Selected Matches',
      deleteMatchesWarning: 'Are you sure you want to delete selected matches?',
      'Score is not set': 'Score is not set',
      'TEAM 1': 'TEAM 1',
      'TEAM 2': 'TEAM 2',
      RESULTS: 'RESULTS',
      'Delete Match Successfully': 'Delete Match Successfully',
      'Court is required': 'Court is required',
      'Edit Score Successfully': 'Edit Score Successfully',
      'Edit Draw Match': 'Edit Draw Match',
      'Optional match details': 'Optional match details',
      'Delete match': 'Delete match',
      'Confirm Match Deletion': 'Confirm Match Deletion',
      deleteDrawMatchWarning: 'Are you sure you want to delete this match? Once match is deleted it will disappears from OOP, and match winner/looser being deleted from future round(s)',
      'Add score instructions': 'Add score instructions',
      scoreInstructionsInfo1: 'Set score to empty field or match winner to "Match not finished" and status of match will be automatically changed to "Upcoming"',
      scoreInstructionsInfo2: "*Games should be divided with ':' symbol (colon). Example: 6:4 6:7(2) [10:6]",
      scoreInstructionsInfo3: '**Tiebreak (if played) should be included in parenthesis "()" and go after game without space. Example: 7:6(4) 7:6(5)',
      scoreInstructionsInfo4: '***Deciding tiebreak (if played) should be included in square brackets "[]". Example: 6:4 6:7(5) [10:6]',
      'Edit Match Successfully': 'Edit Match Successfully',
      'Edit Group Match': 'Edit Group Match',
      'Dashboard: Draw': 'Dashboard: Draw',
      'Draw is Required Field': 'Draw is Required Field',
      'Please add points to submit': 'Please add points to submit',
      'Select Draw': 'Select Draw',
      'No Draws found': 'No Draws found',
      'All draws': 'All draws',
      'Publish Draws': 'Publish Draws',
      'Hide Draws': 'Hide Draws',
      'Generate Order Of Play': 'Generate Order Of Play',
      'Add new Draw': 'Add new Draw',
      'Draw templates is Required Field': 'Draw templates is Required Field',
      'Draw title is Required Field': 'Draw title is Required Field',
      'Generate draw is Required Field': 'Generate draw is Required Field',
      'Live Standings is Required Field': 'Live Standings is Required Field',
      'Start Live Standings is Required Field': 'Start Live Standings is Required Field',
      'End Live Standings is Required Field': 'End Live Standings is Required Field',
      'Draw type is Required Field': 'Draw type is Required Field',
      'Draw segment is Required Field': 'Draw segment is Required Field',
      'Qual winners is Required Field': 'Qual winners is Required Field',
      'No of seeds is Required Field': 'No of seeds is Required Field',
      'Regenerate Draw Successfully': 'Regenerate Draw Successfully',
      'Add Draw Successfully': 'Add Draw Successfully',
      'Draw title': 'Draw title',
      'Draw segment': 'Draw segment',
      'Templates size': 'Templates size',
      'Add Match for 3rd Place': 'Add Match for 3rd Place',
      'Qual Winners': 'Qual Winners',
      'Generate Draw': 'Generate Draw',
      'Main Draw': 'Main Draw',
      'No Main Draw found': 'No Main Draw found',
      'Main Draw Entries': 'Main Draw Entries',
      'Select Live Standings': 'Select Live Standings',
      'Loading...': 'Loading...',
      'Live Standings from': 'Live Standings from',
      'Live Standings to': 'Live Standings to',
      'Algo Type': 'Algo Type',
      'Regenerate Draw': 'Regenerate Draw',
      'Add Draw': 'Add Draw',
      deleteDrawWarning: 'All draw entries and matches will be removed and draw newly generated. Are you sure you want to regenerate draw?',
      Warning: 'Warning',
      Confirm: 'Confirm',
      'Generate order of play Successfully': 'Generate order of play Successfully',
      'Generate order of play': 'Generate order of play',
      'Select Draws': 'Select Draws',
      'Select Rounds': 'Select Rounds',
      'Time Settings': 'Time Settings',
      Select: 'Select',
      'PDF Generated Successfully!': 'PDF Generated Successfully!',
      'Publishing draw...': 'Publishing draw...',
      'Hiding draw...': 'Hiding draw...',
      'Successfully!': 'Successfully!',
      'Regenerate this draw': 'Regenerate this draw',
      editDrawNameInfo: 'If you are looking to change draw size or any other draw setting, you have to remove draw and add new one again.',
      'Draw Name': 'Draw Name',
      'Edit Draw': 'Edit Draw',
      'Draw Name Edited Successfully': 'Draw Name Edited Successfully',
      'Draw Name is required': 'Draw Name is required',
      'Type is required': 'Type is required',
      'Publish this draw': 'Publish this draw',
      'Hide this draw': 'Hide this draw',
      'There is no available draws': 'There is no available draws',
      'Draws deleted successfully.': 'Draws deleted successfully.',
      'Delete Selected Draws': 'Delete Selected Draws',
      deleteDrawsWarning: 'Are you sure you want to delete the selected draws?',
      deleteDrawEntryWarning: 'Are you sure you want to delete this Entry?',
      'Select a Entry': 'Select a Entry',
      'Edit team One': 'Edit team One',
      'Edit team Two': 'Edit team Two',
      'Team Edited Successfully': 'Team Edited Successfully',
      'Deleting Entry...': 'Deleting Entry...',
      'Add team One to this Match': 'Add team One to this Match',
      'Add team Two to this Match': 'Add team Two to this Match',
      'Team Added to Seed Successfully': 'Team Added to Seed Successfully',
      'Score is Required Field': 'Score is Required Field',
      'Match Score Added Successfully': 'Match Score Added Successfully',
      'Match score': 'Match score',
      'Edit score': 'Edit score',
      'All Draws are hidden': 'All Draws are hidden',
      Upload: 'Upload',
      'Upload File': 'Upload File',
      'Upload File in OOP': 'Upload File in OOP',
      'Upload Document': 'Upload Document',
      'Document Name': 'Document Name',
      'Name is required': 'Name is required',
      'Are you sure you want to delete this document?': 'Are you sure you want to delete this document?',
      deleteGroupMatchWarning: 'Are you sure you want to delete this match?',
      deleteMultipleResultsWarning: 'Are you sure you want to delete these results?',
      'How to Set Up a Tournament Draw?': 'How to Set Up a Tournament Draw?',
      setupTournament1: 'Once you have indicated the draw category and title, follow these steps to set up the tournament draw:',
      'Select the Segment:': 'Select the Segment:',
      'Choose from': 'Choose from',
      'Select the Draw Type:': 'Select the Draw Type:',
      'Single Elimination:': 'Single Elimination:',
      'Participants are eliminated after their first loss': 'Participants are eliminated after their first loss',
      'Double Elimination:': 'Double Elimination:',
      'Participants are eliminated after two losses': 'Participants are eliminated after two losses',
      'Generate the Draw:': 'Generate the Draw:',
      'Choose the source for generating the draw:': 'Choose the source for generating the draw:',
      'Use the list of registered participants': 'Use the list of registered participants',
      'Use standings based on group results': 'Use standings based on group results',
      setupTournament2: 'Generate a blank draw and manually add participants to positions.',
      setupTournament3: 'is selected, you will have an additional option to generate the draw from the',
      setupTournament4: 'In this case, you need to select the draw from which to generate.',
      setupTournament5: 'The system will automatically select participants who lost their first match, with the option for referees to adjust the selection as needed.',
      setupTournament6: 'Set the Generation Algorithm (if selected source is not manual):',
      setupTournament7: 'Follows international federation rules, incorporating seeding and randomization.',
      setupTournament8: 'For example, in a draw of 16 participants, the first seed plays against the 16th seed, the second seed against the 15th seed, and so on.',
      'How to Generate the Order of Play?': 'How to Generate the Order of Play?',
      'To generate the Order of Play automatically': 'To generate the Order of Play automatically',
      Click: 'Click',
      'in the draw navigation.': 'in the draw navigation.',
      'Select the specific': 'Select the specific',
      'on which the matches will be set': 'on which the matches will be set',
      'Specify the approximate duration of each match': 'Specify the approximate duration of each match',
      setupTournament9: 'Once the matches are generated, you can adjust the details in three different views',
      'Sorted by match time': 'Sorted by match time',
      setupTournament10: 'Courts as column names with matches listed under the associated court',
      setupTournament11: 'Displays only category names and colors to provide an overview of the Order of Play',
      setupTournament12: 'If you need to add details for matches manually, click the',
      setupTournament13: 'under the match and set all required details',
      'How to Enter Results?': 'How to Enter Results?',
      'To enter match results': 'To enter match results',
      setupOOP1: 'Select the match you want to update (inside the draw or Order of Play).',
      'Set the winner and the score': 'Set the winner and the score',
      'Ensure you format the score correctly': 'Ensure you format the score correctly',
      "Always start with the winner's score": "Always start with the winner's score",
      'Separate sets with a space': 'Separate sets with a space',
      'Separate games within a set with a colon': 'Separate games within a set with a colon',
      'Indicate a set tiebreak in parentheses without a space after the set': 'Indicate a set tiebreak in parentheses without a space after the set',
      'Indicate a super tiebreak set in square brackets with a space before it': 'Indicate a super tiebreak set in square brackets with a space before it',
      Example: 'Example',
      'Draws are not available to the public until you click': 'Draws are not available to the public until you click',
      setupDraw1: 'You can publish all draws at once or individually. Note that any new draw is hidden from the public by default',
      'Delete Draws': 'Delete Draws',
      'To delete a draw': 'To delete a draw',
      'Check the checkbox next to the draw title': 'Check the checkbox next to the draw title',
      'Automatically Submit Final Results (Place / Points)': 'Automatically Submit Final Results (Place / Points)',
      'To submit final results automatically, follow these steps': 'To submit final results automatically, follow these steps',
      setupDraw2: 'In the "Draws" section, locate and click the "Submit Final Results" button in the top right corner',
      'Choose the draw from which you want to get the positions': 'Choose the draw from which you want to get the positions',
      setupDraw3: 'Check the positions to ensure everything is correct. If you notice any inaccuracies, feel free to manually adjust the data as needed',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'Alternatively - Submit Final Results Manually on the "Final Standings" page',
      drawExtra1: 'To display the club name of participants and distribute club members across the draw, select the appropriate checkboxes',
      'You can download and print the draw': 'You can download and print the draw',
      'For pickleball, you can export matches in the correct format for DUPR': 'For pickleball, you can export matches in the correct format for DUPR',
      wildCardQuestion: "How to accurately count the number of entries for the main draw, qualifications, seedings, and wild cards (WC's)?",
      wildCardAnswer:
        "To accurately count the number of entries for the main draw, qualifications, seedings, and wild cards (WC's), please ensure that you have correctly set up the tournament category settings. You can do this by navigating to",
      wildCardAnswer1: 'Settings > Category Settings.',
      wildCardAnswer2: 'In the same place, you can adjust accepted payment methods, entry fees, registration limits, and more.',
      'How to auto confirm entries?': 'How to auto confirm entries?',
      confirmEntries1: 'To automatically confirm entries after registration or payment, go to',
      confirmEntries2: 'Tournament Settings > Tournament Registration',
      'and check the box for': 'and check the box for',
      'Automatically Confirm Entries': 'Automatically Confirm Entries',
      'Add manual tournament results': 'Add manual tournament results',
      results1: 'To manually add tournament results (final standings), click the',
      'Add Final Standings Manually': 'Add Final Standings Manually',
      results2: 'button. If you encounter any issues, you can easily delete the entire block of entries or select specific entries to modify their placement or points',
      results3: 'For places and points to be displayed correctly, ensure they are set in the',
      results4: 'Tournament Dashboard -> League -> Ranking Points section',
      oop1: 'To make the Order of Play (OOP) visible to the public, you need to publish it in the Order of Play navigation.',
      'Edit / Delete Multiple Matches': 'Edit / Delete Multiple Matches',
      oop2: 'Select multiple matches to edit or delete them simultaneously.',
      'You can upload documents related to OOP': 'You can upload documents related to OOP',
      'You can download and print matches': 'You can download and print matches',
      oopExtra: 'For pickleball, you can export matches in the correct format for DUPR.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": "How to Update Participants' Rankings, Seeding, and Segmentation?",
      'To update rankings, segmentation, or seeding': 'To update rankings, segmentation, or seeding',
      'Click the': 'Click the',
      'button (represented by a three-dot icon) located next to the': 'button (represented by a three-dot icon) located next to the',
      button: 'button',
      'from the dropdown menu': 'from the dropdown menu',
      'Segmentation Explained': 'Segmentation Explained',
      "To ensure participants' rankings are indicated properly": "To ensure participants' rankings are indicated properly",
      entries1: 'Verify that a League Manager has correctly set up the tournament categories in the',
      'This can be found under': 'This can be found under',
      'League -> Tournament Categories.': 'League -> Tournament Categories.',
      entries2: 'Ensure that the tournament categories are connected to the correct Rankings category.',
      entries3:
        'If you believe rankings are not being updated properly, ensure the ranking categories are set according to the ranking rules in the Tournated Dashboard. This includes correct tournament categories setup, limits, filters, etc.',
      Note: 'Note',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': 'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?',
      'To Add a New Entry': 'To Add a New Entry',
      "By default, you need to provide the player's email.": "By default, you need to provide the player's email.",
      entries4: 'If your organization has licenses enabled, you can select from athletes who have an active license.',
      'To Withdraw an Entry': 'To Withdraw an Entry',
      'Select the entries you want to withdraw.': 'Select the entries you want to withdraw.',
      'and then choose': 'and then choose',
      'To Assign a Wild Card (WC)': 'To Assign a Wild Card (WC)',
      'Next to the entry, click the three-dot icon and select': 'Next to the entry, click the three-dot icon and select',
      entries5: "Ensure the number of WCs is set in the Category settings; otherwise, the system won't allow you to assign a WC.",
      'Where can I find participant details?': 'Where can I find participant details?',
      'You can find participant details in two ways': 'You can find participant details in two ways',
      'From the Entry List': 'From the Entry List',
      entries6: "Click on the participant's name directly in the Entry List to view their details.",
      'From the Tournament Data Section': 'From the Tournament Data Section',
      entries7: 'Navigate to Tournament Data -> Participants in the left sidebar to see details for all participants.',
      entries8: 'Additionally, you have the option to export all participant details or their emails separately from the Participants section.',
      'How to Add Groups?': 'How to Add Groups?',
      'To add groups, follow these steps': 'To add groups, follow these steps',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'Navigate to the "Groups" section and click the "Add Groups" button.',
      'Select a Category': 'Select a Category',
      'Choose the category for which you want to add groups.': 'Choose the category for which you want to add groups.',
      'Set Number of Groups and Naming Type': 'Set Number of Groups and Naming Type',
      'Specify the number of groups you want to create.': 'Specify the number of groups you want to create.',
      'Choose the naming convention for the groups': 'Choose the naming convention for the groups',
      'Select the Group Type': 'Select the Group Type',
      'Used when athletes or teams are competing against each other': 'Used when athletes or teams are competing against each other',
      'Used when single athletes are playing doubles with each other.': 'Used when single athletes are playing doubles with each other.',
      'Standard Format Options': 'Standard Format Options',
      groups1: 'If you select the Standard format, decide whether to generate group members automatically or manually',
      'Select an algorithm': 'Select an algorithm',
      'Based on international rules.': 'Based on international rules.',
      groups2:
        'For example, if there are 3 groups and 9 entries, Group 1 would include the 1st, 6th, and 7th ranked players; Group 2 would include the 2nd, 5th, and 8th; Group 3 would include the 3rd, 4th, and 9th.',
      groups3:
        'Add teams to each group, and once you’ve finished, make sure to click the "Create Matches" button located next to each group’s name (this step will be automated in the upcoming release)..',
      'King Format Options': 'King Format Options',
      'If you select the King format, choose the': 'If you select the King format, choose the',
      'Display List of Matches': 'Display List of Matches',
      groups4: 'To display the list of matches under each group, select the checkbox labeled',
      'To generate the Order of Play (OOP)': 'To generate the Order of Play (OOP)',
      'Choose the Scope': 'Choose the Scope',
      groups5: 'Decide if you want to generate the OOP for all groups at once (located in the "Groups" navigation) or for each group separately (found next to each group’s name).',
      'Generate the OOP': 'Generate the OOP',
      'Click the appropriate button.': 'Click the appropriate button.',
      'Select the rounds for which you want to generate the OOP.': 'Select the rounds for which you want to generate the OOP.',
      'Set the date and time for the first match.': 'Set the date and time for the first match.',
      'Enter the estimated duration for the matches.': 'Enter the estimated duration for the matches.',
      'Adjust Matches': 'Adjust Matches',
      'You can also create matches manually and make adjustments after the OOP is generated.': 'You can also create matches manually and make adjustments after the OOP is generated.',
      'Select the Match': 'Select the Match',
      groups6: 'Choose the match you want to update, either within the group or from the Order of Play.',
      'Enter the Winner and Score': 'Enter the Winner and Score',
      'Set the winner of the match.': 'Set the winner of the match.',
      'Format the score as follows': 'Format the score as follows',
      "Start with the winner's score.": "Start with the winner's score.",
      'Separate sets with a space.': 'Separate sets with a space.',
      'Use parentheses for a set tiebreak (no space after the set)': 'Use parentheses for a set tiebreak (no space after the set)',
      'Use square brackets for a super tiebreak (with a space before it)': 'Use square brackets for a super tiebreak (with a space before it)',
      'How to Generate the Final Round of King Format?': 'How to Generate the Final Round of King Format?',
      'To generate the final round in King format': 'To generate the final round in King format',
      'Create New Groups': 'Create New Groups',
      'Set up new groups and select the "King" format.': 'Set up new groups and select the "King" format.',
      'Select the Round': 'Select the Round',
      'Choose "Final" as the round type.': 'Choose "Final" as the round type.',
      'Automatic Sorting': 'Automatic Sorting',
      groups7: 'The platform will automatically use the group placements and ratios to sort participants for the final round.',
      'You can download and print groups.': 'You can download and print groups.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'For pickleball, you can export matches in the correct format for DUPR.',
    },
  },
  la: {
    translation: {
      'Preview Tournament': 'Turnīra priekšskatījums',
      'Control Panel': 'Vadības panelis',
      Instructions: 'Instrukcijas',
      'All Tournaments': 'Visi turnīri',
      Settings: 'Iestatījumi',
      'General Data': 'Vispārējie dati',
      'Category Settings': 'Kategoriju iestatījumi',
      Entries: 'Ieraksti',
      Requests: 'Pieprasījumi',
      Withdrawals: 'Izņemšana',
      Matches: 'Sērkociņi',
      Pairings: 'Savienojumi pārī',
      Standings: 'Kopvērtējums',
      'Custom Metrics': 'Pielāgota metrika',
      'List & Results': 'Saraksts un rezultāti',
      Groups: 'Grupas',
      Draws: 'Izlozē',
      'Order Of Play': 'Spēles kārtība',
      'Live Standings': 'Tiešraides tabula',
      'Final Standings': 'Galīgais kopvērtējums',
      'Tournament Data': 'Turnīra dati',
      Participants: 'Dalībnieki',
      Documents: 'Dokumenti',
      Partners: 'Partneri',
      'No tournament selected': 'Turnīrs nav izvēlēts',
      'Copy Tournament': 'Kopēt turnīru',
      'Make a copy of selected tournament': 'Izveidojiet atlasītā turnīra kopiju',
      Completed: 'Pabeigts',
      Upcoming: 'Gaidāms',
      Active: 'Aktīvs',
      Total: 'Kopā',
      'Tournaments list': 'Turnīru saraksts',
      'Tournament Title': 'Turnīra nosaukums',
      Search: 'Meklēt',
      'Create new Tournament': 'Izveidot jaunu turnīru',
      Delete: 'Dzēst',
      Organizer: 'Organizators',
      Location: 'Atrašanās vieta',
      'List of categories': 'Kategoriju saraksts',
      'Start - End': 'Sākums - Beigas',
      Dashboard: 'Informācijas panelis',
      'Athlete Portal': 'Sportista portāls',
      Profile: 'Profils',
      Logout: 'Iziet',
      Create: 'Izveidot',
      'Add Tournament': 'Pievienot turnīru',
      'Add League': 'Pievienot līgu',
      'Add Club': 'Pievienot klubu',
      'Add News': 'Pievienot ziņas',
      'Add Team': 'Pievienot komandu',
      'Edit Tournament': 'Rediģēt turnīru',
      'Create Tournament': 'Izveidot turnīru',
      editAddDesc: 'Konfigurējiet vispārīgos iestatījumus, izskatu, faktu lapu, papildu iestatījumus, pārvaldību un daudz ko citu.',
      'Configure Categories': 'Konfigurēt kategorijas',
      configureCategoriesDesc: 'Norādiet personīgās kategoriju iestatījumus: maksimālais dalībnieku skaits, kvalifikācijas, WC, izsēšanas, maksājumu metodes un daudz ko citu.',
      'Manage Entries': 'Pārvaldīt ierakstus',
      manageEntriesDesc:
        'Apstipriniet/noraidiet reģistrētos ierakstus, importējiet ierakstus no CSV veidnes, piešķiriet vērtējumus un izsēšanas, atjauniniet/kopējiet/dzēsiet ierakstus un daudz ko citu.',
      'Go to Requests': 'Pāriet uz pieprasījumiem',
      'Go to Entries': 'Pāriet uz ierakstiem',
      'Create Groups, Draws, and Live Standings': 'Izveidot grupas, izlozes un tiešsaistes reitingus',
      groupsDrawsLiveStandingsDesc:
        'Pievienojiet un ģenerējiet grupas, izlozes un tiešsaistes reitingus ar dažiem klikšķiem. To var ģenerēt automātiski, pamatojoties uz izvēlēto algoritmu, vai vienkārši izveidot manuāli no dalībnieku saraksta.',
      'Go to Groups': 'Pāriet uz grupām',
      'Go to Draws': 'Pāriet uz izlozēm',
      'Go to Live Standings': 'Pāriet uz tiešsaistes reitingiem',
      'Go to Order of Play': 'Pāriet uz spēļu kārtību',
      'Generate Order of Play': 'Ģenerēt spēļu kārtību',
      generateOrderOfPlayDesc:
        'Pēc grupu/izložu izveides pēdējais solis pirms spēļu publicēšanas ir spēļu (spēļu kārtības) ģenerēšana. Identificējiet grupas/izlozes, raundus, laikus un laukumus; ģenerējiet pāris sekunžu laikā vai pievienojiet spēles manuāli. Jūsu izvēle. Vieglāk ir strādāt ar spēlēm, izmantojot izložu/grupu lapu.',
      'Publish data': 'Publicēt datus',
      publishDataDesc:
        'Lai padarītu datus pieejamus publiski, jums ir jānodrošina, ka grupas, izlozes, tiešsaistes reitingi un spēļu kārtība ir publicēti. Ja redzat pogu "Slēpt", tas nozīmē, ka dati jau ir tiešsaistē un jūs esat gatavi darbam.',
      'Submit Tournament Results': 'Iesniegt turnīra rezultātus',
      submitTournamentResultsDesc:
        'Pēdējais solis, lai pabeigtu turnīru, ir iesniegt turnīra rezultātus un piešķirt punktus, pamatojoties uz punktu tabulu (ja ir iespējota reitingu funkcija). To var izdarīt automātiski, izmantojot tiešsaistes reitingus, vai manuāli, izmantojot turnīra rezultātu ekrānu.',
      'Add Results Manually': 'Pievienot rezultātus manuāli',
      instructionsPageTitle: 'Informācijas panelis: Instrukcijas',
      controlPanelDesc: 'Vadiet turnīru soli pa solim no šī vadības paneļa',
      General: 'Vispārīgs',
      'Dates & Location': 'Datumi un vieta',
      Appearance: 'Izskats',
      'Tournament Registration': 'Turnīra reģistrācija',
      Extras: 'Papildiespējas',
      Management: 'Pārvaldība',
      'Fact Sheet': 'Faktu lapa',
      'Select League': 'Izvēlēties līgu',
      'Select Categories': 'Izvēlēties kategorijas',
      'Payment methods': 'Maksājumu metodes',
      'Payment type': 'Maksājuma veids',
      Currency: 'Valūta',
      'Online Entry Fee': 'Tiešsaistes dalības maksa',
      'Entry Fee': 'Dalības maksa',
      'Onsite Entry Fee': 'Uz vietas dalības maksa',
      'Number of Rounds': 'Raundu skaits',
      'Number of Courts': 'Kortu skaits',
      'Number of Stages': 'Posmu skaits',
      'Tournament timezone': 'Turnīra laika zona',
      'Registration Start Date': 'Reģistrācijas sākuma datums',
      'Start Date': 'Sākuma datums',
      'End Date': 'Beigu datums',
      'Entry Deadline': 'Pieteikšanās termiņš',
      'Withdrawal Deadline': 'Atsaukšanās termiņš',
      'Select Club': 'Izvēlēties klubu',
      'No clubs found in selected league': 'Izvēlētajā līgā nav atrasti klubi',
      'Select Country': 'Izvēlēties valsti',
      City: 'Pilsēta',
      Address: 'Adrese',
      Logo: 'Logo',
      logoDesc: 'Mēs iesakām attēlu, kura izmērs ir 256 x 256 pikseļi',
      'Tournament title': 'Turnīra nosaukums',
      'Enter tournament title': 'Ievadiet turnīra nosaukumu',
      Description: 'Apraksts',
      tournamentDesc: 'Uzrakstiet aprakstu par turnīru',
      'Video URL': 'Video URL',
      videoLink: 'Ievietojiet video saiti šeit',
      tourWebUrl: 'Turnīra mājaslapas URL',
      'Upload Poster': 'Augšupielādēt plakātu',
      'Tournament Poster': 'Turnīra plakāts',
      'Cover Banner': 'Vāka baneris',
      bannerDesc: 'Mēs iesakām attēlu, kura izmērs ir 2400 x 600 pikseļi.',
      'Upload Banner': 'Augšupielādēt baneri',
      Regulations: 'Noteikumi',
      'Upload Regulations': 'Augšupielādēt noteikumus',
      'No Image': 'Nav attēla',
      dragDropDesc: 'Velciet un nometiet failu šeit vai noklikšķiniet, lai atlasītu failu',
      dragDropDesc2: 'Velciet un nometiet failus šeit vai noklikšķiniet, lai atlasītu failus',
      'Select File': 'Atlasīt failu',
      'Additional Questions': 'Papildu jautājumi',
      'Add New Question': 'Pievienot jaunu jautājumu',
      'Terms and Conditions': 'Noteikumi un nosacījumi',
      'Start Typing': 'Sāciet rakstīt',
      'External tournament registration link': 'Ārējā turnīra reģistrācijas saite',
      'Automatically confirm entries': 'Automātiski apstiprināt pieteikumus',
      confirmEntriesInfo: 'Turnīra reģistrācijas pieteikumi tiks tieši pievienoti ierakstiem, nevis pieprasījumiem',
      closeRegistration: 'Aizvērt reģistrāciju visām turnīra kategorijām',
      'Edit this question': 'Rediģēt šo jautājumu',
      'Add new question': 'Pievienot jaunu jautājumu',
      'Question updated successfully!': 'Jautājums veiksmīgi atjaunināts!',
      'Question added successfully!': 'Jautājums veiksmīgi pievienots!',
      Question: 'Jautājums',
      'Field Type': 'Lauka veids',
      Price: 'Cena',
      'Field Option': 'Lauka opcija',
      removeQuestionError: 'Lauka tipam izvēlēties ir nepieciešama vismaz 1 lauka opcija',
      Remove: 'Noņemt',
      'Add new option': 'Pievienot jaunu opciju',
      questionInfo: 'Uzdodiet šo jautājumu katram sportistam',
      Submit: 'Iesniegt',
      isFeatured: 'Padarīt šo turnīru par izcelto',
      partnerSearch: 'Iespējot partneru meklēšanu',
      enablePracticeCourtsBooking: 'Iespējot treniņkortu rezervēšanu',
      showTournamentChessManagerCalendar: 'Rādīt šo turnīru šaha menedžera kalendārā',
      localRatedTournament: 'Vietējais reitinga turnīrs',
      fideRatedTournament: 'FIDE reitinga turnīrs',
      enableTransferBooking: 'Iespējot pārvadājumu rezervēšanu',
      enableLiveStream: 'Iespējot tiešraidi (drīzumā)',
      enableLiveScoring: 'Iespējot tiešraides rezultātus (drīzumā)',
      'Select Organizer': 'Izvēlēties organizatoru',
      'No Organizer available': 'Nav pieejams organizators',
      'Select main referee': 'Izvēlēties galveno tiesnesi',
      'Select referee': 'Izvēlēties tiesnesi',
      'No referee available': 'Nav pieejams tiesnesis',
      'Select additional referees': 'Izvēlēties papildu tiesnešus',
      'Tournament status': 'Turnīra statuss',
      'Make tournament private': 'Padarīt turnīru privātu',
      'Set Password': 'Iestatīt paroli',
      'New Password': 'Jauna parole',
      'Confirm Password': 'Apstiprināt paroli',
      'No facts found': 'Nav atrasti fakti',
      Save: 'Saglabāt',
      Back: 'Atpakaļ',
      'Field Title': 'Lauka nosaukums',
      'Change Settings': 'Mainīt iestatījumus',
      Title: 'Nosaukums',
      Status: 'Statuss',
      'General Limit': 'Vispārējais ierobežojums',
      'Individual metric limit': 'Individuālais metrikas ierobežojums',
      'Allow participants to add results': 'Ļaut dalībniekiem pievienot rezultātus',
      'Attachments status': 'Pielikumu statuss',
      'There is no available': 'Nav pieejams',
      'Public for everyone': 'Publisks visiem',
      'Public only for participants': 'Publisks tikai dalībniekiem',
      Hidden: 'Paslēpts',
      'Hide other participant results': 'Paslēpt citu dalībnieku rezultātus',
      'Individual metric limit applied': 'Piemērots individuālais metrikas ierobežojums',
      'Attachments Status': 'Pielikumu statuss',
      'Public to participants only': 'Publisks tikai dalībniekiem',
      Saving: 'Saglabāšana',
      'General data': 'Vispārējie dati',
      'Category settings': 'Kategorijas iestatījumi',
      'Dashboard: Category Settings': 'Informācijas panelis: Kategorijas iestatījumi',
      'There is no available category!': 'Nav pieejama kategorija!',
      Category: 'Kategorija',
      'Online Fee': 'Tiešsaistes maksa',
      'Onsite Fee': 'Vietā rēķināmā maksa',
      'VAT Amount': 'PVN summa',
      'Payment Type': 'Maksājuma veids',
      'Payment Method': 'Maksājuma metode',
      Rounds: 'Raundi',
      Time: 'Laiks',
      'Time per move': 'Laiks par gājienu',
      'Time after move': 'Laiks pēc gājiena',
      'Extra time': 'Papildus laiks',
      Pairing: 'Savienošana',
      entries: 'ieraksti',
      'Registration Status': 'Reģistrācijas statuss',
      'Additional service fee': 'Papildu apkalpošanas maksa',
      'Registration Limit': 'Reģistrācijas ierobežojums',
      Edit: 'Rediģēt',
      'Registrations Limit': 'Reģistrācijas ierobežojums',
      'Include VAT amount?': 'Iekļaut PVN summu?',
      displayClubInfo: 'Parādīt sportista/komandas kluba nosaukumu ieraksta detalizācijā',
      Open: 'Atvērts',
      Closed: 'Aizvērts',
      categorySettingsInfo: 'Iestatiet datus tikai tiem, ko vēlaties atjaunināt izvēlētajās kategorijās',
      'Select category color': 'Izvēlēties kategorijas krāsu',
      'Dashboard: Requests': 'Informācijas panelis: Pieprasījumi',
      'No category found': 'Nav atrasta kategorija',
      'Add New Request': 'Pievienot jaunu pieprasījumu',
      Actions: 'Darbības',
      Confirming: 'Apstiprināšana',
      'Confirm Selected Requests': 'Apstiprināt izvēlētos pieprasījumus',
      NR: 'NR',
      Team: 'Komanda',
      Player: 'Spēlētājs',
      Rating: 'Reitings',
      Email: 'E-pasts',
      'Phone Number': 'Telefona numurs',
      'Reg. time': 'Reģistrācijas laiks',
      Paid: 'Apmaksāts',
      'ADDITIONAL QUESTIONS': 'PAPILDU JAUTĀJUMI',
      'Show Answer': 'Parādīt atbildi',
      'Download requests list': 'Lejupielādēt pieprasījumu sarakstu',
      Decline: 'Noraidīt',
      'Confirm Decline': 'Apstiprināt noraidīšanu',
      requestDeclineWarning: 'Vai tiešām vēlaties noraidīt izvēlētos pieprasījumus?',
      'Copy Selected Requests': 'Kopēt izvēlētos pieprasījumus',
      'No questions found': 'Nav atrasti jautājumi',
      'Answer/Files': 'Atbilde/Faili',
      Download: 'Lejupielādēt',
      'Edit Entries successfully': 'Ieraksti veiksmīgi rediģēti',
      'Tournament data or category not available': 'Turnīra dati vai kategorija nav pieejami',
      'Please mark the Checkbox': 'Lūdzu, atzīmējiet izvēles rūtiņu',
      'Confirming...': 'Apstiprināšana...',
      'Confirmed successfully!': 'Veiksmīgi apstiprināts!',
      'Please enter both player emails': 'Lūdzu, ievadiet abu spēlētāju e-pastus',
      'Please enter player email': 'Lūdzu, ievadiet spēlētāja e-pastu',
      'Step 1: Select category': '1. solis: Izvēlēties kategoriju',
      'Athlete email (user account)': 'Sportista e-pasts (lietotāja konts)',
      'Player is not registered in the platform': 'Spēlētājs nav reģistrēts platformā',
      'First athlete email (user account)': 'Pirmā sportista e-pasts (lietotāja konts)',
      'First player is not registered in the platform': 'Pirmais spēlētājs nav reģistrēts platformā',
      'Second athlete email (user account)': 'Otrā sportista e-pasts (lietotāja konts)',
      'Second player is not registered in the platform': 'Otrais spēlētājs nav reģistrēts platformā',
      Update: 'Atjaunināt',
      'No Team': 'Nav komandas',
      'Athlete email is required': 'Nepieciešams sportista e-pasts',
      'First athlete email is required': 'Nepieciešams pirmā sportista e-pasts',
      'Second athlete email is required': 'Nepieciešams otrā sportista e-pasts',
      'Add Entries successfully': 'Ieraksti veiksmīgi pievienoti',
      'Select category': 'Izvēlēties kategoriju',
      'No category exists': 'Nav kategorijas',
      Registration: 'Reģistrācija',
      Register: 'Reģistrēties',
      'There is no available requests!': 'Nav pieejamu pieprasījumu!',
      'Dashboard: Entries Data': 'Informācijas panelis: Reģistrācijas dati',
      'Request Updated Successfully!': 'Pieprasījums veiksmīgi atjaunināts!',
      'Import CSV File': 'Importēt CSV failu',
      'Import Entries': 'Importēt reģistrācijas',
      'Add New Entry': 'Pievienot jaunu reģistrāciju',
      'Entries deleted successfully.': 'Reģistrācijas veiksmīgi dzēstas.',
      'Confirm Deletion': 'Apstiprināt dzēšanu',
      deleteEntriesWarning: 'Vai tiešām vēlaties dzēst atlasītās reģistrācijas?',
      'PLAYER 1': 'SPĒLĒTĀJS 1',
      'PLAYER 2': 'SPĒLĒTĀJS 2',
      Rank: 'Vieta',
      'Total Ranks': 'Kopējais vietu skaits',
      Priority: 'Prioritāte',
      Assign: 'Piešķirt',
      'Limit reached': 'Sasniegts limits',
      'Assigning...': 'Piešķiršana...',
      'Removing...': 'Noņemšana...',
      'Moving...': 'Pārvietošana...',
      'Updated successfully': 'Veiksmīgi atjaunināts',
      'Please enter a value': 'Lūdzu, ievadiet vērtību',
      'Updating...': 'Atjaunināšana...',
      'Please enter a rating': 'Lūdzu, ievadiet vērtējumu',
      'Edit Rating': 'Rediģēt vērtējumu',
      'Please enter a rank': 'Lūdzu, ievadiet vietu',
      'Edit Rank': 'Rediģēt vietu',
      'Change Category': 'Mainīt kategoriju',
      'Move selected entries to Requests': 'Pārvietot atlasītās reģistrācijas uz Pieprasījumiem',
      'Withdraw selected entries': 'Atsaukt atlasītās reģistrācijas',
      'Delete rankings': 'Dzēst vietas',
      'Update rankings & seeding': 'Atjaunināt vietas un izsēšanu',
      'Download entry List': 'Lejupielādēt reģistrāciju sarakstu',
      withdrawEntriesWarning: 'Vai tiešām vēlaties atsaukt atlasītās reģistrācijas?',
      'File uploaded & entries created successfully': 'Fails augšupielādēts un reģistrācijas veiksmīgi izveidotas',
      'Please select a file to Upload': 'Lūdzu, izvēlieties failu augšupielādei',
      'Import entries via CSV template': 'Importēt reģistrācijas, izmantojot CSV veidni',
      'Download CSV template for Singles format category': 'Lejupielādēt CSV veidni vienspēļu kategorijai',
      'CSV file Downloaded': 'CSV fails lejupielādēts',
      'Download CSV template for Doubles format category': 'Lejupielādēt CSV veidni dubultspēļu kategorijai',
      'Upload & create entries': 'Augšupielādēt un izveidot reģistrācijas',
      'There is no available entries!': 'Nav pieejamas reģistrācijas!',
      'Entry Fee is Required Field': 'Reģistrācijas maksa ir obligāts lauks',
      'Type is Required Field': 'Tips ir obligāts lauks',
      'Payment Information Removed Successfully': 'Maksājuma informācija veiksmīgi noņemta',
      'Paid Information Added Successfully': 'Maksājuma informācija veiksmīgi pievienota',
      'Update Paid information': 'Atjaunināt maksājuma informāciju',
      'Paid information': 'Maksājuma informācija',
      'Amount of entry fee': 'Reģistrācijas maksas summa',
      Type: 'Tips',
      'Remove payment': 'Noņemt maksājumu',
      'Drag and Drop a file here, or click to Select file': 'Velciet un nometiet failu šeit vai noklikšķiniet, lai atlasītu failu',
      'Supported file': 'Atbalstītais fails',
      'Dashboard: Withdrawals Data': 'Informācijas panelis: Atsaukti ieraksti',
      'Entries Deleted Successfully!': 'Ieraksti veiksmīgi dzēsti!',
      'Deleting...': 'Dzēšana...',
      'There is no available withdrawals!': 'Nav pieejamu atsauktu ierakstu!',
      deleteWithdrawalsWarning: 'Vai tiešām vēlaties dzēst atlasītos ierakstus?',
      'Move selected entries to Entry list': 'Pārvietot atlasītos ierakstus uz Ierakstu sarakstu',
      'Dashboard: Groups Data': 'Informācijas panelis: Grupas dati',
      'Back to tournament': 'Atpakaļ uz turnīru',
      'Submit results to Live standings': 'Iesniegt rezultātus tiešraides tabulās',
      'Please select at least one group': 'Lūdzu, izvēlieties vismaz vienu grupu',
      'Live standings submitted successfully': 'Tiešraides tabulas veiksmīgi iesniegtas',
      'Submit Live Standings': 'Iesniegt tiešraides tabulas',
      'Select Category': 'Izvēlēties kategoriju',
      'Select Groups...': 'Izvēlēties grupas...',
      'Please select at least one Group.': 'Lūdzu, izvēlieties vismaz vienu grupu.',
      'Standings Title': 'Tabulu nosaukums',
      Sorting: 'Šķirošana',
      'Removing groups...': 'Noņem grupas...',
      'Groups removed successfully': 'Grupas veiksmīgi noņemtas',
      Hiding: 'Paslēpšana',
      Publishing: 'Publicēšana',
      Group: 'Grupa',
      Successfully: 'Veiksmīgi',
      Published: 'Publicēts',
      Hide: 'Paslēpt',
      Publish: 'Publicēt',
      'Updating scores...': 'Atjaunina rezultātus...',
      'Scores updated successfully': 'Rezultāti veiksmīgi atjaunināti',
      'Deleting group...': 'Dzēš grupu...',
      'Group Deleted successfully': 'Grupa veiksmīgi izdzēsta',
      'Deleting entries...': 'Dzēš ierakstus...',
      'Entries deleted successfully': 'Ieraksti veiksmīgi izdzēsti',
      'Delete Selected Entries': 'Dzēst izvēlētos ierakstus',
      'Delete Selected Teams': 'Dzēst izvēlētās komandas',
      'Publish this group': 'Publicēt šo grupu',
      'Hide this group': 'Paslēpt šo grupu',
      'Print/Download this group': 'Izdrukāt/Lejupielādēt šo grupu',
      Recover: 'Atjaunot',
      'this group': 'šo grupu',
      'Add team to this group': 'Pievienot komandu šai grupai',
      'Add team': 'Pievienot komandu',
      'Update places for entries': 'Atjaunināt vietas ierakstiem',
      'Update Places': 'Atjaunināt vietas',
      'Generate OOP For This Group Only': 'Ģenerēt spēļu kārtību tikai šai grupai',
      'Create Matches for this group': 'Izveidot spēles šai grupai',
      'Create Matches': 'Izveidot spēles',
      POINTS: 'PUNKTI',
      SR: 'SR',
      PR: 'PR',
      PLACE: 'VIETA',
      Place: 'Vieta',
      'Match Time Conflict': 'Spēles laika konflikts',
      'Winner is required when score is provided': 'Uzvarētājs ir nepieciešams, ja tiek norādīts rezultāts',
      'Match cannot be unfinished when score is provided': 'Spēlei jābūt pabeigtai, ja tiek norādīts rezultāts',
      'Space at the end is not allowed': 'Atstarpe beigās nav atļauta',
      'Please follow the pattern': 'Lūdzu, ievērojiet paraugu',
      'Date is required Field': 'Datums ir nepieciešams lauks',
      'Time is required Field': 'Laiks ir nepieciešams lauks',
      'Add Score Successfully': 'Rezultāts veiksmīgi pievienots',
      'Select Winner': 'Izvēlēties uzvarētāju',
      Athlete: 'Sportists',
      'Additional match status options': 'Papildu spēles statusa iespējas',
      Deselect: 'Noņemt izvēli',
      Date: 'Datums',
      'Start Time': 'Sākuma laiks',
      'Match Status': 'Spēles statuss',
      'Delete match from oop': 'Dzēst spēli no spēļu kārtības',
      'This Group is Deleted': 'Šī grupa ir izdzēsta',
      groupRecoverInfo: 'Ja domājat, ka grupa tika izdzēsta kļūdas dēļ, varat to atjaunot, noklikšķinot uz atjaunošanas pogas vienas dienas laikā',
      'There is no available groups': 'Nav pieejamu grupu',
      DATE: 'DATUMS',
      COURT: 'KORTS',
      TEAM1: 'KOMANDA1',
      TEAM2: 'KOMANDA2',
      RESULT: 'REZULTĀTS',
      Court: 'Korts',
      Score: 'Rezultāts',
      'Add Score': 'Pievienot rezultātu',
      'Delete group Match from oop': 'Dzēst grupas spēli no spēļu kārtības',
      'Edit Match': 'Rediģēt spēli',
      'Match Updated Successfully': 'Spēle veiksmīgi atjaunināta',
      'Edit Match Date & Time': 'Rediģēt spēles datumu un laiku',
      'Match Date Updated Successfully': 'Spēles datums veiksmīgi atjaunināts',
      'Date is Required Field': 'Datums ir nepieciešams lauks',
      'Time is Required Field': 'Laiks ir nepieciešams lauks',
      'Order of Play': 'Spēļu kārtība',
      'Please select at least one court': 'Lūdzu, izvēlieties vismaz vienu kortu',
      'Generate OOP Successfully': 'Spēļu kārtība veiksmīgi ģenerēta',
      'Generate order of play for': 'Ģenerēt spēļu kārtību priekš',
      'Number of teams': 'Komandu skaits',
      'Number of matches': 'Spēļu skaits',
      'Add Rounds...': 'Pievienot raundus...',
      'Please select at least one Round.': 'Lūdzu, izvēlieties vismaz vienu raundu.',
      Add: 'Pievienot',
      'Please select at least one.': 'Lūdzu, izvēlieties vismaz vienu.',
      'Approximate match duration': 'Aptuvenais spēles ilgums',
      'in minutes': 'minūtēs',
      GENERATE: 'ĢENERĒT',
      'Group Edited Successfully': 'Grupa veiksmīgi rediģēta',
      'Edit Group': 'Rediģēt grupu',
      'Group Name': 'Grupas nosaukums',
      'Display order of play under the group': 'Rādīt spēļu kārtību zem grupas',
      'Display list of matches under the group': 'Rādīt spēļu sarakstu zem grupas',
      Comment: 'Komentārs',
      'Group Name is Required Field': 'Grupas nosaukums ir nepieciešams lauks',
      'Entry is required': 'Ieraksts ir nepieciešams',
      'Adding Team...': 'Pievieno komandu...',
      'Team Added Successfully': 'Komanda veiksmīgi pievienota',
      'Add team to group': 'Pievienot komandu grupai',
      'OOP generated successfully': 'Spēļu kārtība veiksmīgi ģenerēta',
      'Matches created successfully': 'Spēles veiksmīgi izveidotas',
      'Generate OOP': 'Ģenerēt spēļu kārtību',
      Duration: 'Ilgums',
      'No courts available': 'Nav pieejamu kortu',
      'Update Entry': 'Atjaunināt ierakstu',
      'Update Entry successfully': 'Ieraksts veiksmīgi atjaunināts',
      Cancel: 'Atcelt',
      deleteGroupWarning: 'Vai tiešām vēlaties dzēst šo grupu?',
      deleteGroupEntryWarning: 'Vai tiešām vēlaties dzēst šo ierakstu?',
      deleteGroupEntriesWarning: 'Vai tiešām vēlaties dzēst šos ierakstus?',
      'Groups deleted successfully.': 'Grupas veiksmīgi izdzēstas.',
      'Edit Selected Groups': 'Rediģēt izvēlētās grupas',
      'Delete Selected Groups': 'Dzēst izvēlētās grupas',
      deleteGroupsWarning: 'Vai tiešām vēlaties dzēst izvēlētās grupas?',
      'Select Entries': 'Izvēlēties ierakstus',
      'PDF being generated...': 'PDF tiek ģenerēts...',
      'Failed to generate PDF': 'Neizdevās ģenerēt PDF',
      'Generating PDF...': 'Ģenerē PDF...',
      'Please wait...': 'Lūdzu, uzgaidiet...',
      'All groups Published successfully': 'Visas grupas veiksmīgi publicētas',
      'All groups Unpublished successfully': 'Visas grupas veiksmīgi nepublicētas',
      'PUBLISH GROUPS': 'PUBLICĒT GRUPAS',
      'HIDE GROUPS': 'PASLĒPT GRUPAS',
      'No segment found': 'Neviens segments nav atrasts',
      'All Segments': 'Visi segmenti',
      'All Categories': 'Visas kategorijas',
      'Generate OOP for all groups': 'Ģenerēt spēļu kārtību visām grupām',
      'Add Group': 'Pievienot grupu',
      'Download PDF': 'Lejupielādēt PDF',
      'Export matches for DUPR': 'Eksportēt spēles DUPR',
      'Publish All Groups': 'Publicēt visas grupas',
      'Unpublish All Groups': 'Nepublicēt visas grupas',
      'Please select at least one Court.': 'Lūdzu, izvēlieties vismaz vienu laukumu.',
      'Select Groups': 'Izvēlēties grupas',
      'All Groups': 'Visas grupas',
      'Match duration is Required Field': 'Spēles ilgums ir obligāts lauks',
      'Round is Required Field': 'Kārta ir obligāts lauks',
      'Courts is Required Field': 'Laukumi ir obligāts lauks',
      'Print/Download All group': 'Izdrukāt/lejupielādēt visas grupas',
      'Category is Required Field': 'Kategorija ir obligāts lauks',
      'Segment is a Required Field': 'Segments ir obligāts lauks',
      'Group Type is Required Field': 'Grupas tips ir obligāts lauks',
      'Groups Name is Required Field': 'Grupas nosaukums ir obligāts lauks',
      'Groups Naming is Required Field': 'Grupas nosaukuma veidošana ir obligāts lauks',
      'Please enter a 2-digit number.': 'Lūdzu, ievadiet 2 ciparu skaitli.',
      'No of groups is Required Field': 'Grupu skaits ir obligāts lauks',
      'Algorithm is Required Field': 'Algoritms ir obligāts lauks',
      'Generate Groups is Required Field': 'Ģenerēt grupas ir obligāts lauks',
      'Add Group Successfully': 'Grupa veiksmīgi pievienota',
      'Add Groups': 'Pievienot grupas',
      'Select segment': 'Izvēlēties segmentu',
      'Groups type': 'Grupu tips',
      'Number of groups': 'Grupu skaits',
      'Groups naming': 'Grupu nosaukumu veidošana',
      'Select Round': 'Izvēlēties kārtu',
      'Generate Groups': 'Ģenerēt grupas',
      'Select Algorithm': 'Izvēlēties algoritmu',
      'Tournament copied successfully': 'Turnīrs veiksmīgi kopēts',
      'Title is required': 'Nosaukums ir obligāts',
      'Start date is required': 'Sākuma datums ir obligāts',
      'End date cannot be before start date': 'Beigu datums nedrīkst būt pirms sākuma datuma',
      'Entry deadline is required': 'Pieteikšanās termiņš ir obligāts',
      'Withdrawl deadline is required': 'Izstāšanās termiņš ir obligāts',
      'Dashboard: Partners': 'Kontroles panelis: Partneri',
      'Add New Partners': 'Pievienot jaunus partnerus',
      'No Partners data!': 'Nav pieejami partneru dati!',
      'Showing dates': 'Rāda datumus',
      Text: 'Teksts',
      Link: 'Saite',
      'Dashboard: Documents Data': 'Kontroles panelis: Dokumentu dati',
      'League Regulations': 'Līgas noteikumi',
      'No regulations uploaded yet': 'Nav augšupielādēti noteikumi',
      'Tournament Regulations': 'Turnīra noteikumi',
      'Uploading...': 'Augšupielādēšana...',
      'Regulations uploaded successfully': 'Noteikumi veiksmīgi augšupielādēti',
      'Error uploading regulations': 'Kļūda augšupielādējot noteikumus',
      'Dashboard: Participants Data': 'Kontroles panelis: Dalībnieku dati',
      'Status: ': 'Statuss: ',
      'Show All': 'Rādīt visus',
      'No status found': 'Nav atrasts statuss',
      'Export User Emails': 'Eksportēt lietotāju e-pastus',
      'Export Users': 'Eksportēt lietotājus',
      'PLAYER NAME': 'SPĒLĒTĀJA VĀRDS',
      COUNTRY: 'VALSTS',
      'There is no participants data!': 'Nav pieejami dalībnieku dati!',
      'Dashboard: Final Standings': 'Kontroles panelis: Galīgie rezultāti',
      'Add Final Results Manually': 'Pievienot galīgos rezultātus manuāli',
      'There is no available tournament results': 'Nav pieejami turnīra rezultāti',
      'Final results added successfully': 'Galīgie rezultāti veiksmīgi pievienoti',
      Points: 'Punkti',
      'Select place': 'Izvēlieties vietu',
      'Result deleted successfully': 'Rezultāts veiksmīgi dzēsts',
      'Duplicate entries & assign points to another category': 'Dublēt ierakstus un piešķirt punktus citai kategorijai',
      'Duplicate & Assign': 'Dublēt un piešķirt',
      'Hide Results': 'Slēpt rezultātus',
      'Publish Results': 'Publicēt rezultātus',
      'Delete selected entries': 'Dzēst atlasītos ierakstus',
      'Delete results block': 'Dzēst rezultātu bloku',
      Entry: 'Ieraksts',
      'Download List': 'Lejupielādēt sarakstu',
      'Add new result': 'Pievienot jaunu rezultātu',
      'Entry Deleted Successfully': 'Ieraksts veiksmīgi dzēsts',
      'Points is required': 'Punkti ir obligāti',
      'Place is required': 'Vieta ir obligāta',
      'Please select a entry': 'Lūdzu, izvēlieties ierakstu',
      'Updated Successfully': 'Veiksmīgi atjaunināts',
      'Edit Entry': 'Rediģēt ierakstu',
      'Delete Entry': 'Dzēst ierakstu',
      deleteRankingEntryWarning: 'Vai tiešām vēlaties dzēst šo ierakstu?',
      'Select Pair': 'Izvēlieties pāri',
      'Select Player': 'Izvēlieties spēlētāju',
      'No ranking points found': 'Nav atrasti ranga punkti',
      'Select Ranking Point block': 'Izvēlieties ranga punktu bloku',
      'To Category': 'Uz kategoriju',
      'From Category': 'No kategorijas',
      'Duplicate entries & points to another category': 'Dublēt ierakstus un punktus uz citu kategoriju',
      'Entries & points copied successfully': 'Ieraksti un punkti veiksmīgi kopēti',
      'From Category is required': 'No kategorijas ir obligāti',
      'To Category is required': 'Uz kategoriju ir obligāti',
      'Ranking Point is required': 'Ranga punkts ir obligāti',
      deleteResultsWarning: 'Vai tiešām vēlaties dzēst šos rezultātus?',
      'Dashboard: Live Standings Data': 'Kontroles panelis: Tiešraides rezultātu dati',
      'Segment: ': 'Segmens: ',
      'No segments found': 'Nav atrasti segmenti',
      'Add Live Standings': 'Pievienot tiešraides rezultātus',
      'Submit final Results': 'Iesniegt galīgos rezultātus',
      'There is no available live standings': 'Nav pieejami tiešraides rezultāti',
      'Groups is Required Field': 'Grupas ir obligāts lauks',
      'Standings Title is Required Field': 'Rezultātu nosaukums ir obligāts lauks',
      'Sorting is Required Field': 'Kārtošana ir obligāts lauks',
      'Live standings added successfully': 'Tiešraides rezultāti veiksmīgi pievienoti',
      'Live Standing is Required Field': 'Tiešraides rezultāti ir obligāts lauks',
      'Results added successfully': 'Rezultāti veiksmīgi pievienoti',
      'Submit Results to Rankings': 'Iesniegt rezultātus rangam',
      'Select Live Standing': 'Izvēlieties tiešraides rezultātu',
      'No Live Standings found': 'Nav atrasti tiešraides rezultāti',
      'Deleting live standings...': 'Dzēš tiešraides rezultātus...',
      'Live standings deleted successfully.': 'Tiešraides rezultāti veiksmīgi dzēsti.',
      'Delete Selected Live Standings': 'Dzēst izvēlētos tiešraides rezultātus',
      deleteLivesStandingsWarning: 'Vai tiešām vēlaties dzēst izvēlētos tiešraides rezultātus?',
      'Generating CSV...': 'Veido CSV...',
      'CSV being generated...': 'CSV tiek veidots...',
      'Failed to generate CSV': 'Neizdevās veidot CSV',
      'Dashboard: Order Of Play': 'Kontroles panelis: Spēļu kārtība',
      Grid: 'Tīkls',
      List: 'Saraksts',
      "Bird's View": 'Putna skats',
      'Hide Order of Play': 'Slēpt spēļu kārtību',
      'Publish Order of Play': 'Publicēt spēļu kārtību',
      'Hide OOP': 'Slēpt OOP',
      'Publish OOP': 'Publicēt OOP',
      'Enter Name': 'Ievadiet vārdu',
      'There is no available order of play': 'Nav pieejama spēļu kārtība',
      'Export OOP for DUPR': 'Eksportēt OOP DUPR',
      'Deleting Matches...': 'Dzēš spēles...',
      'Matches Deleted Successfully!': 'Spēles veiksmīgi dzēstas!',
      'Delete Selected Matches': 'Dzēst izvēlētās spēles',
      deleteMatchesWarning: 'Vai tiešām vēlaties dzēst izvēlētās spēles?',
      'Score is not set': 'Rezultāts nav iestatīts',
      'TEAM 1': 'KOMANDA 1',
      'TEAM 2': 'KOMANDA 2',
      RESULTS: 'REZULTĀTI',
      'Delete Match Successfully': 'Spēle veiksmīgi dzēsta',
      'Court is required': 'Laukums ir obligāts',
      'Edit Score Successfully': 'Rezultāts veiksmīgi rediģēts',
      'Edit Draw Match': 'Rediģēt izlozes spēli',
      'Optional match details': 'Papildu spēles detaļas',
      'Delete match': 'Dzēst spēli',
      'Confirm Match Deletion': 'Apstiprināt spēles dzēšanu',
      deleteDrawMatchWarning: 'Vai tiešām vēlaties dzēst šo spēli? Kad spēle ir dzēsta, tā pazudīs no OOP, un spēles uzvarētājs/zaudētājs tiks dzēsts no nākamās kārtas/kārtām',
      'Add score instructions': 'Pievienot rezultāta norādījumus',
      scoreInstructionsInfo1: 'Iestatiet rezultātu tukšajā laukā vai spēles uzvarētāju kā "Spēle nav pabeigta", un spēles statuss automātiski mainīsies uz "Gaidāms"',
      scoreInstructionsInfo2: '*Spēles jāatdala ar ":" simbolu (kolonu). Piemērs: 6:4 6:7(2) [10:6]',
      scoreInstructionsInfo3: '**Izšķirošais tiebreak (ja spēlēts) jāiekļauj iekavās "()" un jānovieto pēc spēles bez atstarpes. Piemērs: 7:6(4) 7:6(5)',
      scoreInstructionsInfo4: '***Izšķirošais tiebreak (ja spēlēts) jāiekļauj kvadrātiekavās "[]". Piemērs: 6:4 6:7(5) [10:6]',
      'Edit Match Successfully': 'Spēle veiksmīgi rediģēta',
      'Edit Group Match': 'Rediģēt grupas spēli',
      'Dashboard: Draw': 'Kontroles panelis: Izloze',
      'Draw is Required Field': 'Izloze ir obligāts lauks',
      'Please add points to submit': 'Lūdzu, pievienojiet punktus, lai iesniegtu',
      'Select Draw': 'Izvēlieties izlozi',
      'No Draws found': 'Nav atrastas izlozes',
      'All draws': 'Visas izlozes',
      'Publish Draws': 'Publicēt izlozes',
      'Hide Draws': 'Slēpt izlozes',
      'Generate Order Of Play': 'Ģenerēt spēļu kārtību',
      'Add new Draw': 'Pievienot jaunu izlozi',
      'Draw templates is Required Field': 'Izlozes veidnes ir obligāts lauks',
      'Draw title is Required Field': 'Izlozes nosaukums ir obligāts lauks',
      'Generate draw is Required Field': 'Ģenerēt izlozi ir obligāts lauks',
      'Live Standings is Required Field': 'Tiešraides rezultāti ir obligāts lauks',
      'Start Live Standings is Required Field': 'Sākt tiešraides rezultātus ir obligāts lauks',
      'End Live Standings is Required Field': 'Beigt tiešraides rezultātus ir obligāts lauks',
      'Draw type is Required Field': 'Izlozes tips ir obligāts lauks',
      'Draw segment is Required Field': 'Izlozes segments ir obligāts lauks',
      'Qual winners is Required Field': 'Kvalifikācijas uzvarētāji ir obligāts lauks',
      'No of seeds is Required Field': 'Sēklu skaits ir obligāts lauks',
      'Regenerate Draw Successfully': 'Izloze veiksmīgi pārģenerēta',
      'Add Draw Successfully': 'Izloze veiksmīgi pievienota',
      'Draw title': 'Izlozes nosaukums',
      'Draw segment': 'Izlozes segments',
      'Templates size': 'Veidņu izmērs',
      'Add Match for 3rd Place': 'Pievienot spēli par 3. vietu',
      'Qual Winners': 'Kvalifikācijas uzvarētāji',
      'Generate Draw': 'Ģenerēt izlozi',
      'Main Draw': 'Galvenā izloze',
      'No Main Draw found': 'Nav atrasta galvenā izloze',
      'Main Draw Entries': 'Galvenās izlozes ieraksti',
      'Select Live Standings': 'Izvēlieties tiešraides rezultātus',
      'Loading...': 'Ielādē...',
      'Live Standings from': 'Tiešraides rezultāti no',
      'Live Standings to': 'Tiešraides rezultāti līdz',
      'Algo Type': 'Algo tips',
      'Regenerate Draw': 'Pārģenerēt izlozi',
      'Add Draw': 'Pievienot izlozi',
      deleteDrawWarning: 'Visi izlozes ieraksti un spēles tiks izdzēsti un izloze tiks ģenerēta no jauna. Vai tiešām vēlaties pārģenerēt izlozi?',
      Warning: 'Brīdinājums',
      Confirm: 'Apstiprināt',
      'Generate order of play Successfully': 'Spēļu kārtība veiksmīgi ģenerēta',
      'Generate order of play': 'Ģenerēt spēļu kārtību',
      'Select Draws': 'Izvēlieties izlozes',
      'Select Rounds': 'Izvēlieties kārtas',
      'Time Settings': 'Laika iestatījumi',
      Select: 'Izvēlieties',
      'PDF Generated Successfully!': 'PDF veiksmīgi ģenerēts!',
      'Publishing draw...': 'Publicē izlozi...',
      'Hiding draw...': 'Slēpj izlozi...',
      'Successfully!': 'Veiksmīgi!',
      'Regenerate this draw': 'Pārģenerēt šo izlozi',
      editDrawNameInfo: 'Ja vēlaties mainīt izlozes izmēru vai jebkuru citu izlozes iestatījumu, jums jādzēš izloze un jāpiešķir jauna.',
      'Draw Name': 'Izlozes nosaukums',
      'Edit Draw': 'Rediģēt izlozi',
      'Draw Name Edited Successfully': 'Izlozes nosaukums veiksmīgi rediģēts',
      'Draw Name is required': 'Izlozes nosaukums ir obligāts',
      'Type is required': 'Tips ir obligāts',
      'Publish this draw': 'Publicēt šo izlozi',
      'Hide this draw': 'Slēpt šo izlozi',
      'There is no available draws': 'Nav pieejamu izložu',
      'Draws deleted successfully.': 'Izlozes veiksmīgi dzēstas.',
      'Delete Selected Draws': 'Dzēst izvēlētās izlozes',
      deleteDrawsWarning: 'Vai tiešām vēlaties dzēst izvēlētās izlozes?',
      deleteDrawEntryWarning: 'Vai tiešām vēlaties dzēst šo ierakstu?',
      'Select a Entry': 'Izvēlieties ierakstu',
      'Edit team One': 'Rediģēt pirmo komandu',
      'Edit team Two': 'Rediģēt otro komandu',
      'Team Edited Successfully': 'Komanda veiksmīgi rediģēta',
      'Deleting Entry...': 'Dzēš ierakstu...',
      'Add team One to this Match': 'Pievienot pirmo komandu šai spēlei',
      'Add team Two to this Match': 'Pievienot otro komandu šai spēlei',
      'Team Added to Seed Successfully': 'Komanda veiksmīgi pievienota sēklai',
      'Score is Required Field': 'Rezultāts ir obligāts lauks',
      'Match Score Added Successfully': 'Spēles rezultāts veiksmīgi pievienots',
      'Match score': 'Spēles rezultāts',
      'Edit score': 'Rediģēt rezultātu',
      'All Draws are hidden': 'Visas izlozes ir paslēptas',
      Upload: 'Augšupielādēt',
      'Upload File': 'Augšupielādēt failu',
      'Upload File in OOP': 'Augšupielādēt failu spēļu kārtībā',
      'Upload Document': 'Augšupielādēt dokumentu',
      'Document Name': 'Dokumenta nosaukums',
      'Name is required': 'Nosaukums ir obligāts',
      'Are you sure you want to delete this document?': 'Vai tiešām vēlaties dzēst šo dokumentu?',
      deleteGroupMatchWarning: 'Vai tiešām vēlaties dzēst šo grupas spēli?',
      deleteMultipleResultsWarning: 'Vai tiešām vēlaties dzēst izvēlētos rezultātus?',
      'How to Set Up a Tournament Draw?': 'Kā izveidot turnīra izlozi?',
      setupTournament1: 'Kad esat norādījis izlozes kategoriju un nosaukumu, sekojiet šiem soļiem, lai izveidotu turnīra izlozi:',
      'Select the Segment:': 'Izvēlieties segmentu:',
      'Choose from': 'Izvēlieties no',
      'Select the Draw Type:': 'Izvēlieties izlozes veidu:',
      'Single Elimination:': 'Vienreizēja izslēgšana:',
      'Participants are eliminated after their first loss': 'Dalībnieki tiek izslēgti pēc pirmā zaudējuma.',
      'Double Elimination:': 'Dubultā izslēgšana:',
      'Participants are eliminated after two losses': 'Dalībnieki tiek izslēgti pēc diviem zaudējumiem.',
      'Generate the Draw:': 'Ģenerēt izlozi:',
      'Choose the source for generating the draw:': 'Izvēlieties avotu izlozes ģenerēšanai:',
      'Use the list of registered participants': 'Izmantot reģistrēto dalībnieku sarakstu.',
      'Use standings based on group results': 'Izmantot rezultātus, pamatojoties uz grupu rezultātiem.',
      setupTournament2: 'Ģenerēt tukšu izlozi un manuāli pievienot dalībniekus pozīcijām.',
      setupTournament3: 'ja izvēlēts, jums būs papildu iespēja ģenerēt izlozi no',
      setupTournament4: 'Šajā gadījumā jums jāizvēlas izloze, no kuras ģenerēt.',
      setupTournament5: 'Sistēma automātiski izvēlēsies dalībniekus, kuri zaudējuši savu pirmo spēli, ar iespēju tiesnešiem pielāgot izvēli pēc vajadzības.',
      setupTournament6: 'Iestatiet ģenerēšanas algoritmu (ja izvēlētais avots nav manuāls):',
      setupTournament7: 'Sekos starptautisko federāciju noteikumiem, iekļaujot sēšanas un randomizāciju.',
      setupTournament8: 'Piemēram, 16 dalībnieku izlozē pirmais sēklis spēlē pret 16. sēkli, otrais sēklis pret 15. sēkli un tā tālāk.',
      'How to Generate the Order of Play?': 'Kā ģenerēt spēļu secību?',
      'To generate the Order of Play automatically': 'Lai automātiski ģenerētu spēļu secību',
      Click: 'Noklikšķiniet',
      'in the draw navigation.': 'izlozes navigācijā.',
      'Select the specific': 'Izvēlieties konkrēto',
      'on which the matches will be set': 'uz kura spēles tiks noteiktas',
      'Specify the approximate duration of each match': 'Norādiet katras spēles aptuveno ilgumu',
      setupTournament9: 'Kad spēles ir ģenerētas, jūs varat pielāgot detaļas trīs dažādos skatījumos',
      'Sorted by match time': 'Kārtotas pēc spēles laika',
      setupTournament10: 'Kortus kā kolonnu nosaukumus ar spēlēm zem attiecīgā korta',
      setupTournament11: 'Rāda tikai kategoriju nosaukumus un krāsas, lai nodrošinātu spēļu secības pārskatu',
      setupTournament12: 'Ja jums ir jāievada spēļu detaļas manuāli, noklikšķiniet uz',
      setupTournament13: 'zem spēles un iestatiet visas nepieciešamās detaļas',
      'How to Enter Results?': 'Kā ievadīt rezultātus?',
      'To enter match results': 'Lai ievadītu spēļu rezultātus',
      setupOOP1: 'Izvēlieties spēli, kuru vēlaties atjaunināt (izlozē vai spēļu secībā).',
      'Set the winner and the score': 'Norādiet uzvarētāju un rezultātu',
      'Ensure you format the score correctly': 'Pārliecinieties, ka rezultāts ir pareizi formatēts',
      "Always start with the winner's score": 'Vienmēr sāciet ar uzvarētāja rezultātu',
      'Separate sets with a space': 'Atskiriet setus ar atstarpi',
      'Separate games within a set with a colon': 'Atskiriet spēles setā ar kolu',
      'Indicate a set tiebreak in parentheses without a space after the set': 'Norādiet seta tiebreak iekavās bez atstarpes pēc seta',
      'Indicate a super tiebreak set in square brackets with a space before it': 'Norādiet super tiebreak setu kvadrātiekavās ar atstarpi pirms tā',
      Example: 'Piemērs',
      'Draws are not available to the public until you click': 'Izlozes nav pieejamas publikai, līdz jūs noklikšķiniet',
      setupDraw1: 'Jūs varat publicēt visas izlozes uzreiz vai individuāli. Ņemiet vērā, ka jebkura jauna izloze pēc noklusējuma ir slēpta no publikas',
      'Delete Draws': 'Dzēst izlozes',
      'To delete a draw': 'Lai dzēstu izlozi',
      'Check the checkbox next to the draw title': 'Atzīmējiet izvēles rūtiņu blakus izlozes nosaukumam',
      'Automatically Submit Final Results (Place / Points)': 'Automātiski iesniegt gala rezultātus (vieta / punkti)',
      'To submit final results automatically, follow these steps': 'Lai automātiski iesniegtu gala rezultātus, sekojiet šiem soļiem',
      setupDraw2: 'Sadaļā "Izlozes" atrodiet un noklikšķiniet uz "Iesniegt gala rezultātus" pogas augšējā labajā stūrī',
      'Choose the draw from which you want to get the positions': 'Izvēlieties izlozi, no kuras vēlaties iegūt pozīcijas',
      setupDraw3: 'Pārbaudiet pozīcijas, lai pārliecinātos, ka viss ir pareizi. Ja pamanāt kādas neprecizitātes, jūtieties brīvi manuāli pielāgot datus pēc nepieciešamības',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'Alternatīvi - iesniegt gala rezultātus manuāli lapā "Gala rezultāti"',
      drawExtra1: 'Lai rādītu dalībnieku klubu nosaukumus un sadalītu kluba biedrus izlozē, atzīmējiet atbilstošās izvēles rūtiņas',
      'You can download and print the draw': 'Jūs varat lejupielādēt un izdrukāt izlozi',
      'For pickleball, you can export matches in the correct format for DUPR': 'Pickleball spēlēm jūs varat eksportēt spēles pareizajā formātā DUPR',
      wildCardQuestion: 'Kā precīzi saskaitīt dalībnieku skaitu galvenajā izlozē, kvalifikācijās, sēšanā un wild card (WC)?',
      wildCardAnswer:
        'Lai precīzi saskaitītu dalībnieku skaitu galvenajā izlozē, kvalifikācijās, sēšanā un wild card (WC), pārliecinieties, ka esat pareizi iestatījis turnīra kategoriju iestatījumus. To var izdarīt, dodoties uz',
      wildCardAnswer1: 'Iestatījumi > Kategoriju iestatījumi.',
      wildCardAnswer2: 'Turpat varat pielāgot pieņemtās maksājumu metodes, dalības maksu, reģistrācijas ierobežojumus un daudz ko citu.',
      'How to auto confirm entries?': 'Kā automātiski apstiprināt pieteikumus?',
      confirmEntries1: 'Lai automātiski apstiprinātu pieteikumus pēc reģistrācijas vai maksājuma, dodieties uz',
      confirmEntries2: 'Turnīra iestatījumi > Turnīra reģistrācija',
      'and check the box for': 'un atzīmējiet rūtiņu',
      'Automatically Confirm Entries': 'Automātiski apstiprināt pieteikumus',
      'Add manual tournament results': 'Pievienot manuālus turnīra rezultātus',
      results1: 'Lai manuāli pievienotu turnīra rezultātus (gala rezultātus), noklikšķiniet uz',
      'Add Final Standings Manually': 'Pievienot gala rezultātus manuāli',
      results2: 'pogas. Ja rodas kādas problēmas, jūs varat viegli izdzēst visu ierakstu bloku vai izvēlēties konkrētus ierakstus, lai mainītu to vietu vai punktus',
      results3: 'Lai vietas un punkti tiktu parādīti pareizi, pārliecinieties, ka tie ir iestatīti',
      results4: 'Turnīra pārvaldnieks -> Līga -> Punktu sadale',
      oop1: 'Lai spēļu secība (OOP) būtu redzama publikai, jums tā jāpulicē spēļu secības navigācijā.',
      'Edit / Delete Multiple Matches': 'Rediģēt / Dzēst vairākas spēles',
      oop2: 'Izvēlieties vairākas spēles, lai tās rediģētu vai dzēstu vienlaikus.',
      'You can upload documents related to OOP': 'Jūs varat augšupielādēt dokumentus, kas saistīti ar OOP.',
      'You can download and print matches': 'Jūs varat lejupielādēt un izdrukāt spēles.',
      oopExtra: 'Pickleball spēlēm jūs varat eksportēt spēles pareizajā formātā DUPR.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": 'Kā atjaunināt dalībnieku reitingus, sēšanu un segmentāciju?',
      'To update rankings, segmentation, or seeding': 'Lai atjauninātu reitingus, segmentāciju vai sēšanu',
      'Click the': 'Noklikšķiniet uz',
      'button (represented by a three-dot icon) located next to the': 'pogas (attēlota ar trīs punktu ikonu), kas atrodas blakus',
      button: 'poga',
      'from the dropdown menu': 'no nolaižamās izvēlnes',
      'Segmentation Explained': 'Segmentācijas skaidrojums',
      "To ensure participants' rankings are indicated properly": 'Lai nodrošinātu dalībnieku reitingu pareizu norādi',
      entries1: 'Pārliecinieties, ka Līgas pārvaldnieks ir pareizi iestatījis turnīra kategorijas',
      'This can be found under': 'To var atrast sadaļā',
      'League -> Tournament Categories.': 'Līga -> Turnīra kategorijas.',
      entries2: 'Pārliecinieties, ka turnīra kategorijas ir savienotas ar pareizo reitingu kategoriju.',
      entries3:
        'Ja uzskatāt, ka reitingi netiek pareizi atjaunināti, pārliecinieties, ka reitingu kategorijas ir iestatītas saskaņā ar reitingu noteikumiem Turnīra pārvaldnieka panelī. Tas ietver pareizu turnīra kategoriju iestatīšanu, ierobežojumus, filtrus utt.',
      Note: 'Piezīme',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': 'Kā atjaunināt vai atsaukt pieteikumus un piešķirt wild cards (WC)?',
      'To Add a New Entry': 'Lai pievienotu jaunu pieteikumu',
      "By default, you need to provide the player's email.": 'Pēc noklusējuma jums ir jānorāda spēlētāja e-pasts.',
      entries4: 'Ja jūsu organizācijai ir iespējotas licences, jūs varat izvēlēties no sportistiem, kuriem ir aktīva licence.',
      'To Withdraw an Entry': 'Lai atsauktu pieteikumu',
      'Select the entries you want to withdraw.': 'Izvēlieties pieteikumus, kurus vēlaties atsaukt.',
      'and then choose': 'un pēc tam izvēlieties',
      'To Assign a Wild Card (WC)': 'Lai piešķirtu wild card (WC)',
      'Next to the entry, click the three-dot icon and select': 'Blakus pieteikumam noklikšķiniet uz trīs punktu ikonas un izvēlieties',
      entries5: 'Pārliecinieties, ka WC skaits ir iestatīts kategorijas iestatījumos; pretējā gadījumā sistēma neļaus jums piešķirt WC.',
      'Where can I find participant details?': 'Kur es varu atrast dalībnieku informāciju?',
      'You can find participant details in two ways': 'Jūs varat atrast dalībnieku informāciju divos veidos',
      'From the Entry List': 'No pieteikumu saraksta',
      entries6: 'Noklikšķiniet uz dalībnieka vārda tieši pieteikumu sarakstā, lai skatītu viņa informāciju.',
      'From the Tournament Data Section': 'No turnīra datu sadaļas',
      entries7: 'Dodieties uz Turnīra dati -> Dalībnieki kreisajā sānjoslā, lai skatītu visu dalībnieku informāciju.',
      entries8: 'Papildus jums ir iespēja eksportēt visu dalībnieku informāciju vai to e-pastus atsevišķi no dalībnieku sadaļas.',
      'How to Add Groups?': 'Kā pievienot grupas?',
      'To add groups, follow these steps': 'Lai pievienotu grupas, sekojiet šiem soļiem',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'Dodieties uz sadaļu "Grupas" un noklikšķiniet uz pogas "Pievienot grupas".',
      'Select a Category': 'Izvēlieties kategoriju',
      'Choose the category for which you want to add groups.': 'Izvēlieties kategoriju, kurai vēlaties pievienot grupas.',
      'Set Number of Groups and Naming Type': 'Iestatiet grupu skaitu un nosaukumu veidu',
      'Specify the number of groups you want to create.': 'Norādiet grupu skaitu, kuras vēlaties izveidot.',
      'Choose the naming convention for the groups': 'Izvēlieties nosaukumu konvenciju grupām',
      'Select the Group Type': 'Izvēlieties grupas veidu',
      'Used when athletes or teams are competing against each other': 'Izmanto, kad sportisti vai komandas sacenšas viens pret otru',
      'Used when single athletes are playing doubles with each other.': 'Izmanto, kad individuālie sportisti spēlē dubultspēlēs viens ar otru.',
      'Standard Format Options': 'Standarta formāta opcijas',
      groups1: 'Ja izvēlaties standarta formātu, izlemiet, vai ģenerēt grupas dalībniekus automātiski vai manuāli',
      'Select an algorithm': 'Izvēlieties algoritmu',
      'Based on international rules.': 'Balstīts uz starptautiskajiem noteikumiem.',
      groups2: 'Piemēram, ja ir 3 grupas un 9 dalībnieki, 1. grupā būtu 1., 6. un 7. vietas spēlētāji; 2. grupā būtu 2., 5. un 8. vietas spēlētāji; 3. grupā būtu 3., 4. un 9. vietas spēlētāji.',
      groups3:
        'Pievienojiet komandas katrai grupai, un, kad esat pabeidzis, pārliecinieties, ka noklikšķināt uz pogas "Izveidot spēles", kas atrodas blakus katras grupas nosaukumam (šis solis tiks automatizēts nākamajā izlaidumā).',
      'King Format Options': 'King formāta opcijas',
      'If you select the King format, choose the': 'Ja izvēlaties King formātu, izvēlieties',
      'Display List of Matches': 'Rādīt spēļu sarakstu',
      groups4: 'Lai rādītu spēļu sarakstu zem katras grupas, atzīmējiet izvēles rūtiņu ar uzrakstu',
      'To generate the Order of Play (OOP)': 'Lai ģenerētu spēļu secību (OOP)',
      'Choose the Scope': 'Izvēlieties apjomu',
      groups5: 'Izlemiet, vai vēlaties ģenerēt OOP visām grupām uzreiz (atrodas grupu navigācijā) vai katrai grupai atsevišķi (atrodas blakus katras grupas nosaukumam).',
      'Generate the OOP': 'Ģenerēt OOP',
      'Click the appropriate button.': 'Noklikšķiniet uz atbilstošās pogas.',
      'Select the rounds for which you want to generate the OOP.': 'Izvēlieties kārtas, kurām vēlaties ģenerēt OOP.',
      'Set the date and time for the first match.': 'Iestatiet pirmās spēles datumu un laiku.',
      'Enter the estimated duration for the matches.': 'Ievadiet aptuveno spēļu ilgumu.',
      'Adjust Matches': 'Pielāgot spēles',
      'You can also create matches manually and make adjustments after the OOP is generated.': 'Jūs varat arī manuāli izveidot spēles un veikt pielāgojumus pēc OOP ģenerēšanas.',
      'Select the Match': 'Izvēlieties spēli',
      groups6: 'Izvēlieties spēli, kuru vēlaties atjaunināt, vai nu grupā, vai no spēļu secības.',
      'Enter the Winner and Score': 'Ievadiet uzvarētāju un rezultātu',
      'Set the winner of the match.': 'Iestatiet spēles uzvarētāju.',
      'Format the score as follows': 'Formatējiet rezultātu šādi',
      "Start with the winner's score.": 'Sāciet ar uzvarētāja rezultātu.',
      'Separate sets with a space.': 'Atsķiriet setus ar atstarpi.',
      'Use parentheses for a set tiebreak (no space after the set)': 'Izmantojiet iekavas seta tiebreak norādei (bez atstarpes pēc seta)',
      'Use square brackets for a super tiebreak (with a space before it)': 'Izmantojiet kvadrātiekavas super tiebreak norādei (ar atstarpi pirms tā)',
      'How to Generate the Final Round of King Format?': 'Kā ģenerēt King formāta pēdējo kārtu?',
      'To generate the final round in King format': 'Lai ģenerētu pēdējo kārtu King formātā',
      'Create New Groups': 'Izveidot jaunas grupas',
      'Set up new groups and select the "King" format.': 'Iestatiet jaunas grupas un izvēlieties "King" formātu.',
      'Select the Round': 'Izvēlieties kārtu',
      'Choose "Final" as the round type.': 'Izvēlieties "Fināls" kā kārtas veidu.',
      'Automatic Sorting': 'Automātiskā šķirošana',
      groups7: 'Platforma automātiski izmantos grupu vietas un attiecības, lai sakārtotu dalībniekus finālai kārtai.',
      'You can download and print groups.': 'Jūs varat lejupielādēt un izdrukāt grupas.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'Pickleball spēlēm jūs varat eksportēt spēles pareizajā formātā DUPR.',
    },
  },
  ka: {
    translation: {
      'Preview Tournament': 'ტურნირის გადახედვა',
      'Control Panel': 'მართვის პანელი',
      Instructions: 'ინსტრუქციები',
      'All Tournaments': 'ყველა ტურნირი',
      Settings: 'პარამეტრები',
      'General Data': 'ზოგადი მონაცემები',
      'Category Settings': 'კატეგორიის პარამეტრები',
      Entries: 'ჩანაწერები',
      Requests: 'ითხოვს',
      Withdrawals: 'ამოღებები',
      Matches: 'მატჩები',
      Pairings: 'წყვილები',
      Standings: 'პოზიციები',
      'Custom Metrics': 'მორგებული მეტრიკა',
      'List & Results': 'სია და შედეგები',
      Groups: 'ჯგუფები',
      Draws: 'ამახვილებს',
      'Order Of Play': 'თამაშის ორდენი',
      'Live Standings': 'ცოცხალი პოზიციები',
      'Final Standings': 'საბოლოო პოზიციები',
      'Tournament Data': 'ტურნირის მონაცემები',
      Participants: 'მონაწილეები',
      Documents: 'დოკუმენტები',
      Partners: 'პარტნიორები',
      'No tournament selected': 'ტურნირი არ არის შერჩეული',
      'Copy Tournament': 'კოპირების ტურნირი',
      'Make a copy of selected tournament': 'შექმენით შერჩეული ტურნირის ასლი',
      Completed: 'დასრულებული',
      Upcoming: 'მომავალი',
      Active: 'აქტიური',
      Total: 'სულ',
      'Tournaments list': 'ტურნირების სია',
      'Tournament Title': 'ტურნირის სათაური',
      Search: 'ძიება',
      'Create new Tournament': 'შექმენით ახალი ტურნირი',
      Delete: 'წაშლა',
      Organizer: 'ორგანიზატორი',
      Location: 'მდებარეობა',
      'List of categories': 'კატეგორიების სია',
      'Start - End': 'დაწყება - დასასრული',
      Dashboard: 'დაფა',
      'Athlete Portal': 'სპორტსმენის პორტალი',
      Profile: 'პროფილი',
      Logout: 'გამოსვლა',
      Create: 'შექმნა',
      'Add Tournament': 'დაამატეთ ტურნირი',
      'Add League': 'ლიგის დამატება',
      'Add Club': 'კლუბის დამატება',
      'Add News': 'დაამატეთ ახალი ამბები',
      'Add Team': 'გუნდის დამატება',
      'Edit Tournament': 'ტურნირის რედაქტირება',
      'Create Tournament': 'შექმენით ტურნირი',
      editAddDesc: 'დააკონფიგურირეთ ზოგადი პარამეტრები, გარეგნობა, ფაქტების ცხრილი, დამატებითი პარამეტრები, მართვა და სხვა.',
      'Configure Categories': 'კატეგორიების კონფიგურაცია',
      configureCategoriesDesc: 'მიუთითეთ პირადი კატეგორიის პარამეტრები: მონაწილეთა მაქსიმალური რაოდენობა, კვალიფიკატორები, WC-ები, თესლი, გადახდის მეთოდები და სხვა.',
      'Manage Entries': 'ჩანაწერების მართვა',
      manageEntriesDesc: 'დაადასტურეთ/უარყოთ რეგისტრირებული ჩანაწერები, შემოიტანეთ ჩანაწერები CSV შაბლონიდან, მიანიჭეთ რეიტინგები და დათესვა, განაახლეთ / დააკოპირეთ / წაშალეთ ჩანაწერები და სხვა',
      'Go to Requests': 'გადადით მოთხოვნებზე',
      'Go to Entries': 'გადადით ჩანაწერებზე',
      'Create Groups, Draws, and Live Standings': 'შექმენით ჯგუფები, გათამაშებები და ცოცხალი პოზიციები',
      groupsDrawsLiveStandingsDesc: 'დაამატეთ და შექმენით ჯგუფები, გათამაშებები და ცოცხალი პოზიციები რამდენიმე დაწკაპუნებით. ',
      'Go to Groups': 'გადადით ჯგუფებში',
      'Go to Draws': 'გადადით Draws-ზე',
      'Go to Live Standings': 'გადადით Live Standing-ზე',
      'Go to Order of Play': 'გადადით თამაშის ორდერზე',
      'Generate Order of Play': 'შექმენით თამაშის ორდერი',
      generateOrderOfPlayDesc: 'ჯგუფების/გათამაშების შექმნის შემდეგ, მატჩების გამოქვეყნებამდე ბოლო ნაბიჯი არის მატჩების გენერაცია (თამაშის თანმიმდევრობა). ',
      'Publish data': 'მონაცემების გამოქვეყნება',
      publishDataDesc: 'იმისთვის, რომ მონაცემები საზოგადოებისთვის ხელმისაწვდომი გახადოთ, თქვენ უნდა დარწმუნდეთ, რომ გამოქვეყნებულია ჯგუფები, გათამაშებები, ცოცხალი პოზიციები და თამაშის რიგი. ',
      'Submit Tournament Results': 'ტურნირის შედეგების გაგზავნა',
      submitTournamentResultsDesc: 'ტურნირის დასასრულებლად ბოლო ნაბიჯი არის ტურნირის შედეგების წარდგენა და ქულების მინიჭება ქულების ცხრილის მიხედვით (თუ ჩართულია რეიტინგის ფუნქცია). ',
      'Add Results Manually': 'დაამატეთ შედეგები ხელით',
      instructionsPageTitle: 'დაფა: ინსტრუქციები',
      controlPanelDesc: 'გაუშვით ტურნირი ეტაპობრივად ამ მართვის პანელიდან',
      General: 'გენერალი',
      'Dates & Location': 'თარიღები და ადგილმდებარეობა',
      Appearance: 'გარეგნობა',
      'Tournament Registration': 'ტურნირის რეგისტრაცია',
      Extras: 'დამატებები',
      Management: 'მენეჯმენტი',
      'Fact Sheet': 'ფაქტების ცხრილი',
      'Select League': 'აირჩიეთ ლიგა',
      'Select Categories': 'აირჩიეთ კატეგორიები',
      'Payment methods': 'გადახდის მეთოდები',
      'Payment type': 'გადახდის ტიპი',
      Currency: 'ვალუტა',
      'Online Entry Fee': 'ონლაინ შესვლის საფასური',
      'Entry Fee': 'შესვლის საფასური',
      'Onsite Entry Fee': 'ადგილზე შესვლის საფასური',
      'Number of Rounds': 'რაუნდების რაოდენობა',
      'Number of Courts': 'სასამართლოების რაოდენობა',
      'Number of Stages': 'ეტაპების რაოდენობა',
      'Tournament timezone': 'ტურნირის დროის ზონა',
      'Registration Start Date': 'რეგისტრაციის დაწყების თარიღი',
      'Start Date': 'დაწყების თარიღი',
      'End Date': 'დასრულების თარიღი',
      'Entry Deadline': 'შესვლის ბოლო ვადა',
      'Withdrawal Deadline': 'გატანის ვადა',
      'Select Club': 'აირჩიეთ კლუბი',
      'No clubs found in selected league': 'არჩეულ ლიგაში კლუბები არ მოიძებნა',
      'Select Country': 'აირჩიეთ ქვეყანა',
      City: 'ქალაქი',
      Address: 'მისამართი',
      Logo: 'ლოგო',
      logoDesc: 'ჩვენ გირჩევთ სურათს, რომელიც არის 256 x 256 პიქსელი',
      'Tournament title': 'ტურნირის სათაური',
      'Enter tournament title': 'შეიყვანეთ ტურნირის სათაური',
      Description: 'აღწერა',
      tournamentDesc: 'დაწერეთ აღწერა ტურნირის შესახებ',
      'Video URL': 'ვიდეოს URL',
      videoLink: 'ჩადეთ ვიდეო ბმული აქ',
      tourWebUrl: 'ტურნირის ვებსაიტის URL',
      'Tournament Poster': 'ტურნირის პოსტერი',
      'Upload Poster': 'ატვირთეთ პოსტერი',
      'Cover Banner': 'საფარის ბანერი',
      bannerDesc: 'ჩვენ გირჩევთ სურათს, რომელიც არის 2400 x 600 პიქსელი.',
      'Upload Banner': 'ატვირთეთ ბანერი',
      Regulations: 'რეგულაციები',
      'Upload Regulations': 'ატვირთვის წესები',
      'No Image': 'სურათი არ არის',
      dragDropDesc: 'გადაათრიეთ და ჩამოაგდეთ ფაილი აქ, ან დააწკაპუნეთ ფაილის არჩევაზე',
      dragDropDesc2: 'გადაათრიეთ და ჩამოაგდეთ ფაილები აქ, ან დააწკაპუნეთ ფაილების არჩევაზე',
      'Select File': 'აირჩიეთ ფაილი',
      'Additional Questions': 'დამატებითი კითხვები',
      'Add New Question': 'დაამატეთ ახალი შეკითხვა',
      'Terms and Conditions': 'წესები და პირობები',
      'Start Typing': 'დაიწყეთ აკრეფა',
      'External tournament registration link': 'გარე ტურნირის რეგისტრაციის ლინკი',
      'Automatically confirm entries': 'ჩანაწერების ავტომატურად დადასტურება',
      confirmEntriesInfo: 'ტურნირის სარეგისტრაციო ჩანაწერები პირდაპირ გადადის ჩანაწერებზე და არა მოთხოვნებზე',
      closeRegistration: 'დახურეთ რეგისტრაცია ყველა ტურნირის კატეგორიაში',
      'Edit this question': 'შეცვალეთ ეს შეკითხვა',
      'Add new question': 'დაამატეთ ახალი შეკითხვა',
      'Question updated successfully!': 'კითხვა წარმატებით განახლდა!',
      'Question added successfully!': 'კითხვა წარმატებით დაემატა!',
      Question: 'კითხვა',
      'Field Type': 'ველის ტიპი',
      Price: 'ფასი',
      'Field Option': 'ველის ვარიანტი',
      removeQuestionError: 'ველის ტიპის არჩევისთვის საჭიროა მინიმუმ 1 ველის ვარიანტი',
      Remove: 'ამოღება',
      'Add new option': 'დაამატეთ ახალი ვარიანტი',
      questionInfo: 'დაუსვით ეს შეკითხვა თითოეულ სპორტსმენს',
      Submit: 'გაგზავნა',
      isFeatured: 'გახადეთ ეს ტურნირი გამორჩეული',
      partnerSearch: 'პარტნიორის ძიების ჩართვა',
      enablePracticeCourtsBooking: 'ჩართეთ პრაქტიკული სასამართლოს დაჯავშნა',
      showTournamentChessManagerCalendar: 'აჩვენეთ ეს ტურნირი ჭადრაკის მენეჯერის კალენდარში',
      localRatedTournament: 'ადგილობრივი რეიტინგული ტურნირი',
      fideRatedTournament: 'FIDE-ს რეიტინგული ტურნირი',
      enableTransferBooking: 'გადარიცხვის ჯავშნის ჩართვა',
      enableLiveStream: 'პირდაპირი სტრიმინგის ჩართვა (მალე)',
      enableLiveScoring: 'ცოცხალი ქულების ჩართვა (მალე)',
      'Select Organizer': 'აირჩიეთ ორგანიზატორი',
      'No Organizer available': 'ორგანიზატორი არ არის ხელმისაწვდომი',
      'Select main referee': 'აირჩიეთ მთავარი მსაჯი',
      'Select referee': 'აირჩიეთ მსაჯი',
      'No referee available': 'არ არის ხელმისაწვდომი მსაჯი',
      'Select additional referees': 'აირჩიეთ დამატებითი მსაჯები',
      'Tournament status': 'ტურნირის სტატუსი',
      'Make tournament private': 'გახადე ტურნირი კერძო',
      'Set Password': 'პაროლის დაყენება',
      'New Password': 'ახალი პაროლი',
      'Confirm Password': 'დაადასტურეთ პაროლი',
      'No facts found': 'ფაქტები არ მოიძებნა',
      Save: 'შენახვა',
      Back: 'უკან',
      'Field Title': 'სფეროს სათაური',
      'Dashboard: Stage Settings': 'დაფა: ეტაპის პარამეტრები',
      'Edit tournament': 'ტურნირის რედაქტირება',
      'Change Settings': 'შეცვალეთ პარამეტრები',
      Title: 'სათაური',
      Status: 'სტატუსი',
      'General Limit': 'ზოგადი ლიმიტი',
      'Individual metric limit': 'ინდივიდუალური მეტრიკული ლიმიტი',
      'Allow participants to add results': 'მიეცით საშუალება მონაწილეებს დაამატონ შედეგები',
      'Attachments status': 'დანართების სტატუსი',
      'There is no available': 'არ არის ხელმისაწვდომი',
      'Public for everyone': 'საჯარო ყველასთვის',
      'Public only for participants': 'საჯარო მხოლოდ მონაწილეებისთვის',
      Hidden: 'დამალული',
      'Hide other participant results': 'სხვა მონაწილეთა შედეგების დამალვა',
      'Individual metric limit applied': 'გამოყენებულია ინდივიდუალური მეტრიკული ლიმიტი',
      'Attachments Status': 'დანართების სტატუსი',
      'Public to participants only': 'საჯარო მხოლოდ მონაწილეებისთვის',
      Saving: 'შენახვა',
      'General data': 'ზოგადი მონაცემები',
      'Category settings': 'კატეგორიის პარამეტრები',
      'Dashboard: Category Settings': 'დაფა: კატეგორიის პარამეტრები',
      'There is no available category!': 'არ არის ხელმისაწვდომი კატეგორია!',
      Category: 'კატეგორია',
      'Online Fee': 'ონლაინ გადასახადი',
      'Onsite Fee': 'ადგილზე გადასახადი',
      'VAT Amount': 'დღგ-ს თანხა',
      'Payment Type': 'გადახდის ტიპი',
      'Payment Method': 'გადახდის მეთოდი',
      Rounds: 'რაუნდები',
      Time: 'დრო',
      'Time per move': 'დრო თითო მოძრაობაზე',
      'Time after move': 'დრო გადაადგილების შემდეგ',
      'Extra time': 'დამატებითი დრო',
      Pairing: 'დაწყვილება',
      entries: 'ჩანაწერები',
      'Registration Status': 'რეგისტრაციის სტატუსი',
      'Additional service fee': 'დამატებითი მომსახურების საფასური',
      'Registration Limit': 'რეგისტრაციის ლიმიტი',
      Edit: 'რედაქტირება',
      'Registrations Limit': 'რეგისტრაციის ლიმიტი',
      'Include VAT amount?': 'მოიცავს დღგ-ს თანხას?',
      displayClubInfo: 'აჩვენეთ სპორტსმენის/გუნდის კლუბის სახელი შესვლის დეტალებში',
      Open: 'გახსენით',
      Closed: 'დახურულია',
      categorySettingsInfo: 'დააყენეთ მხოლოდ მონაცემები, რომელთა განახლება გსურთ არჩეული კატეგორიებისთვის',
      'Select category color': 'აირჩიეთ კატეგორიის ფერი',
      'Dashboard: Requests': 'დაფა: მოთხოვნები',
      'No category found': 'კატეგორია ვერ მოიძებნა',
      'Add New Request': 'დაამატეთ ახალი მოთხოვნა',
      Actions: 'მოქმედებები',
      Confirming: 'ადასტურებს',
      'Confirm Selected Requests': 'დაადასტურეთ არჩეული მოთხოვნები',
      NR: 'NR',
      Team: 'გუნდი',
      Player: 'მოთამაშე',
      Rating: 'რეიტინგი',
      Email: 'ელფოსტა',
      'Phone Number': 'ტელეფონის ნომერი',
      'Reg. time': 'რეგ. ',
      Paid: 'გადახდილი',
      'ADDITIONAL QUESTIONS': 'დამატებითი კითხვები',
      'Show Answer': 'პასუხის ჩვენება',
      'Download requests list': 'ჩამოტვირთვის მოთხოვნების სია',
      Decline: 'უარყოფა',
      'Confirm Decline': 'დაადასტურეთ უარი',
      requestDeclineWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული მოთხოვნების უარყოფა?',
      'Copy Selected Requests': 'დააკოპირეთ არჩეული მოთხოვნები',
      'No questions found': 'კითხვები ვერ მოიძებნა',
      'Answer/Files': 'პასუხი/ფაილი',
      Download: 'ჩამოტვირთვა',
      'Edit Entries successfully': 'ჩანაწერების რედაქტირება წარმატებით',
      'Tournament data or category not available': 'ტურნირის მონაცემები ან კატეგორია მიუწვდომელია',
      'Please mark the Checkbox': 'გთხოვთ მონიშნოთ მოსანიშნი ველი',
      'Confirming...': 'დადასტურება...',
      'Confirmed successfully!': 'წარმატებით დადასტურდა!',
      'Please enter both player emails': 'გთხოვთ, შეიყვანოთ ორივე მოთამაშის ელ.წერილი',
      'Please enter player email': 'გთხოვთ, შეიყვანოთ მოთამაშის ელფოსტა',
      'Step 1: Select category': 'ნაბიჯი 1: აირჩიეთ კატეგორია',
      'Athlete email (user account)': 'სპორტსმენის ელფოსტა (მომხმარებლის ანგარიში)',
      'Player is not registered in the platform': 'მოთამაშე არ არის რეგისტრირებული პლატფორმაზე',
      'First athlete email (user account)': 'პირველი სპორტსმენის ელფოსტა (მომხმარებლის ანგარიში)',
      'First player is not registered in the platform': 'პირველი მოთამაშე არ არის რეგისტრირებული პლატფორმაზე',
      'Second athlete email (user account)': 'მეორე სპორტსმენის ელფოსტა (მომხმარებლის ანგარიში)',
      'Second player is not registered in the platform': 'მეორე მოთამაშე არ არის რეგისტრირებული პლატფორმაზე',
      Update: 'განახლება',
      'No Team': 'არანაირი გუნდი',
      'Athlete email is required': 'საჭიროა სპორტსმენის ელ.წერილი',
      'First athlete email is required': 'პირველი სპორტსმენის ელ.წერილი აუცილებელია',
      'Second athlete email is required': 'საჭიროა მეორე სპორტსმენის ელ.წერილი',
      'Add Entries successfully': 'ჩანაწერების დამატება წარმატებით',
      'Select category': 'აირჩიეთ კატეგორია',
      'No category exists': 'კატეგორია არ არსებობს',
      Registration: 'რეგისტრაცია',
      Register: 'რეგისტრაცია',
      'There is no available requests!': 'არ არის ხელმისაწვდომი მოთხოვნები!',
      'Dashboard: Entries Data': 'დაფა: ჩანაწერების მონაცემები',
      'Request Updated Successfully!': 'მოთხოვნა წარმატებით განახლდა!',
      'Import CSV File': 'CSV ფაილის იმპორტი',
      'Import Entries': 'იმპორტის ჩანაწერები',
      'Add New Entry': 'დაამატეთ ახალი ჩანაწერი',
      'Entries deleted successfully.': 'ჩანაწერები წარმატებით წაიშალა.',
      'Confirm Deletion': 'დაადასტურეთ წაშლა',
      deleteEntriesWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული ჩანაწერების წაშლა?',
      'PLAYER 1': 'მოთამაშე 1',
      'PLAYER 2': 'მოთამაშე 2',
      Rank: 'წოდება',
      'Total Ranks': 'სულ წოდებები',
      Priority: 'პრიორიტეტი',
      Assign: 'მიანიჭეთ',
      'Limit reached': 'ლიმიტი მიღწეულია',
      'Assigning...': 'მინიჭება...',
      'Removing...': 'მიმდინარეობს ამოღება...',
      'Moving...': 'გადაადგილება...',
      'Updated successfully': 'წარმატებით განახლდა',
      'Please enter a value': 'გთხოვთ, შეიყვანოთ მნიშვნელობა',
      'Updating...': 'მიმდინარეობს განახლება...',
      'Please enter a rating': 'გთხოვთ შეიყვანოთ რეიტინგი',
      'Edit Rating': 'რეიტინგის რედაქტირება',
      'Please enter a rank': 'გთხოვთ შეიყვანოთ წოდება',
      'Edit Rank': 'რანგის რედაქტირება',
      'Change Category': 'კატეგორიის შეცვლა',
      'Move selected entries to Requests': 'არჩეული ჩანაწერების გადატანა მოთხოვნებში',
      'Withdraw selected entries': 'ამოიღეთ არჩეული ჩანაწერები',
      'Delete rankings': 'წაშალეთ რეიტინგები',
      'Update rankings & seeding': 'განაახლეთ რეიტინგები და დათესვა',
      'Download entry List': 'ჩამოტვირთეთ შესვლის სია',
      withdrawEntriesWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული შენატანების ამოღება?',
      'File uploaded & entries created successfully': 'ფაილი აიტვირთა და ჩანაწერები წარმატებით შეიქმნა',
      'Please select a file to Upload': 'გთხოვთ, აირჩიოთ ფაილი ასატვირთად',
      'Import entries via CSV template': 'ჩანაწერების იმპორტი CSV შაბლონის საშუალებით',
      'Download CSV template for Singles format category': 'ჩამოტვირთეთ CSV შაბლონი მარტოხელა ფორმატის კატეგორიისთვის',
      'CSV file Downloaded': 'CSV ფაილი ჩამოტვირთულია',
      'Download CSV template for Doubles format category': 'ჩამოტვირთეთ CSV შაბლონი Doubles ფორმატის კატეგორიისთვის',
      'Upload & create entries': 'ატვირთეთ და შექმენით ჩანაწერები',
      'There is no available entries!': 'არ არის ხელმისაწვდომი ჩანაწერები!',
      'Entry Fee is Required Field': 'შესვლის საფასური სავალდებულოა ველი',
      'Type is Required Field': 'ტიპი არის სავალდებულო ველი',
      'Payment Information Removed Successfully': 'გადახდის ინფორმაცია წარმატებით წაიშალა',
      'Paid Information Added Successfully': 'ფასიანი ინფორმაცია წარმატებით დამატებულია',
      'Update Paid information': 'განაახლეთ ფასიანი ინფორმაცია',
      'Paid information': 'ფასიანი ინფორმაცია',
      'Amount of entry fee': 'შესვლის საფასურის ოდენობა',
      Type: 'ტიპი',
      'Remove payment': 'გადახდის მოხსნა',
      'Drag and Drop a file here, or click to Select file': 'გადაიტანეთ და ჩამოაგდეთ ფაილი აქ, ან დააწკაპუნეთ ფაილის არჩევაზე',
      'Supported file': 'მხარდაჭერილი ფაილი',
      'Dashboard: Withdrawals Data': 'დაფა: ამოღების მონაცემები',
      'Entries Deleted Successfully!': 'ჩანაწერები წარმატებით წაიშალა!',
      'Deleting...': 'წაშლა...',
      'There is no available withdrawals!': 'არ არის ხელმისაწვდომი გატანა!',
      deleteWithdrawalsWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული ჩანაწერების წაშლა?',
      'Move selected entries to Entry list': 'არჩეული ჩანაწერების გადატანა შესვლის სიაში',
      'Dashboard: Groups Data': 'დაფა: ჯგუფების მონაცემები',
      'Back to tournament': 'ტურნირზე დაბრუნება',
      'Submit results to Live standings': 'შედეგების გაგზავნა ცოცხალ რეიტინგში',
      'Please select at least one group': 'გთხოვთ, აირჩიოთ მინიმუმ ერთი ჯგუფი',
      'Live standings submitted successfully': 'ცოცხალი პოზიციები წარმატებით გაიგზავნა',
      'Submit Live Standings': 'გამოაგზავნეთ ცოცხალი პოზიციები',
      'Select Category': 'აირჩიეთ კატეგორია',
      'Select Groups...': 'აირჩიეთ ჯგუფები...',
      'Please select at least one Group.': 'გთხოვთ, აირჩიოთ მინიმუმ ერთი ჯგუფი.',
      'Standings Title': 'პოზიციის სათაური',
      Sorting: 'დახარისხება',
      'Removing groups...': 'ჯგუფების წაშლა...',
      'Groups removed successfully': 'ჯგუფები წარმატებით წაიშალა',
      Hiding: 'იმალებოდა',
      Publishing: 'გამომცემლობა',
      Group: 'ჯგუფი',
      Successfully: 'წარმატებულად',
      Published: 'გამოქვეყნდა',
      Hide: 'დამალვა',
      Publish: 'გამოაქვეყნეთ',
      'Updating scores...': 'ქულების განახლება...',
      'Scores updated successfully': 'ქულები წარმატებით განახლდა',
      'Deleting group...': 'ჯგუფის წაშლა...',
      'Group Deleted successfully': 'ჯგუფი წარმატებით წაიშალა',
      'Deleting entries...': 'ჩანაწერების წაშლა...',
      'Entries deleted successfully': 'ჩანაწერები წარმატებით წაიშალა',
      'Delete Selected Entries': 'არჩეული ჩანაწერების წაშლა',
      'Delete Selected Teams': 'არჩეული გუნდების წაშლა',
      'Publish this group': 'გამოაქვეყნეთ ეს ჯგუფი',
      'Hide this group': 'ამ ჯგუფის დამალვა',
      'Print/Download this group': 'ამ ჯგუფის ამობეჭდვა/ჩამოტვირთვა',
      Recover: 'აღდგენა',
      'this group': 'ამ ჯგუფის',
      'Add team to this group': 'დაამატეთ გუნდი ამ ჯგუფში',
      'Add team': 'გუნდის დამატება',
      'Update places for entries': 'განაახლეთ ადგილები ჩანაწერებისთვის',
      'Update Places': 'განაახლეთ ადგილები',
      'Generate OOP For This Group Only': 'შექმენით OOP მხოლოდ ამ ჯგუფისთვის',
      'Create Matches for this group': 'შექმენით მატჩები ამ ჯგუფისთვის',
      'Create Matches': 'მატჩების შექმნა',
      POINTS: 'ქულები',
      SR: 'სრ',
      PR: 'პიარი',
      PLACE: 'ადგილი',
      Place: 'ადგილი',
      'Match Time Conflict': 'მატჩის დროის კონფლიქტი',
      'Winner is required when score is provided': 'ქულის მიწოდებისას საჭიროა გამარჯვებული',
      'Match cannot be unfinished when score is provided': 'მატჩი არ შეიძლება იყოს დაუმთავრებელი, როდესაც ქულებია მითითებული',
      'Space at the end is not allowed': 'ბოლოში ადგილი დაუშვებელია',
      'Please follow the pattern': 'გთხოვთ მიჰყევით შაბლონს',
      'Date is required Field': 'თარიღი სავალდებულოა ველი',
      'Time is required Field': 'დროა საჭირო ველი',
      'Add Score Successfully': 'დაამატე ქულა წარმატებით',
      'Select Winner': 'აირჩიეთ გამარჯვებული',
      Athlete: 'სპორტსმენი',
      'Additional match status options': 'მატჩის სტატუსის დამატებითი პარამეტრები',
      Deselect: 'არჩევის გაუქმება',
      Date: 'თარიღი',
      'Start Time': 'დაწყების დრო',
      'Match Status': 'მატჩის სტატუსი',
      'Delete match from oop': 'წაშალე მატჩი ოოპ-დან',
      'This Group is Deleted': 'ეს ჯგუფი წაშლილია',
      groupRecoverInfo: 'თუ ფიქრობთ, რომ ჯგუფი შეცდომით წაიშალა, შეგიძლიათ მისი აღდგენა აღდგენის ღილაკზე დაწკაპუნებით მხოლოდ 1 დღეში',
      'There is no available groups': 'არ არის ხელმისაწვდომი ჯგუფები',
      DATE: 'თარიღი',
      COURT: 'სასამართლო',
      TEAM1: 'გუნდი 1',
      TEAM2: 'გუნდი 2',
      RESULT: 'შედეგი',
      Court: 'სასამართლო',
      Score: 'ქულა',
      'Add Score': 'დაამატეთ ქულა',
      'Delete group Match from oop': 'წაშალეთ ჯგუფის Match ოოპიდან',
      'Edit Match': 'მატჩის რედაქტირება',
      'Match Updated Successfully': 'მატჩი წარმატებით განახლდა',
      'Edit Match Date & Time': 'შეცვალეთ მატჩის თარიღი და დრო',
      'Match Date Updated Successfully': 'მატჩის თარიღი წარმატებით განახლდა',
      'Date is Required Field': 'თარიღი სავალდებულო ველია',
      'Time is Required Field': 'დრო არის საჭირო ველი',
      'Order of Play': 'თამაშის ორდენი',
      'Please select at least one court': 'გთხოვთ, აირჩიოთ ერთი სასამართლო მაინც',
      'Generate OOP Successfully': 'OOP-ის გენერირება წარმატებით',
      'Generate order of play for': 'შექმენით თამაშის რიგი',
      'Number of teams': 'გუნდების რაოდენობა',
      'Number of matches': 'მატჩების რაოდენობა',
      'Add Rounds...': 'რაუნდების დამატება...',
      'Please select at least one Round.': 'გთხოვთ, აირჩიოთ მინიმუმ ერთი რაუნდი.',
      Add: 'დამატება',
      'Please select at least one.': 'გთხოვთ, აირჩიოთ მინიმუმ ერთი.',
      'Approximate match duration': 'მატჩის სავარაუდო ხანგრძლივობა',
      'in minutes': 'წუთებში',
      GENERATE: 'გენერირება',
      'Group Edited Successfully': 'ჯგუფი წარმატებით დაარედაქტირდა',
      'Edit Group': 'ჯგუფის რედაქტირება',
      'Group Name': 'ჯგუფის სახელი',
      'Display order of play under the group': 'აჩვენეთ თამაშის თანმიმდევრობა ჯგუფის ქვეშ',
      'Display list of matches under the group': 'ჯგუფის ფარგლებში მატჩების სიის ჩვენება',
      Comment: 'კომენტარი',
      'Group Name is Required Field': 'ჯგუფის სახელი სავალდებულოა ველი',
      'Entry is required': 'შესვლა აუცილებელია',
      'Adding Team...': 'გუნდის დამატება...',
      'Team Added Successfully': 'გუნდი დამატებულია წარმატებით',
      'Add team to group': 'გუნდის დამატება ჯგუფში',
      'OOP generated successfully': 'OOP წარმატებით გენერირებულია',
      'Matches created successfully': 'მატჩები წარმატებით შეიქმნა',
      'Generate OOP': 'შექმენით OOP',
      Duration: 'ხანგრძლივობა',
      'No courts available': 'სასამართლოები არ არის ხელმისაწვდომი',
      'Update Entry': 'განაახლეთ ჩანაწერი',
      'Update Entry successfully': 'ჩანაწერის განახლება წარმატებით',
      Cancel: 'გაუქმება',
      deleteGroupWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ ჯგუფის წაშლა?',
      deleteGroupEntryWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ ჩანაწერის წაშლა?',
      deleteGroupEntriesWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ ჩანაწერების წაშლა?',
      'Groups deleted successfully.': 'ჯგუფები წარმატებით წაიშალა.',
      'Edit Selected Groups': 'შერჩეული ჯგუფების რედაქტირება',
      'Delete Selected Groups': 'არჩეული ჯგუფების წაშლა',
      deleteGroupsWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული ჯგუფების წაშლა?',
      'Select Entries': 'აირჩიეთ ჩანაწერები',
      'PDF being generated...': 'PDF-ის გენერირება...',
      'Failed to generate PDF': 'PDF-ის გენერირება ვერ მოხერხდა',
      'Generating PDF...': 'PDF-ის გენერირება...',
      'Please wait...': 'გთხოვთ დაელოდოთ...',
      'All groups Published successfully': 'ყველა ჯგუფი წარმატებით გამოქვეყნდა',
      'All groups Unpublished successfully': 'ყველა ჯგუფი გამოქვეყნდა წარმატებით',
      'PUBLISH GROUPS': 'ჯგუფების გამოქვეყნება',
      'HIDE GROUPS': 'ჯგუფების დამალვა',
      'No segment found': 'სეგმენტი ვერ მოიძებნა',
      'All Segments': 'ყველა სეგმენტი',
      'All Categories': 'ყველა კატეგორია',
      'Generate OOP for all groups': 'შექმენით OOP ყველა ჯგუფისთვის',
      'Add Group': 'ჯგუფის დამატება',
      'Download PDF': 'ჩამოტვირთეთ PDF',
      'Export matches for DUPR': 'შესატყვისების ექსპორტი DUPR-ისთვის',
      'Publish All Groups': 'ყველა ჯგუფის გამოქვეყნება',
      'Unpublish All Groups': 'ყველა ჯგუფის გამოქვეყნების გაუქმება',
      'Please select at least one Court.': 'გთხოვთ, აირჩიოთ ერთი სასამართლო მაინც.',
      'Select Groups': 'აირჩიეთ ჯგუფები',
      'All Groups': 'ყველა ჯგუფი',
      'Match duration is Required Field': 'მატჩის ხანგრძლივობა სავალდებულო ველია',
      'Round is Required Field': 'რაუნდი სავალდებულო ველია',
      'Courts is Required Field': 'სასამართლოები სავალდებულო სფეროა',
      'Print/Download All group': 'ყველა ჯგუფის ამობეჭდვა/ჩამოტვირთვა',
      'Category is Required Field': 'კატეგორია სავალდებულო ველია',
      'Segment is a Required Field': 'სეგმენტი არის აუცილებელი ველი',
      'Group Type is Required Field': 'ჯგუფის ტიპი სავალდებულო ველია',
      'Groups Name is Required Field': 'ჯგუფის სახელი სავალდებულოა ველი',
      'Groups Naming is Required Field': 'ჯგუფების დასახელება სავალდებულოა ველი',
      'Please enter a 2-digit number.': 'გთხოვთ შეიყვანოთ 2-ნიშნა ნომერი.',
      'No of groups is Required Field': 'ჯგუფების რაოდენობა სავალდებულო ველია',
      'Algorithm is Required Field': 'ალგორითმი არის აუცილებელი ველი',
      'Generate Groups is Required Field': 'ჯგუფების გენერირება სავალდებულო ველია',
      'Add Group Successfully': 'ჯგუფის დამატება წარმატებით დასრულდა',
      'Add Groups': 'ჯგუფების დამატება',
      'Select segment': 'აირჩიეთ სეგმენტი',
      'Groups type': 'ჯგუფების ტიპი',
      'Number of groups': 'ჯგუფების რაოდენობა',
      'Groups naming': 'ჯგუფების დასახელება',
      'Select Round': 'აირჩიეთ მრგვალი',
      'Generate Groups': 'ჯგუფების გენერირება',
      'Select Algorithm': 'აირჩიეთ ალგორითმი',
      'Tournament copied successfully': 'ტურნირი წარმატებით დაკოპირდა',
      'Title is required': 'სათაური აუცილებელია',
      'Start date is required': 'დაწყების თარიღი აუცილებელია',
      'End date cannot be before start date': 'დასრულების თარიღი არ შეიძლება იყოს დაწყების თარიღამდე',
      'Entry deadline is required': 'შესვლის ვადა აუცილებელია',
      'Withdrawl deadline is required': 'გატანის ვადა აუცილებელია',
      'Dashboard: Partners': 'დაფა: პარტნიორები',
      'Add New Partners': 'ახალი პარტნიორების დამატება',
      'No Partners data!': 'პარტნიორების მონაცემები არ არის!',
      'Showing dates': 'თარიღების ჩვენება',
      Text: 'ტექსტი',
      Link: 'ბმული',
      'Dashboard: Documents Data': 'დაფა: დოკუმენტების მონაცემები',
      'League Regulations': 'ლიგის რეგლამენტი',
      'No regulations uploaded yet': 'ჯერ არ არის ატვირთული რეგულაციები',
      'Tournament Regulations': 'ტურნირის წესები',
      'Uploading...': 'მიმდინარეობს ატვირთვა...',
      'Regulations uploaded successfully': 'რეგულაციები წარმატებით აიტვირთა',
      'Error uploading regulations': 'შეცდომა რეგულაციების ატვირთვისას',
      'Dashboard: Participants Data': 'დაფა: მონაწილეთა მონაცემები',
      'Status: ': 'სტატუსი: ',
      'Show All': 'ყველას ჩვენება',
      'No status found': 'სტატუსი ვერ მოიძებნა',
      'Export User Emails': 'მომხმარებლის ელფოსტის ექსპორტი',
      'Export Users': 'მომხმარებლების ექსპორტი',
      'PLAYER NAME': 'მოთამაშის სახელი',
      COUNTRY: 'ქვეყანა',
      'There is no participants data!': 'მონაწილეთა მონაცემები არ არის!',
      'Dashboard: Final Standings': 'დაფა: საბოლოო პოზიციები',
      'Add Final Results Manually': 'დაამატეთ საბოლოო შედეგები ხელით',
      'There is no available tournament results': 'ტურნირის შედეგები არ არის ხელმისაწვდომი',
      'Final results added successfully': 'საბოლოო შედეგები წარმატებით დაემატა',
      Points: 'ქულები',
      'Select place': 'აირჩიეთ ადგილი',
      'Result deleted successfully': 'შედეგი წარმატებით წაიშალა',
      'Duplicate entries & assign points to another category': 'ჩანაწერების დუბლიკატი და ქულების მინიჭება სხვა კატეგორიას',
      'Duplicate & Assign': 'დუბლიკატი და მინიჭება',
      'Hide Results': 'შედეგების დამალვა',
      'Publish Results': 'შედეგების გამოქვეყნება',
      'Delete selected entries': 'არჩეული ჩანაწერების წაშლა',
      'Delete results block': 'შედეგების ბლოკის წაშლა',
      Entry: 'შესვლა',
      'Download List': 'ჩამოტვირთვის სია',
      'Add new result': 'დაამატეთ ახალი შედეგი',
      'Entry Deleted Successfully': 'ჩანაწერი წარმატებით წაიშალა',
      'Points is required': 'ქულები საჭიროა',
      'Place is required': 'ადგილი აუცილებელია',
      'Please select a entry': 'გთხოვთ, აირჩიოთ ჩანაწერი',
      'Updated Successfully': 'განახლებულია წარმატებით',
      'Edit Entry': 'ჩანაწერის რედაქტირება',
      'Delete Entry': 'ჩანაწერის წაშლა',
      deleteRankingEntryWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ ჩანაწერის წაშლა?',
      'Select Pair': 'აირჩიეთ დაწყვილება',
      'Select Player': 'აირჩიეთ მოთამაშე',
      'No ranking points found': 'სარეიტინგო ქულები ვერ მოიძებნა',
      'Select Ranking Point block': 'აირჩიეთ Ranking Point ბლოკი',
      'To Category': 'კატეგორიამდე',
      'From Category': 'კატეგორიიდან',
      'Duplicate entries & points to another category': 'ჩანაწერების და ქულების დუბლიკატი სხვა კატეგორიაში',
      'Entries & points copied successfully': 'ჩანაწერები და ქულები წარმატებით დაკოპირდა',
      'From Category is required': 'კატეგორიიდან აუცილებელია',
      'To Category is required': 'კატეგორიაში აუცილებელია',
      'Ranking Point is required': 'საჭიროა რეიტინგის ქულა',
      deleteResultsWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ შედეგების წაშლა?',
      'Dashboard: Live Standings Data': 'Dashboard: ცოცხალი შედეგების მონაცემები',
      'Segment: ': 'სეგმენტი: ',
      'No segments found': 'სეგმენტები ვერ მოიძებნა',
      'Add Live Standings': 'დაამატეთ ცოცხალი რეიტინგი',
      'Submit final Results': 'საბოლოო შედეგების გაგზავნა',
      'There is no available live standings': 'ცოცხალი პოზიციები არ არის ხელმისაწვდომი',
      'Groups is Required Field': 'ჯგუფები სავალდებულო ველია',
      'Standings Title is Required Field': 'სატურნირო დასახელება სავალდებულო ველია',
      'Sorting is Required Field': 'დახარისხება სავალდებულო ველია',
      'Live standings added successfully': 'ცოცხალი პოზიციები წარმატებით დაემატა',
      'Live Standing is Required Field': 'ცოცხალი დგომა სავალდებულო ველია',
      'Results added successfully': 'შედეგები წარმატებით დაემატა',
      'Submit Results to Rankings': 'შედეგების გაგზავნა რეიტინგში',
      'Select Live Standing': 'აირჩიეთ Live Standing',
      'No Live Standings found': 'ცოცხალი რეიტინგი ვერ მოიძებნა',
      'Deleting live standings...': 'პირდაპირი პოზიციების წაშლა...',
      'Live standings deleted successfully.': 'ცოცხალი პოზიციები წარმატებით წაიშალა.',
      'Delete Selected Live Standings': 'არჩეული ცოცხალი პოზიციების წაშლა',
      deleteLivesStandingsWarning: 'დარწმუნებული ხართ, რომ გსურთ წაშალოთ არჩეული პირდაპირი რეიტინგი?',
      'Generating CSV...': 'მიმდინარეობს CSV-ის გენერირება...',
      'CSV being generated...': 'CSV გენერირდება...',
      'Failed to generate CSV': 'CSV-ის გენერირება ვერ მოხერხდა',
      'Dashboard: Order Of Play': 'დაფა: თამაშის ორდერი',
      Grid: 'ბადე',
      List: 'სია',
      "Bird's View": 'ჩიტის ხედი',
      'Hide Order of Play': 'თამაშის ორდერის დამალვა',
      'Publish Order of Play': 'თამაშის ორდერის გამოქვეყნება',
      'Hide OOP': 'დამალე OOP',
      'Publish OOP': 'გამოაქვეყნეთ OOP',
      'Enter Name': 'შეიყვანეთ სახელი',
      'There is no available order of play': 'არ არის ხელმისაწვდომი თამაშის ბრძანება',
      'Export OOP for DUPR': 'OOP-ის ექსპორტი DUPR-ისთვის',
      'Deleting Matches...': 'მატჩების წაშლა...',
      'Matches Deleted Successfully!': 'მატჩები წარმატებით წაიშალა!',
      'Delete Selected Matches': 'არჩეული მატჩების წაშლა',
      deleteMatchesWarning: 'დარწმუნებული ხართ, რომ გსურთ არჩეული შესატყვისების წაშლა?',
      'Score is not set': 'ქულა არ არის დადგენილი',
      'TEAM 1': 'გუნდი 1',
      'TEAM 2': 'გუნდი 2',
      RESULTS: 'შედეგები',
      'Delete Match Successfully': 'Match წარმატებით წაშალეთ',
      'Court is required': 'სასამართლოა საჭირო',
      'Edit Score Successfully': 'ქულის რედაქტირება წარმატებით დასრულდა',
      'Edit Draw Match': 'ფრე მატჩის რედაქტირება',
      'Optional match details': 'არჩევითი მატჩის დეტალები',
      'Delete match': 'მატჩის წაშლა',
      'Confirm Match Deletion': 'დაადასტურეთ მატჩის წაშლა',
      deleteDrawMatchWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ მატჩის წაშლა? ',
      'Add score instructions': 'დაამატეთ ქულის ინსტრუქციები',
      scoreInstructionsInfo1: 'დააყენეთ ქულა ცარიელ მოედანზე ან მატჩის გამარჯვებულზე "მატჩი არ დასრულებულა" და მატჩის სტატუსი ავტომატურად შეიცვლება "მომავალი"',
      scoreInstructionsInfo2: '*თამაშები უნდა დაიყოს ":" სიმბოლოთი (მძიმე). ',
      scoreInstructionsInfo3: '**ტაიბრეიკი (თუ ითამაშა) უნდა იყოს ჩასმული ფრჩხილებში "()" და თამაშის შემდეგ წასვლა სივრცის გარეშე. ',
      scoreInstructionsInfo4: '***ტაიბრეიკის გადაწყვეტა (თუ ითამაშა) უნდა იყოს ჩართული კვადრატულ ფრჩხილებში "[]". ',
      'Edit Match Successfully': 'მატჩის რედაქტირება წარმატებით დასრულდა',
      'Edit Group Match': 'ჯგუფური მატჩის რედაქტირება',
      'Dashboard: Draw': 'დაფა: დახატე',
      'Draw is Required Field': 'გათამაშება სავალდებულო ველია',
      'Please add points to submit': 'გთხოვთ, დაამატოთ ქულები გაგზავნისთვის',
      'Select Draw': 'აირჩიეთ დახატვა',
      'No Draws found': 'ნახატები ვერ მოიძებნა',
      'All draws': 'ყველა გათამაშება',
      'Publish Draws': 'ნახატების გამოქვეყნება',
      'Hide Draws': 'ნახატების დამალვა',
      'Generate Order Of Play': 'თამაშის ორდერის გენერირება',
      'Add new Draw': 'დაამატეთ ახალი გათამაშება',
      'Draw templates is Required Field': 'შაბლონების დახატვა სავალდებულო ველია',
      'Draw title is Required Field': 'გათამაშების სათაური არის სავალდებულო ველი',
      'Generate draw is Required Field': 'გათამაშების გენერირება სავალდებულო ველია',
      'Live Standings is Required Field': 'ცოცხალი პოზიციები სავალდებულო ველია',
      'Start Live Standings is Required Field': 'Start Live Standings არის აუცილებელი ველი',
      'End Live Standings is Required Field': 'ცოცხალი პოზიციების დასრულება სავალდებულო ველია',
      'Draw type is Required Field': 'გათამაშების ტიპი არის აუცილებელი ველი',
      'Draw segment is Required Field': 'გათამაშების სეგმენტი არის აუცილებელი ველი',
      'Qual winners is Required Field': 'კვალიფიკაციის გამარჯვებულები სავალდებულო ველია',
      'No of seeds is Required Field': 'თესლის რაოდენობა სავალდებულო ველია',
      'Regenerate Draw Successfully': 'ნახატის რეგენერაცია წარმატებით',
      'Add Draw Successfully': 'დაამატე ნახატი წარმატებით',
      'Draw title': 'დახატე სათაური',
      'Draw segment': 'დახაზეთ სეგმენტი',
      'Templates size': 'შაბლონების ზომა',
      'Add Match for 3rd Place': 'დაამატეთ მატჩი მე-3 ადგილისთვის',
      'Qual Winners': 'ხარისხის გამარჯვებულები',
      'Generate Draw': 'გათამაშების გენერირება',
      'Main Draw': 'მთავარი გათამაშება',
      'No Main Draw found': 'მთავარი გათამაშება ვერ მოიძებნა',
      'Main Draw Entries': 'ძირითადი გათამაშების ჩანაწერები',
      'Select Live Standings': 'აირჩიეთ ცოცხალი რეიტინგი',
      'Loading...': 'იტვირთება...',
      'Live Standings from': 'ცოცხალი რეიტინგი დან',
      'Live Standings to': 'ცოცხალი პოზიციები',
      'Algo Type': 'ალგოს ტიპი',
      'Regenerate Draw': 'გათამაშების რეგენერაცია',
      'Add Draw': 'დაამატეთ Draw',
      deleteDrawWarning: 'ყველა გათამაშების ჩანაწერი და მატჩი წაიშლება და ახლად გენერირებული გათამაშება. ',
      Warning: 'გაფრთხილება',
      Confirm: 'დაადასტურეთ',
      'Generate order of play Successfully': 'თამაშის ბრძანების გენერირება წარმატებით',
      'Generate order of play': 'შექმენით თამაშის ბრძანება',
      'Select Draws': 'აირჩიეთ ნახატები',
      'Select Rounds': 'აირჩიეთ რაუნდები',
      'Time Settings': 'დროის პარამეტრები',
      Select: 'აირჩიეთ',
      'PDF Generated Successfully!': 'PDF გენერირებული წარმატებით!',
      'Publishing draw...': 'გათამაშების გამოქვეყნება...',
      'Hiding draw...': 'ნახატის დამალვა...',
      'Successfully!': 'წარმატებულად!',
      'Regenerate this draw': 'აღადგინეთ ეს გათამაშება',
      editDrawNameInfo: 'თუ გსურთ შეცვალოთ გათამაშების ზომა ან გათამაშების სხვა პარამეტრი, უნდა ამოშალოთ გათამაშება და კვლავ დაამატოთ ახალი.',
      'Draw Name': 'ხატვის სახელი',
      'Edit Draw': 'ნახატის რედაქტირება',
      'Draw Name Edited Successfully': 'დახაზვის სახელი წარმატებით შესწორდა',
      'Draw Name is required': 'ნახატის სახელი აუცილებელია',
      'Type is required': 'ტიპი აუცილებელია',
      'Publish this draw': 'გამოაქვეყნეთ ეს გათამაშება',
      'Hide this draw': 'დამალეთ ეს გათამაშება',
      'There is no available draws': 'არ არის ხელმისაწვდომი გათამაშებები',
      'Draws deleted successfully.': 'ნახატები წარმატებით წაიშალა.',
      'Delete Selected Draws': 'არჩეული ნახატების წაშლა',
      deleteDrawsWarning: 'დარწმუნებული ხართ, რომ გსურთ წაშალოთ არჩეული გათამაშებები?',
      deleteDrawEntryWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ ჩანაწერის წაშლა?',
      'Select a Entry': 'აირჩიეთ ჩანაწერი',
      'Edit team One': 'პირველი გუნდის რედაქტირება',
      'Edit team Two': 'მეორე გუნდის რედაქტირება',
      'Team Edited Successfully': 'გუნდი წარმატებით რედაქტირდა',
      'Deleting Entry...': 'ჩანაწერის წაშლა...',
      'Add team One to this Match': 'დაამატეთ გუნდი პირველი ამ მატჩს',
      'Add team Two to this Match': 'დაამატეთ გუნდი მეორე ამ მატჩს',
      'Team Added to Seed Successfully': 'გუნდი წარმატებით დაემატა თესლს',
      'Score is Required Field': 'ქულა სავალდებულო ველია',
      'Match Score Added Successfully': 'მატჩის ქულა წარმატებით დამატებულია',
      'Match score': 'მატჩის ქულა',
      'Edit score': 'ქულის რედაქტირება',
      'All Draws are hidden': 'ყველა გათამაშება დამალულია',
      Upload: 'ატვირთვა',
      'Upload File': 'ფაილის ატვირთვა',
      'Upload File in OOP': 'ატვირთეთ ფაილი OOP-ში',
      'Upload Document': 'ატვირთეთ დოკუმენტი',
      'Document Name': 'დოკუმენტის სახელი',
      'Name is required': 'სახელი აუცილებელია',
      'Are you sure you want to delete this document?': 'დარწმუნებული ხართ, რომ გსურთ ამ დოკუმენტის წაშლა?',
      deleteGroupMatchWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ მატჩის წაშლა?',
      deleteMultipleResultsWarning: 'დარწმუნებული ხართ, რომ გსურთ ამ შედეგების წაშლა?',
      'How to Set Up a Tournament Draw?': 'როგორ მოვაწყოთ ტურნირის გათამაშება?',
      setupTournament1: 'მას შემდეგ რაც მიუთითებთ გათამაშების კატეგორიას და სათაურს, მიჰყევით ამ ნაბიჯებს ტურნირის გათამაშების დასაყენებლად:',
      'Select the Segment:': 'აირჩიეთ სეგმენტი:',
      'Choose from': 'აირჩიეთ',
      'Select the Draw Type:': 'აირჩიეთ გათამაშების ტიპი:',
      'Single Elimination:': 'ერთჯერადი ელიმინაცია:',
      'Participants are eliminated after their first loss': 'მონაწილეები გამოირიცხებიან პირველი წაგების შემდეგ',
      'Double Elimination:': 'ორმაგი ელიმინაცია:',
      'Participants are eliminated after two losses': 'მონაწილეები ორი წაგების შემდეგ იშლება',
      'Generate the Draw:': 'გათამაშების გენერირება:',
      'Choose the source for generating the draw:': 'აირჩიეთ წყარო გათამაშების გენერირებისთვის:',
      'Use the list of registered participants': 'გამოიყენეთ რეგისტრირებული მონაწილეების სია',
      'Use standings based on group results': 'გამოიყენეთ პოზიციები ჯგუფის შედეგების მიხედვით',
      setupTournament2: 'შექმენით ცარიელი გათამაშება და ხელით დაამატეთ მონაწილეები პოზიციებზე.',
      setupTournament3: 'არჩეულია, გექნებათ დამატებითი ვარიანტი, რომ გენერირება გათამაშება',
      setupTournament4: 'ამ შემთხვევაში, თქვენ უნდა აირჩიოთ გათამაშება, საიდანაც გენერირება მოხდება.',
      setupTournament5: 'სისტემა ავტომატურად შეარჩევს მონაწილეებს, რომლებმაც წააგეს პირველი მატჩი, მსაჯების ოფციით, საჭიროებისამებრ შეცვალონ არჩევანი.',
      setupTournament6: 'დააყენეთ გენერაციის ალგორითმი (თუ არჩეული წყარო არ არის ხელით):',
      setupTournament7: 'მიჰყვება საერთაშორისო ფედერაციის წესებს, მოიცავს დათესვას და რანდომიზაციას.',
      setupTournament8: 'მაგალითად, 16 მონაწილის გათამაშებაში პირველი განთესილი მე-16 განთესილს თამაშობს, მეორე განთესილი მე-15 განთესილს და ა.შ.',
      'How to Generate the Order of Play?': 'როგორ შევქმნათ თამაშის ორდერი?',
      'To generate the Order of Play automatically': 'თამაშის ორდერის ავტომატურად გენერირება',
      Click: 'დააწკაპუნეთ',
      'in the draw navigation.': 'გათამაშების ნავიგაციაში.',
      'Select the specific': 'აირჩიეთ კონკრეტული',
      'on which the matches will be set': 'რომელზეც მატჩები დაინიშნება',
      'Specify the approximate duration of each match': 'მიუთითეთ თითოეული მატჩის სავარაუდო ხანგრძლივობა',
      setupTournament9: 'მატჩების გენერირების შემდეგ, შეგიძლიათ დეტალების მორგება სამ სხვადასხვა ხედში',
      'Sorted by match time': 'დალაგებულია მატჩის დროის მიხედვით',
      setupTournament10: 'კორტები, როგორც სვეტების სახელები მატჩებით, რომლებიც ჩამოთვლილია ასოცირებულ კორტში',
      setupTournament11: 'აჩვენებს მხოლოდ კატეგორიების სახელებს და ფერებს, რათა უზრუნველყოს თამაშის რიგის მიმოხილვა',
      setupTournament12: 'თუ გსურთ მატჩების დეტალების ხელით დამატება, დააწკაპუნეთ',
      setupTournament13: 'მატჩის ქვეშ და დააყენეთ ყველა საჭირო დეტალი',
      'How to Enter Results?': 'როგორ შევიტანოთ შედეგები?',
      'To enter match results': 'მატჩის შედეგების შესაყვანად',
      setupOOP1: 'აირჩიეთ მატჩი, რომლის განახლებაც გსურთ (გათამაშების ან თამაშის რიგის შიგნით).',
      'Set the winner and the score': 'დაადგინეთ გამარჯვებული და ქულა',
      'Ensure you format the score correctly': 'დარწმუნდით, რომ სწორად ჩამოაყალიბეთ ქულა',
      "Always start with the winner's score": 'ყოველთვის დაიწყეთ გამარჯვებულის ანგარიშით',
      'Separate sets with a space': 'ცალკე კომპლექტი სივრცეში',
      'Separate games within a set with a colon': 'ცალკე თამაშები ნაკრების ფარგლებში ორწერტილით',
      'Indicate a set tiebreak in parentheses without a space after the set': 'ფრჩხილებში მიუთითეთ ნაკრების ტაიბრეიკი სეტის შემდეგ სივრცის გარეშე',
      'Indicate a super tiebreak set in square brackets with a space before it': 'მიუთითეთ სუპერ ტაიბრეიკის ნაკრები კვადრატულ ფრჩხილებში მის წინ სივრცეში',
      Example: 'მაგალითი',
      'Draws are not available to the public until you click': 'გათამაშებები მიუწვდომელია საზოგადოებისთვის, სანამ არ დააწკაპუნებთ',
      setupDraw1: 'თქვენ შეგიძლიათ გამოაქვეყნოთ ყველა გათამაშება ერთდროულად ან ინდივიდუალურად. ',
      'Delete Draws': 'ნახატების წაშლა',
      'To delete a draw': 'გათამაშების წასაშლელად',
      'Check the checkbox next to the draw title': 'მონიშნეთ ყუთი გათამაშების სათაურის გვერდით',
      'Automatically Submit Final Results (Place / Points)': 'საბოლოო შედეგების ავტომატურად გაგზავნა (ადგილი / ქულები)',
      'To submit final results automatically, follow these steps': 'საბოლოო შედეგების ავტომატურად გასაგზავნად, მიჰყევით ამ ნაბიჯებს',
      setupDraw2: '"ნახაზების" განყოფილებაში იპოვნეთ და დააჭირეთ ღილაკს "საბოლოო შედეგების გაგზავნა" ზედა მარჯვენა კუთხეში.',
      'Choose the draw from which you want to get the positions': 'აირჩიეთ გათამაშება, საიდანაც გსურთ მიიღოთ პოზიციები',
      setupDraw3: 'შეამოწმეთ პოზიციები, რათა დარწმუნდეთ, რომ ყველაფერი სწორია. ',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'ალტერნატიულად - საბოლოო შედეგების ხელით გაგზავნა "საბოლოო პოზიციების" გვერდზე',
      drawExtra1: 'მონაწილეთა კლუბის სახელის საჩვენებლად და კლუბის წევრების გათამაშებაში გასანაწილებლად, აირჩიეთ შესაბამისი ველი',
      'You can download and print the draw': 'შეგიძლიათ ჩამოტვირთოთ და დაბეჭდოთ გათამაშება',
      'For pickleball, you can export matches in the correct format for DUPR': 'პიკლბოლისთვის შეგიძლიათ მატჩების ექსპორტი სწორი ფორმატით DUPR-ისთვის',
      wildCardQuestion: "როგორ ზუსტად დავთვალოთ შესვლების რაოდენობა ძირითად თამაშში, კვალიფიკაციაში, დათესვისა და ველური კარტების (WC's)?",
      wildCardAnswer:
        'ძირითადი გათამაშების, კვალიფიკაციის, თესლებისა და ველური კარტების (WC) შესვლის ზუსტი რაოდენობის დასათვლელად, გთხოვთ, დარწმუნდეთ, რომ სწორად დააყენეთ ტურნირის კატეგორიის პარამეტრები. ',
      wildCardAnswer1: 'პარამეტრები > კატეგორიის პარამეტრები.',
      wildCardAnswer2: 'იმავე ადგილას, შეგიძლიათ დაარეგულიროთ მიღებული გადახდის მეთოდები, შესვლის საფასური, რეგისტრაციის ლიმიტები და სხვა.',
      'How to auto confirm entries?': 'როგორ ხდება ჩანაწერების ავტომატური დადასტურება?',
      confirmEntries1: 'რეგისტრაციის ან გადახდის შემდეგ ჩანაწერების ავტომატურად დასადასტურებლად გადადით',
      confirmEntries2: 'ტურნირის პარამეტრები > ტურნირის რეგისტრაცია',
      'and check the box for': 'და შეამოწმეთ ყუთი',
      'Automatically Confirm Entries': 'ჩანაწერების ავტომატურად დადასტურება',
      'Add manual tournament results': 'დაამატეთ მანუალური ტურნირის შედეგები',
      results1: 'ტურნირის შედეგების ხელით დასამატებლად (ფინალური პოზიციები), დააწკაპუნეთ',
      'Add Final Standings Manually': 'დაამატეთ საბოლოო პოზიციები ხელით',
      results2: 'ღილაკი. ',
      results3: 'იმისათვის, რომ ადგილები და ქულები სწორად იყოს ნაჩვენები, დარწმუნდით, რომ ისინი მითითებულია',
      results4: 'ტურნირის დაფა -> ლიგა -> რეიტინგის ქულების განყოფილება',
      oop1: 'იმისათვის, რომ თამაშის რიგითობა (OOP) საზოგადოებისთვის ხილული გახდეს, თქვენ უნდა გამოაქვეყნოთ ის თამაშის რიგის ნავიგაციაში.',
      'Edit / Delete Multiple Matches': 'მრავალი მატჩის რედაქტირება/წაშლა',
      oop2: 'აირჩიეთ რამდენიმე შესატყვისი, რომ შეცვალოთ ან წაშალოთ ისინი ერთდროულად.',
      'You can upload documents related to OOP': 'შეგიძლიათ ატვირთოთ დოკუმენტები OOP-თან დაკავშირებული',
      'You can download and print matches': 'შეგიძლიათ ჩამოტვირთოთ და დაბეჭდოთ მატჩები',
      oopExtra: 'პიკლბოლისთვის შეგიძლიათ მატჩების ექსპორტი სწორი ფორმატით DUPR-ისთვის.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": 'როგორ განაახლოთ მონაწილეთა რეიტინგი, დათესვა და სეგმენტაცია?',
      'To update rankings, segmentation, or seeding': 'რეიტინგების, სეგმენტაციის ან დათესვის განახლებისთვის',
      'Click the': 'დააწკაპუნეთ',
      'button (represented by a three-dot icon) located next to the': 'ღილაკი (გამოსახულია სამ წერტილიანი ხატით), რომელიც მდებარეობს გვერდით',
      button: 'ღილაკი',
      'from the dropdown menu': 'ჩამოსაშლელი მენიუდან',
      'Segmentation Explained': 'ახსნილია სეგმენტაცია',
      "To ensure participants' rankings are indicated properly": 'მონაწილეთა რეიტინგების სწორად მითითების უზრუნველსაყოფად',
      entries1: 'შეამოწმეთ, რომ ლიგის მენეჯერმა სწორად დაადგინა ტურნირის კატეგორიები',
      'This can be found under': 'ეს შეგიძლიათ იხილოთ ქვეშ',
      'League -> Tournament Categories.': 'ლიგა -> ტურნირის კატეგორიები.',
      entries2: 'დარწმუნდით, რომ ტურნირის კატეგორიები დაკავშირებულია რეიტინგის სწორ კატეგორიასთან.',
      entries3: 'თუ ფიქრობთ, რომ რეიტინგები სათანადოდ არ განახლდება, დარწმუნდით, რომ რეიტინგის კატეგორიები დაყენებულია რანჟირების წესების მიხედვით Tournated Dashboard-ში. ',
      Note: 'შენიშვნა',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': 'როგორ განაახლოთ ან ამოიღოთ ჩანაწერები და მიანიჭოთ Wild Cards (WCs)?',
      'To Add a New Entry': 'ახალი ჩანაწერის დასამატებლად',
      "By default, you need to provide the player's email.": 'ნაგულისხმევად, თქვენ უნდა მიაწოდოთ მოთამაშის ელ.წერილი.',
      entries4: 'თუ თქვენს ორგანიზაციას ჩართული აქვს ლიცენზიები, შეგიძლიათ აირჩიოთ სპორტსმენებიდან, რომლებსაც აქვთ აქტიური ლიცენზია.',
      'To Withdraw an Entry': 'შესვლის გასაუქმებლად',
      'Select the entries you want to withdraw.': 'აირჩიეთ ჩანაწერები, რომელთა ამოღება გსურთ.',
      'and then choose': 'და შემდეგ აირჩიე',
      'To Assign a Wild Card (WC)': 'Wild Card-ის (WC) მინიჭება',
      'Next to the entry, click the three-dot icon and select': 'ჩანაწერის გვერდით დააწკაპუნეთ სამი წერტილის ხატულაზე და აირჩიეთ',
      entries5: 'დარწმუნდით, რომ WC-ების რაოდენობა მითითებულია კატეგორიის პარამეტრებში; ',
      'Where can I find participant details?': 'სად შემიძლია ვნახო მონაწილის დეტალები?',
      'You can find participant details in two ways': 'თქვენ შეგიძლიათ იპოვოთ მონაწილეთა დეტალები ორი გზით',
      'From the Entry List': 'შესვლის სიიდან',
      entries6: 'დააწკაპუნეთ მონაწილის სახელზე პირდაპირ შესვლის სიაში მათი დეტალების სანახავად.',
      'From the Tournament Data Section': 'ტურნირის მონაცემების განყოფილებიდან',
      entries7: 'გადადით ტურნირის მონაცემები -> მონაწილეები მარცხენა მხარეს, რათა ნახოთ დეტალები ყველა მონაწილისთვის.',
      entries8: 'გარდა ამისა, თქვენ გაქვთ შესაძლებლობა ექსპორტის ყველა მონაწილის დეტალი ან მათი ელფოსტა ცალკე მონაწილეთა განყოფილებიდან.',
      'How to Add Groups?': 'როგორ დავამატო ჯგუფები?',
      'To add groups, follow these steps': 'ჯგუფების დასამატებლად მიჰყევით ამ ნაბიჯებს',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'გადადით "ჯგუფების" განყოფილებაში და დააჭირეთ ღილაკს "ჯგუფების დამატება".',
      'Select a Category': 'აირჩიეთ კატეგორია',
      'Choose the category for which you want to add groups.': 'აირჩიეთ კატეგორია, რომლისთვისაც გსურთ ჯგუფების დამატება.',
      'Set Number of Groups and Naming Type': 'დააყენეთ ჯგუფების რაოდენობა და დასახელების ტიპი',
      'Specify the number of groups you want to create.': 'მიუთითეთ ჯგუფების რაოდენობა, რომელთა შექმნაც გსურთ.',
      'Choose the naming convention for the groups': 'აირჩიეთ ჯგუფების სახელების კონვენცია',
      'Select the Group Type': 'აირჩიეთ ჯგუფის ტიპი',
      'Used when athletes or teams are competing against each other': 'გამოიყენება, როდესაც სპორტსმენები ან გუნდები ერთმანეთს ეჯიბრებიან',
      'Used when single athletes are playing doubles with each other.': 'გამოიყენება, როდესაც მარტოხელა სპორტსმენები თამაშობენ დუბლებს ერთმანეთთან.',
      'Standard Format Options': 'სტანდარტული ფორმატის პარამეტრები',
      groups1: 'თუ აირჩევთ სტანდარტულ ფორმატს, გადაწყვიტეთ ჯგუფის წევრების ავტომატურად გენერირება თუ ხელით',
      'Select an algorithm': 'აირჩიეთ ალგორითმი',
      'Based on international rules.': 'საერთაშორისო წესებზე დაყრდნობით.',
      groups2: 'მაგალითად, თუ არის 3 ჯგუფი და 9 ჩანაწერი, 1 ჯგუფში მოხვდება 1-ლი, მე-6 და მე-7 რეიტინგული მოთამაშეები; ',
      groups3:
        'დაამატეთ გუნდები თითოეულ ჯგუფს და დასრულების შემდეგ, დარწმუნდით, რომ დააწკაპუნეთ ღილაკზე „შექმენით მატჩები“, რომელიც მდებარეობს თითოეული ჯგუფის სახელის გვერდით (ეს ნაბიჯი ავტომატიზირებული იქნება მომავალ გამოშვებაში).',
      'King Format Options': 'King ფორმატის პარამეტრები',
      'If you select the King format, choose the': 'თუ აირჩევთ King ფორმატს, აირჩიეთ',
      'Display List of Matches': 'მატჩების სიის ჩვენება',
      groups4: 'თითოეულ ჯგუფში შესატყვისების სიის საჩვენებლად, აირჩიეთ მონიშნული ველი',
      'To generate the Order of Play (OOP)': 'თამაშის ორდერის გენერირება (OOP)',
      'Choose the Scope': 'აირჩიეთ სფერო',
      groups5:
        'გადაწყვიტეთ, გსურთ თუ არა OOP-ის გენერირება ერთდროულად ყველა ჯგუფისთვის (მდებარეობს „ჯგუფების“ ნავიგაციაში) თუ თითოეული ჯგუფისთვის ცალ-ცალკე (იპოვება თითოეული ჯგუფის სახელის გვერდით).',
      'Generate the OOP': 'შექმენით OOP',
      'Click the appropriate button.': 'დააჭირეთ შესაბამის ღილაკს.',
      'Select the rounds for which you want to generate the OOP.': 'აირჩიეთ რაუნდები, რომლებისთვისაც გსურთ OOP-ის გენერირება.',
      'Set the date and time for the first match.': 'დააყენეთ პირველი მატჩის თარიღი და დრო.',
      'Enter the estimated duration for the matches.': 'შეიყვანეთ მატჩების სავარაუდო ხანგრძლივობა.',
      'Adjust Matches': 'მატჩების მორგება',
      'You can also create matches manually and make adjustments after the OOP is generated.': 'თქვენ ასევე შეგიძლიათ შექმნათ შესატყვისები ხელით და შეცვალოთ OOP-ის გენერირების შემდეგ.',
      'Select the Match': 'აირჩიეთ მატჩი',
      groups6: 'აირჩიეთ მატჩი, რომლის განახლებაც გსურთ, ჯგუფში ან თამაშის რიგიდან.',
      'Enter the Winner and Score': 'შეიყვანეთ გამარჯვებული და ქულა',
      'Set the winner of the match.': 'დაადგინეთ მატჩის გამარჯვებული.',
      'Format the score as follows': 'ქულების ფორმატირება შემდეგნაირად',
      "Start with the winner's score.": 'დაიწყეთ გამარჯვებულის ანგარიშით.',
      'Separate sets with a space.': 'ცალკე კომპლექტი სივრცეში.',
      'Use parentheses for a set tiebreak (no space after the set)': 'გამოიყენეთ ფრჩხილები სეტის ტაიბრეიკისთვის (სეტის შემდეგ სივრცე არ არის)',
      'Use square brackets for a super tiebreak (with a space before it)': 'გამოიყენეთ კვადრატული ფრჩხილები სუპერ ტაიბრეიკისთვის (მანამდე სივრცე)',
      'How to Generate the Final Round of King Format?': 'როგორ შევქმნათ King-ის ფორმატის საბოლოო რაუნდი?',
      'To generate the final round in King format': 'ფინალური რაუნდის გენერირება King ფორმატში',
      'Create New Groups': 'შექმენით ახალი ჯგუფები',
      'Set up new groups and select the "King" format.': 'შექმენით ახალი ჯგუფები და აირჩიეთ "მეფე" ფორმატი.',
      'Select the Round': 'აირჩიეთ რაუნდი',
      'Choose "Final" as the round type.': 'აირჩიეთ "ფინალი", როგორც მრგვალი ტიპი.',
      'Automatic Sorting': 'ავტომატური დახარისხება',
      groups7: 'პლატფორმა ავტომატურად გამოიყენებს ჯგუფურ განლაგებას და კოეფიციენტებს ფინალური რაუნდისთვის მონაწილეთა დასალაგებლად.',
      'You can download and print groups.': 'შეგიძლიათ ჩამოტვირთოთ და დაბეჭდოთ ჯგუფები.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'პიკლბოლისთვის შეგიძლიათ მატჩების ექსპორტი სწორი ფორმატით DUPR-ისთვის.',
    },
  },
  esp: {
    translation: {
      'Preview Tournament': 'Torneo previo',
      'Control Panel': 'Panel de control',
      Instructions: 'Instrucciones',
      'All Tournaments': 'Todos los torneos',
      Settings: 'Ajustes',
      'General Data': 'Datos Generales',
      'Category Settings': 'Configuración de categoría',
      Entries: 'Entradas',
      Requests: 'Solicitudes',
      Withdrawals: 'Retiros',
      Matches: 'Partidos',
      Pairings: 'Maridajes',
      Standings: 'Clasificación',
      'Custom Metrics': 'Métricas personalizadas',
      'List & Results': 'Lista y resultados',
      Groups: 'Grupos',
      Draws: 'Sorteos',
      'Order Of Play': 'Orden de juego',
      'Live Standings': 'Posiciones en vivo',
      'Final Standings': 'Clasificación final',
      'Tournament Data': 'Datos del torneo',
      Participants: 'Participantes',
      Documents: 'Documentos',
      Partners: 'Fogonadura',
      'No tournament selected': 'Ningún torneo seleccionado',
      'Copy Tournament': 'Copiar torneo',
      'Make a copy of selected tournament': 'Hacer una copia del torneo seleccionado',
      Completed: 'Terminado',
      Upcoming: 'Próximo',
      Active: 'Activo',
      Total: 'Total',
      'Tournaments list': 'Lista de torneos',
      'Tournament Title': 'Título del torneo',
      Search: 'Buscar',
      'Create new Tournament': 'Crear nuevo torneo',
      Delete: 'Borrar',
      Organizer: 'Organizador',
      Location: 'Ubicación',
      'List of categories': 'Lista de categorías',
      'Start - End': 'Inicio - Fin',
      Dashboard: 'Panel',
      'Athlete Portal': 'Portal del deportista',
      Profile: 'Perfil',
      Logout: 'Cerrar sesión',
      Create: 'Crear',
      'Add Tournament': 'Agregar torneo',
      'Add League': 'Agregar liga',
      'Add Club': 'Agregar club',
      'Add News': 'Agregar noticias',
      'Add Team': 'Agregar equipo',
      'Edit Tournament': 'Editar torneo',
      'Create Tournament': 'Crear torneo',
      editAddDesc: 'Configure ajustes generales, apariencia, hoja informativa, ajustes adicionales, administración y más.',
      'Configure Categories': 'Configurar categorías',
      configureCategoriesDesc: 'Especifique la configuración de la categoría personal: número máximo de participantes, clasificados, WC, semillas, métodos de pago y más.',
      'Manage Entries': 'Administrar entradas',
      manageEntriesDesc: 'Confirmar/rechazar entradas registradas, importar entradas desde una plantilla CSV, asignar clasificaciones y siembra, actualizar/copiar/eliminar entradas y más',
      'Go to Requests': 'Ir a Solicitudes',
      'Go to Entries': 'Ir a Entradas',
      'Create Groups, Draws, and Live Standings': 'Crea grupos, sorteos y clasificaciones en vivo',
      groupsDrawsLiveStandingsDesc: 'Agrega y genera grupos, sorteos y clasificaciones en vivo con un par de clics. ',
      'Go to Groups': 'Ir a grupos',
      'Go to Draws': 'Ir a Sorteos',
      'Go to Live Standings': 'Ir a la clasificación en vivo',
      'Go to Order of Play': 'Ir al orden de juego',
      'Generate Order of Play': 'Generar orden de juego',
      generateOrderOfPlayDesc: 'Después de crear grupos/sorteos, el último paso antes de publicar los partidos es la generación de los partidos (orden de juego). ',
      'Publish data': 'Publicar datos',
      publishDataDesc: 'Para que los datos estén disponibles para el público, debe asegurarse de que se publiquen los grupos, los sorteos, las clasificaciones en vivo y el orden de juego. ',
      'Submit Tournament Results': 'Enviar resultados del torneo',
      submitTournamentResultsDesc:
        'El último paso para concluir el torneo es enviar los resultados del torneo y asignar puntos según la tabla de puntos (si la función de clasificación está habilitada). ',
      'Add Results Manually': 'Agregar resultados manualmente',
      instructionsPageTitle: 'Panel de control: instrucciones',
      controlPanelDesc: 'Ejecuta el torneo paso a paso desde este panel de control',
      General: 'General',
      'Dates & Location': 'Fechas y ubicación',
      Appearance: 'Apariencia',
      'Tournament Registration': 'Registro de torneo',
      Extras: 'Extras',
      Management: 'Gestión',
      'Fact Sheet': 'Hoja informativa',
      'Select League': 'Seleccionar Liga',
      'Select Categories': 'Seleccionar categorías',
      'Payment methods': 'Métodos de pago',
      'Payment type': 'Tipo de pago',
      Currency: 'Divisa',
      'Online Entry Fee': 'Tarifa de inscripción en línea',
      'Entry Fee': 'Cuota de entrada',
      'Onsite Entry Fee': 'Tarifa de entrada en el sitio',
      'Number of Rounds': 'Número de rondas',
      'Number of Courts': 'Número de tribunales',
      'Number of Stages': 'Número de etapas',
      'Tournament timezone': 'Zona horaria del torneo',
      'Registration Start Date': 'Fecha de inicio de registro',
      'Start Date': 'Fecha de inicio',
      'End Date': 'Fecha de finalización',
      'Entry Deadline': 'Fecha límite de inscripción',
      'Withdrawal Deadline': 'Fecha límite de retiro',
      'Select Club': 'Seleccionar Club',
      'No clubs found in selected league': 'No se encontraron clubes en la liga seleccionada.',
      'Select Country': 'Seleccionar país',
      City: 'Ciudad',
      Address: 'DIRECCIÓN',
      Logo: 'Logo',
      logoDesc: 'Recomendamos una imagen de 256 x 256 píxeles.',
      'Tournament title': 'Título del torneo',
      'Enter tournament title': 'Introduce el título del torneo',
      Description: 'Descripción',
      tournamentDesc: 'Escribe una descripción sobre el torneo.',
      'Video URL': 'URL del vídeo',
      videoLink: 'Inserte un enlace de vídeo aquí',
      tourWebUrl: 'URL del sitio web del torneo',
      'Tournament Poster': 'Póster Torneo',
      'Upload Poster': 'Subir cartel',
      'Cover Banner': 'Banner de portada',
      bannerDesc: 'Recomendamos una imagen de 2400 x 600 píxeles.',
      'Upload Banner': 'Subir banner',
      Regulations: 'Reglamentos',
      'Upload Regulations': 'Subir Reglamento',
      'No Image': 'Sin imagen',
      dragDropDesc: 'Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo',
      dragDropDesc2: 'Arrastre y suelte archivos aquí, o haga clic para seleccionar archivos',
      'Select File': 'Seleccionar archivo',
      'Additional Questions': 'Preguntas adicionales',
      'Add New Question': 'Agregar nueva pregunta',
      'Terms and Conditions': 'Términos y condiciones',
      'Start Typing': 'Empezar a escribir',
      'External tournament registration link': 'Enlace de registro de torneo externo',
      'Automatically confirm entries': 'Confirmar entradas automáticamente',
      confirmEntriesInfo: 'Las inscripciones de torneos irán directamente a las inscripciones, no a las solicitudes.',
      closeRegistration: 'Cerrar inscripción para todas las categorías del torneo',
      'Edit this question': 'Editar esta pregunta',
      'Add new question': 'Agregar nueva pregunta',
      'Question updated successfully!': '¡Pregunta actualizada exitosamente!',
      'Question added successfully!': '¡Pregunta agregada exitosamente!',
      Question: 'Pregunta',
      'Field Type': 'Tipo de campo',
      Price: 'Precio',
      'Field Option': 'Opción de campo',
      removeQuestionError: 'Se requiere al menos 1 opción de campo para seleccionar el tipo de campo',
      Remove: 'Eliminar',
      'Add new option': 'Agregar nueva opción',
      questionInfo: 'Haz esta pregunta a cada deportista.',
      Submit: 'Entregar',
      isFeatured: 'Haz que este torneo sea destacado',
      partnerSearch: 'Habilitar búsqueda de socios',
      enablePracticeCourtsBooking: 'Habilitar reserva de canchas de práctica',
      showTournamentChessManagerCalendar: 'Mostrar este torneo en el calendario del manager de ajedrez',
      localRatedTournament: 'Torneo clasificado local',
      fideRatedTournament: 'Torneo clasificado por la FIDE',
      enableTransferBooking: 'Habilitar reserva de traslado',
      enableLiveStream: 'Habilitar transmisión en vivo (próximamente)',
      enableLiveScoring: 'Habilitar puntuación en vivo (próximamente)',
      'Select Organizer': 'Seleccionar Organizador',
      'No Organizer available': 'No hay organizador disponible',
      'Select main referee': 'Seleccionar árbitro principal',
      'Select referee': 'Seleccionar árbitro',
      'No referee available': 'No hay árbitro disponible',
      'Select additional referees': 'Seleccionar árbitros adicionales',
      'Tournament status': 'Estado del torneo',
      'Make tournament private': 'Hacer el torneo privado',
      'Set Password': 'Establecer contraseña',
      'New Password': 'Nueva contraseña',
      'Confirm Password': 'confirmar Contraseña',
      'No facts found': 'No se encontraron hechos',
      Save: 'Ahorrar',
      Back: 'Atrás',
      'Field Title': 'Título del campo',
      'Dashboard: Stage Settings': 'Panel de control: Configuración del escenario',
      'Edit tournament': 'Editar torneo',
      'Change Settings': 'Cambiar configuración',
      Title: 'Título',
      Status: 'Estado',
      'General Limit': 'Límite general',
      'Individual metric limit': 'Límite de métrica individual',
      'Allow participants to add results': 'Permitir a los participantes agregar resultados',
      'Attachments status': 'Estado de los archivos adjuntos',
      'There is no available': 'No hay disponible',
      'Public for everyone': 'Público para todos',
      'Public only for participants': 'Público sólo para participantes',
      Hidden: 'Oculto',
      'Hide other participant results': 'Ocultar resultados de otros participantes',
      'Individual metric limit applied': 'Límite de métrica individual aplicado',
      'Attachments Status': 'Estado de los archivos adjuntos',
      'Public to participants only': 'Público solo para participantes',
      Saving: 'Ahorro',
      'General data': 'Datos generales',
      'Category settings': 'Configuración de categoría',
      'Dashboard: Category Settings': 'Panel de control: configuración de categorías',
      'There is no available category!': '¡No hay ninguna categoría disponible!',
      Category: 'Categoría',
      'Online Fee': 'Tarifa en línea',
      'Onsite Fee': 'Tarifa en el sitio',
      'VAT Amount': 'Importe del IVA',
      'Payment Type': 'Tipo de pago',
      'Payment Method': 'Método de pago',
      Rounds: 'Rondas',
      Time: 'Tiempo',
      'Time per move': 'Tiempo por movimiento',
      'Time after move': 'Tiempo después de la mudanza',
      'Extra time': 'tiempo extra',
      Pairing: 'Emparejamiento',
      entries: 'entradas',
      'Registration Status': 'Estado de registro',
      'Additional service fee': 'Tarifa de servicio adicional',
      'Registration Limit': 'Límite de registro',
      Edit: 'Editar',
      'Registrations Limit': 'Límite de inscripciones',
      'Include VAT amount?': '¿Incluye el importe del IVA?',
      displayClubInfo: 'Mostrar el nombre del atleta/club del equipo en los detalles de la entrada',
      Open: 'Abierto',
      Closed: 'Cerrado',
      categorySettingsInfo: 'Establezca solo los datos que desea actualizar para las categorías seleccionadas',
      'Select category color': 'Seleccionar color de categoría',
      'Dashboard: Requests': 'Panel de control: Solicitudes',
      'No category found': 'No se encontró ninguna categoría',
      'Add New Request': 'Agregar nueva solicitud',
      Actions: 'Comportamiento',
      Confirming: 'Confirmando',
      'Confirm Selected Requests': 'Confirmar solicitudes seleccionadas',
      NR: 'NR',
      Team: 'Equipo',
      Player: 'Jugador',
      Rating: 'Clasificación',
      Email: 'Correo electrónico',
      'Phone Number': 'Número de teléfono',
      'Reg. time': 'Reg. ',
      Paid: 'Pagado',
      'ADDITIONAL QUESTIONS': 'PREGUNTAS ADICIONALES',
      'Show Answer': 'Mostrar respuesta',
      'Download requests list': 'Descargar lista de solicitudes',
      Decline: 'Rechazar',
      'Confirm Decline': 'Confirmar rechazo',
      requestDeclineWarning: '¿Está seguro de que desea rechazar las solicitudes seleccionadas?',
      'Copy Selected Requests': 'Copiar solicitudes seleccionadas',
      'No questions found': 'No se encontraron preguntas',
      'Answer/Files': 'Respuesta/Archivos',
      Download: 'Descargar',
      'Edit Entries successfully': 'Editar entradas correctamente',
      'Tournament data or category not available': 'Datos del torneo o categoría no disponibles',
      'Please mark the Checkbox': 'Por favor marque la casilla de verificación',
      'Confirming...': 'Confirmando...',
      'Confirmed successfully!': '¡Confirmado exitosamente!',
      'Please enter both player emails': 'Por favor ingresa los correos electrónicos de ambos jugadores.',
      'Please enter player email': 'Por favor ingresa el correo electrónico del jugador',
      'Step 1: Select category': 'Paso 1: seleccione la categoría',
      'Athlete email (user account)': 'Correo electrónico del deportista (cuenta de usuario)',
      'Player is not registered in the platform': 'El jugador no está registrado en la plataforma.',
      'First athlete email (user account)': 'Correo electrónico del primer deportista (cuenta de usuario)',
      'First player is not registered in the platform': 'El primer jugador no está registrado en la plataforma.',
      'Second athlete email (user account)': 'Correo electrónico del segundo deportista (cuenta de usuario)',
      'Second player is not registered in the platform': 'El segundo jugador no está registrado en la plataforma.',
      Update: 'Actualizar',
      'No Team': 'Sin equipo',
      'Athlete email is required': 'Se requiere el correo electrónico del atleta',
      'First athlete email is required': 'Se requiere el correo electrónico del primer atleta',
      'Second athlete email is required': 'Se requiere el correo electrónico del segundo atleta.',
      'Add Entries successfully': 'Agregar entradas exitosamente',
      'Select category': 'Seleccionar categoría',
      'No category exists': 'No existe ninguna categoría',
      Registration: 'Registro',
      Register: 'Registro',
      'There is no available requests!': '¡No hay solicitudes disponibles!',
      'Dashboard: Entries Data': 'Panel de control: Datos de entradas',
      'Request Updated Successfully!': '¡Solicitud actualizada con éxito!',
      'Import CSV File': 'Importar archivo CSV',
      'Import Entries': 'Importar entradas',
      'Add New Entry': 'Agregar nueva entrada',
      'Entries deleted successfully.': 'Entradas eliminadas correctamente.',
      'Confirm Deletion': 'Confirmar eliminación',
      deleteEntriesWarning: '¿Está seguro de que desea eliminar las entradas seleccionadas?',
      'PLAYER 1': 'JUGADOR 1',
      'PLAYER 2': 'JUGADOR 2',
      Rank: 'Rango',
      'Total Ranks': 'Rangos totales',
      Priority: 'Prioridad',
      Assign: 'Asignar',
      'Limit reached': 'Límite alcanzado',
      'Assigning...': 'Asignando...',
      'Removing...': 'Eliminando...',
      'Moving...': 'Emocionante...',
      'Updated successfully': 'Actualizado exitosamente',
      'Please enter a value': 'Por favor introduce un valor',
      'Updating...': 'Actualizando...',
      'Please enter a rating': 'Por favor ingresa una calificación',
      'Edit Rating': 'Editar calificación',
      'Please enter a rank': 'Por favor ingresa un rango',
      'Edit Rank': 'Editar rango',
      'Change Category': 'Cambiar categoría',
      'Move selected entries to Requests': 'Mover las entradas seleccionadas a Solicitudes',
      'Withdraw selected entries': 'Retirar entradas seleccionadas',
      'Delete rankings': 'Eliminar clasificaciones',
      'Update rankings & seeding': 'Actualizar clasificaciones y siembra',
      'Download entry List': 'Descargar lista de entradas',
      withdrawEntriesWarning: '¿Está seguro de que desea retirar las entradas seleccionadas?',
      'File uploaded & entries created successfully': 'Archivo cargado y entradas creadas correctamente',
      'Please select a file to Upload': 'Por favor seleccione un archivo para cargar',
      'Import entries via CSV template': 'Importar entradas mediante plantilla CSV',
      'Download CSV template for Singles format category': 'Descargue la plantilla CSV para la categoría de formato Solteros',
      'CSV file Downloaded': 'Archivo CSV descargado',
      'Download CSV template for Doubles format category': 'Descargue la plantilla CSV para la categoría de formato Dobles',
      'Upload & create entries': 'Cargar y crear entradas',
      'There is no available entries!': '¡No hay entradas disponibles!',
      'Entry Fee is Required Field': 'La tarifa de inscripción es un campo obligatorio',
      'Type is Required Field': 'El tipo es campo obligatorio',
      'Payment Information Removed Successfully': 'Información de pago eliminada correctamente',
      'Paid Information Added Successfully': 'Información pagada agregada exitosamente',
      'Update Paid information': 'Actualizar información pagada',
      'Paid information': 'Información pagada',
      'Amount of entry fee': 'Monto de la tarifa de entrada',
      Type: 'Tipo',
      'Remove payment': 'Eliminar pago',
      'Drag and Drop a file here, or click to Select file': 'Arrastre y suelte un archivo aquí, o haga clic para seleccionar el archivo',
      'Supported file': 'Archivo soportado',
      'Dashboard: Withdrawals Data': 'Panel de control: datos de retiros',
      'Entries Deleted Successfully!': '¡Entradas eliminadas con éxito!',
      'Deleting...': 'Eliminando...',
      'There is no available withdrawals!': '¡No hay retiros disponibles!',
      deleteWithdrawalsWarning: '¿Está seguro de que desea eliminar las entradas seleccionadas?',
      'Move selected entries to Entry list': 'Mover entradas seleccionadas a la lista de entradas',
      'Dashboard: Groups Data': 'Panel de control: datos de grupos',
      'Back to tournament': 'Volver al torneo',
      'Submit results to Live standings': 'Enviar resultados a la clasificación en vivo',
      'Please select at least one group': 'Por favor seleccione al menos un grupo',
      'Live standings submitted successfully': 'Clasificación en vivo enviada exitosamente',
      'Submit Live Standings': 'Enviar clasificaciones en vivo',
      'Select Category': 'Seleccionar categoría',
      'Select Groups...': 'Seleccionar grupos...',
      'Please select at least one Group.': 'Seleccione al menos un grupo.',
      'Standings Title': 'Título de la clasificación',
      Sorting: 'Clasificación',
      'Removing groups...': 'Eliminando grupos...',
      'Groups removed successfully': 'Grupos eliminados correctamente',
      Hiding: 'Ocultación',
      Publishing: 'Publicación',
      Group: 'Grupo',
      Successfully: 'Exitosamente',
      Published: 'Publicado',
      Hide: 'Esconder',
      Publish: 'Publicar',
      'Updating scores...': 'Actualizando puntuaciones...',
      'Scores updated successfully': 'Puntajes actualizados exitosamente',
      'Deleting group...': 'Eliminando grupo...',
      'Group Deleted successfully': 'Grupo eliminado con éxito',
      'Deleting entries...': 'Eliminando entradas...',
      'Entries deleted successfully': 'Entradas eliminadas exitosamente',
      'Delete Selected Entries': 'Eliminar entradas seleccionadas',
      'Delete Selected Teams': 'Eliminar equipos seleccionados',
      'Publish this group': 'Publicar este grupo',
      'Hide this group': 'Ocultar este grupo',
      'Print/Download this group': 'Imprimir/Descargar este grupo',
      Recover: 'Recuperar',
      'this group': 'este grupo',
      'Add team to this group': 'Agregar equipo a este grupo',
      'Add team': 'Agregar equipo',
      'Update places for entries': 'Actualizar lugares para entradas',
      'Update Places': 'Actualizar lugares',
      'Generate OOP For This Group Only': 'Generar programación orientada a objetos solo para este grupo',
      'Create Matches for this group': 'Crear coincidencias para este grupo',
      'Create Matches': 'Crear coincidencias',
      POINTS: 'AGUJAS',
      SR: 'SR',
      PR: 'relaciones públicas',
      PLACE: 'LUGAR',
      Place: 'Lugar',
      'Match Time Conflict': 'Conflicto de tiempo de partido',
      'Winner is required when score is provided': 'Se requiere el ganador cuando se proporciona la puntuación.',
      'Match cannot be unfinished when score is provided': 'El partido no puede quedar sin terminar cuando se proporciona la puntuación.',
      'Space at the end is not allowed': 'No se permite espacio al final',
      'Please follow the pattern': 'Por favor sigue el patrón',
      'Date is required Field': 'La fecha es obligatoria. Campo',
      'Time is required Field': 'Se requiere tiempo Campo',
      'Add Score Successfully': 'Añadir puntuación con éxito',
      'Select Winner': 'Seleccionar ganador',
      Athlete: 'Atleta',
      'Additional match status options': 'Opciones adicionales de estado de coincidencia',
      Deselect: 'Deseleccionar',
      Date: 'Fecha',
      'Start Time': 'Hora de inicio',
      'Match Status': 'Estado del partido',
      'Delete match from oop': 'Eliminar coincidencia de oop',
      'This Group is Deleted': 'Este grupo está eliminado',
      groupRecoverInfo: 'Si crees que el grupo fue eliminado por error, puedes recuperarlo haciendo clic en el botón recuperar en solo 1 día.',
      'There is no available groups': 'No hay grupos disponibles',
      DATE: 'FECHA',
      COURT: 'CORTE',
      TEAM1: 'EQUIPO1',
      TEAM2: 'EQUIPO2',
      RESULT: 'RESULTADO',
      Court: 'Corte',
      Score: 'Puntaje',
      'Add Score': 'Agregar puntuación',
      'Delete group Match from oop': 'Eliminar grupo Match de oop',
      'Edit Match': 'Editar partido',
      'Match Updated Successfully': 'Partido actualizado con éxito',
      'Edit Match Date & Time': 'Editar fecha y hora del partido',
      'Match Date Updated Successfully': 'Fecha del partido actualizada con éxito',
      'Date is Required Field': 'La fecha es un campo obligatorio',
      'Time is Required Field': 'El tiempo es un campo obligatorio',
      'Order of Play': 'Orden de juego',
      'Please select at least one court': 'Por favor seleccione al menos un tribunal',
      'Generate OOP Successfully': 'Genere programación orientada a objetos con éxito',
      'Generate order of play for': 'Generar orden de juego para',
      'Number of teams': 'Número de equipos',
      'Number of matches': 'Número de partidos',
      'Add Rounds...': 'Agregar rondas...',
      'Please select at least one Round.': 'Seleccione al menos una ronda.',
      Add: 'Agregar',
      'Please select at least one.': 'Por favor seleccione al menos uno.',
      'Approximate match duration': 'Duración aproximada del partido',
      'in minutes': 'en minutos',
      GENERATE: 'GENERAR',
      'Group Edited Successfully': 'Grupo editado exitosamente',
      'Edit Group': 'Editar grupo',
      'Group Name': 'Nombre del grupo',
      'Display order of play under the group': 'Mostrar orden de juego bajo el grupo.',
      'Display list of matches under the group': 'Mostrar lista de coincidencias bajo el grupo.',
      Comment: 'Comentario',
      'Group Name is Required Field': 'El nombre del grupo es un campo obligatorio',
      'Entry is required': 'Se requiere entrada',
      'Adding Team...': 'Agregando equipo...',
      'Team Added Successfully': 'Equipo agregado exitosamente',
      'Add team to group': 'Agregar equipo al grupo',
      'OOP generated successfully': 'POO generada con éxito',
      'Matches created successfully': 'Coincidencias creadas con éxito',
      'Generate OOP': 'Generar programación orientada a objetos',
      Duration: 'Duración',
      'No courts available': 'No hay canchas disponibles',
      'Update Entry': 'Actualizar entrada',
      'Update Entry successfully': 'Actualizar entrada exitosamente',
      Cancel: 'Cancelar',
      deleteGroupWarning: '¿Estás seguro de que deseas eliminar este grupo?',
      deleteGroupEntryWarning: '¿Está seguro de que desea eliminar esta entrada?',
      deleteGroupEntriesWarning: '¿Está seguro de que desea eliminar estas entradas?',
      'Groups deleted successfully.': 'Los grupos se eliminaron correctamente.',
      'Edit Selected Groups': 'Editar grupos seleccionados',
      'Delete Selected Groups': 'Eliminar grupos seleccionados',
      deleteGroupsWarning: '¿Está seguro de que desea eliminar los grupos seleccionados?',
      'Select Entries': 'Seleccionar entradas',
      'PDF being generated...': 'PDF en proceso de generación...',
      'Failed to generate PDF': 'No se pudo generar el PDF',
      'Generating PDF...': 'Generando PDF...',
      'Please wait...': 'Espere por favor...',
      'All groups Published successfully': 'Todos los grupos Publicado exitosamente',
      'All groups Unpublished successfully': 'Todos los grupos Despublicado correctamente',
      'PUBLISH GROUPS': 'GRUPOS DE PUBLICACIÓN',
      'HIDE GROUPS': 'OCULTAR GRUPOS',
      'No segment found': 'No se encontró ningún segmento',
      'All Segments': 'Todos los segmentos',
      'All Categories': 'Todas las categorías',
      'Generate OOP for all groups': 'Generar programación orientada a objetos para todos los grupos',
      'Add Group': 'Agregar grupo',
      'Download PDF': 'Descargar PDF',
      'Export matches for DUPR': 'Exportar coincidencias para DUPR',
      'Publish All Groups': 'Publicar todos los grupos',
      'Unpublish All Groups': 'Anular la publicación de todos los grupos',
      'Please select at least one Court.': 'Por favor seleccione al menos un tribunal.',
      'Select Groups': 'Seleccionar grupos',
      'All Groups': 'Todos los grupos',
      'Match duration is Required Field': 'La duración del partido es un campo obligatorio',
      'Round is Required Field': 'La ronda es un campo obligatorio',
      'Courts is Required Field': 'Los tribunales son campos obligatorios',
      'Print/Download All group': 'Imprimir/Descargar todo el grupo',
      'Category is Required Field': 'La categoría es un campo obligatorio',
      'Segment is a Required Field': 'El segmento es un campo obligatorio',
      'Group Type is Required Field': 'El tipo de grupo es un campo obligatorio',
      'Groups Name is Required Field': 'El nombre del grupo es un campo obligatorio',
      'Groups Naming is Required Field': 'El nombre de los grupos es un campo obligatorio',
      'Please enter a 2-digit number.': 'Por favor ingrese un número de 2 dígitos.',
      'No of groups is Required Field': 'El número de grupos es un campo obligatorio',
      'Algorithm is Required Field': 'El algoritmo es un campo obligatorio',
      'Generate Groups is Required Field': 'Generar grupos es un campo obligatorio',
      'Add Group Successfully': 'Agregar grupo exitosamente',
      'Add Groups': 'Agregar grupos',
      'Select segment': 'Seleccionar segmento',
      'Groups type': 'Tipo de grupos',
      'Number of groups': 'Número de grupos',
      'Groups naming': 'Nomenclatura de grupos',
      'Select Round': 'Seleccionar Ronda',
      'Generate Groups': 'Generar grupos',
      'Select Algorithm': 'Seleccionar algoritmo',
      'Tournament copied successfully': 'Torneo copiado exitosamente',
      'Title is required': 'Se requiere título',
      'Start date is required': 'Se requiere fecha de inicio',
      'End date cannot be before start date': 'La fecha de finalización no puede ser anterior a la fecha de inicio.',
      'Entry deadline is required': 'Se requiere fecha límite de inscripción.',
      'Withdrawl deadline is required': 'Se requiere fecha límite de retiro',
      'Dashboard: Partners': 'Panel de control: Socios',
      'Add New Partners': 'Agregar nuevos socios',
      'No Partners data!': '¡Sin datos de socios!',
      'Showing dates': 'Mostrando fechas',
      Text: 'Texto',
      Link: 'Enlace',
      'Dashboard: Documents Data': 'Panel de control: Datos de documentos',
      'League Regulations': 'Reglamento de la Liga',
      'No regulations uploaded yet': 'Aún no se ha subido ningún reglamento',
      'Tournament Regulations': 'Reglamento del Torneo',
      'Uploading...': 'Subiendo...',
      'Regulations uploaded successfully': 'Reglamento cargado exitosamente',
      'Error uploading regulations': 'Error al subir normativa',
      'Dashboard: Participants Data': 'Panel de control: Datos de los participantes',
      'Status: ': 'Estado: ',
      'Show All': 'Mostrar todo',
      'No status found': 'No se encontró ningún estado',
      'Export User Emails': 'Exportar correos electrónicos de usuarios',
      'Export Users': 'Exportar usuarios',
      'PLAYER NAME': 'NOMBRE DEL JUGADOR',
      COUNTRY: 'PAÍS',
      'There is no participants data!': '¡No hay datos de participantes!',
      'Dashboard: Final Standings': 'Panel de control: Clasificación final',
      'Add Final Results Manually': 'Agregar resultados finales manualmente',
      'There is no available tournament results': 'No hay resultados del torneo disponibles',
      'Final results added successfully': 'Resultados finales agregados exitosamente',
      Points: 'Agujas',
      'Select place': 'Seleccionar lugar',
      'Result deleted successfully': 'Resultado eliminado exitosamente',
      'Duplicate entries & assign points to another category': 'Duplicar entradas y asignar puntos a otra categoría',
      'Duplicate & Assign': 'Duplicar y asignar',
      'Hide Results': 'Ocultar resultados',
      'Publish Results': 'Publicar resultados',
      'Delete selected entries': 'Eliminar entradas seleccionadas',
      'Delete results block': 'Eliminar bloque de resultados',
      Entry: 'Entrada',
      'Download List': 'Descargar lista',
      'Add new result': 'Agregar nuevo resultado',
      'Entry Deleted Successfully': 'Entrada eliminada exitosamente',
      'Points is required': 'Se requieren puntos',
      'Place is required': 'Se requiere lugar',
      'Please select a entry': 'Por favor seleccione una entrada',
      'Updated Successfully': 'Actualizado con éxito',
      'Edit Entry': 'Editar entrada',
      'Delete Entry': 'Eliminar entrada',
      deleteRankingEntryWarning: '¿Estás seguro de que deseas eliminar esta entrada?',
      'Select Pair': 'Seleccionar par',
      'Select Player': 'Seleccionar jugador',
      'No ranking points found': 'No se encontraron puntos de clasificación',
      'Select Ranking Point block': 'Seleccionar bloque de puntos de clasificación',
      'To Category': 'A categoría',
      'From Category': 'De categoría',
      'Duplicate entries & points to another category': 'Entradas duplicadas y puntos a otra categoría',
      'Entries & points copied successfully': 'Entradas y puntos copiados correctamente',
      'From Category is required': 'De categoría es obligatorio',
      'To Category is required': 'Se requiere categoría',
      'Ranking Point is required': 'Se requiere punto de clasificación',
      deleteResultsWarning: '¿Está seguro de que desea eliminar estos resultados?',
      'Dashboard: Live Standings Data': 'Panel de control: datos de clasificación en vivo',
      'Segment: ': 'Segmento: ',
      'No segments found': 'No se encontraron segmentos',
      'Add Live Standings': 'Agregar clasificaciones en vivo',
      'Submit final Results': 'Enviar resultados finales',
      'There is no available live standings': 'No hay clasificaciones en vivo disponibles',
      'Groups is Required Field': 'Grupos es un campo obligatorio',
      'Standings Title is Required Field': 'El título de la clasificación es un campo obligatorio',
      'Sorting is Required Field': 'La clasificación es un campo obligatorio',
      'Live standings added successfully': 'Clasificación en vivo agregada exitosamente',
      'Live Standing is Required Field': 'Vivir en pie es un campo obligatorio',
      'Results added successfully': 'Resultados agregados exitosamente',
      'Submit Results to Rankings': 'Enviar resultados a las clasificaciones',
      'Select Live Standing': 'Seleccionar posición en vivo',
      'No Live Standings found': 'No se encontraron clasificaciones en vivo',
      'Deleting live standings...': 'Eliminando clasificaciones en vivo...',
      'Live standings deleted successfully.': 'Las clasificaciones en vivo se eliminaron correctamente.',
      'Delete Selected Live Standings': 'Eliminar clasificaciones en vivo seleccionadas',
      deleteLivesStandingsWarning: '¿Estás seguro de que deseas eliminar las clasificaciones en vivo seleccionadas?',
      'Generating CSV...': 'Generando CSV...',
      'CSV being generated...': 'CSV generándose...',
      'Failed to generate CSV': 'No se pudo generar CSV',
      'Dashboard: Order Of Play': 'Panel de control: orden de juego',
      Grid: 'Red',
      List: 'Lista',
      "Bird's View": 'Vista de pájaro',
      'Hide Order of Play': 'Ocultar orden de juego',
      'Publish Order of Play': 'Publicar orden de juego',
      'Hide OOP': 'Ocultar programación orientada a objetos',
      'Publish OOP': 'Publicar programación orientada a objetos',
      'Enter Name': 'Introduzca el nombre',
      'There is no available order of play': 'No hay orden de juego disponible',
      'Export OOP for DUPR': 'Exportar programación orientada a objetos para DUPR',
      'Deleting Matches...': 'Eliminando coincidencias...',
      'Matches Deleted Successfully!': '¡Partidas eliminadas con éxito!',
      'Delete Selected Matches': 'Eliminar coincidencias seleccionadas',
      deleteMatchesWarning: '¿Estás seguro de que deseas eliminar las coincidencias seleccionadas?',
      'Score is not set': 'La puntuación no está establecida',
      'TEAM 1': 'EQUIPO 1',
      'TEAM 2': 'EQUIPO 2',
      RESULTS: 'RESULTADOS',
      'Delete Match Successfully': 'Eliminar coincidencia con éxito',
      'Court is required': 'Se requiere corte',
      'Edit Score Successfully': 'Editar puntuación con éxito',
      'Edit Draw Match': 'Editar partido de sorteo',
      'Optional match details': 'Detalles opcionales del partido',
      'Delete match': 'Eliminar coincidencia',
      'Confirm Match Deletion': 'Confirmar eliminación de coincidencia',
      deleteDrawMatchWarning: '¿Estás seguro de que deseas eliminar esta coincidencia? ',
      'Add score instructions': 'Agregar instrucciones de puntuación',
      scoreInstructionsInfo1: 'Establezca la puntuación en un campo vacío o el ganador del partido en "Partido no finalizado" y el estado del partido se cambiará automáticamente a "Próximo".',
      scoreInstructionsInfo2: "*Los juegos deben dividirse con el símbolo ':' (dos puntos). ",
      scoreInstructionsInfo3: '**El desempate (si se juega) debe incluirse entre paréntesis "()" e ir después del juego sin espacio. ',
      scoreInstructionsInfo4: '***La decisión de desempate (si se juega) debe incluirse entre corchetes "[]". ',
      'Edit Match Successfully': 'Editar coincidencia con éxito',
      'Edit Group Match': 'Editar partido de grupo',
      'Dashboard: Draw': 'Panel de control: dibujar',
      'Draw is Required Field': 'El sorteo es un campo obligatorio',
      'Please add points to submit': 'Por favor agregue puntos para enviar',
      'Select Draw': 'Seleccione Dibujar',
      'No Draws found': 'No se encontraron sorteos',
      'All draws': 'Todos los sorteos',
      'Publish Draws': 'Publicar sorteos',
      'Hide Draws': 'Ocultar sorteos',
      'Generate Order Of Play': 'Generar orden de juego',
      'Add new Draw': 'Añadir nuevo sorteo',
      'Draw templates is Required Field': 'Las plantillas de dibujo son un campo obligatorio',
      'Draw title is Required Field': 'El título del sorteo es un campo obligatorio',
      'Generate draw is Required Field': 'Generar sorteo es un campo obligatorio',
      'Live Standings is Required Field': 'La clasificación en vivo es un campo obligatorio',
      'Start Live Standings is Required Field': 'Iniciar clasificación en vivo es un campo obligatorio',
      'End Live Standings is Required Field': 'Finalizar la clasificación en vivo es un campo obligatorio',
      'Draw type is Required Field': 'El tipo de sorteo es un campo obligatorio',
      'Draw segment is Required Field': 'El segmento de dibujo es un campo obligatorio',
      'Qual winners is Required Field': 'Los ganadores calificados son campos obligatorios',
      'No of seeds is Required Field': 'El número de semillas es un campo obligatorio',
      'Regenerate Draw Successfully': 'Regenerar sorteo con éxito',
      'Add Draw Successfully': 'Agregar sorteo exitosamente',
      'Draw title': 'Título del sorteo',
      'Draw segment': 'Dibujar segmento',
      'Templates size': 'Tamaño de las plantillas',
      'Add Match for 3rd Place': 'Agregar partido por el 3er lugar',
      'Qual Winners': 'Ganadores calificados',
      'Generate Draw': 'Generar sorteo',
      'Main Draw': 'Cuadro principal',
      'No Main Draw found': 'No se encontró ningún cuadro principal',
      'Main Draw Entries': 'Entradas al cuadro principal',
      'Select Live Standings': 'Seleccionar clasificación en vivo',
      'Loading...': 'Cargando...',
      'Live Standings from': 'Posiciones en vivo desde',
      'Live Standings to': 'Posiciones en vivo para',
      'Algo Type': 'Tipo de algoritmo',
      'Regenerate Draw': 'Regenerar sorteo',
      'Add Draw': 'Agregar sorteo',
      deleteDrawWarning: 'Todas las entradas del sorteo y los partidos se eliminarán y se generará un sorteo nuevo. ',
      Warning: 'Advertencia',
      Confirm: 'Confirmar',
      'Generate order of play Successfully': 'Generar orden de juego con éxito',
      'Generate order of play': 'Generar orden de juego',
      'Select Draws': 'Seleccionar sorteos',
      'Select Rounds': 'Seleccionar rondas',
      'Time Settings': 'Configuración de hora',
      Select: 'Seleccionar',
      'PDF Generated Successfully!': '¡PDF generado con éxito!',
      'Publishing draw...': 'Sorteo editorial...',
      'Hiding draw...': 'Ocultando dibujo...',
      'Successfully!': '¡Exitosamente!',
      'Regenerate this draw': 'Regenerar este sorteo',
      editDrawNameInfo: 'Si desea cambiar el tamaño del dibujo o cualquier otra configuración de dibujo, debe eliminar el dibujo y agregar uno nuevo nuevamente.',
      'Draw Name': 'Nombre del sorteo',
      'Edit Draw': 'Editar dibujo',
      'Draw Name Edited Successfully': 'Nombre del sorteo editado con éxito',
      'Draw Name is required': 'Se requiere el nombre del sorteo',
      'Type is required': 'Se requiere tipo',
      'Publish this draw': 'Publicar este sorteo',
      'Hide this draw': 'Ocultar este sorteo',
      'There is no available draws': 'No hay sorteos disponibles',
      'Draws deleted successfully.': 'Sorteos eliminados con éxito.',
      'Delete Selected Draws': 'Eliminar sorteos seleccionados',
      deleteDrawsWarning: '¿Estás seguro de que quieres eliminar los sorteos seleccionados?',
      deleteDrawEntryWarning: '¿Estás seguro de que deseas eliminar esta entrada?',
      'Select a Entry': 'Seleccione una entrada',
      'Edit team One': 'Editar equipo uno',
      'Edit team Two': 'Editar equipo dos',
      'Team Edited Successfully': 'Equipo editado con éxito',
      'Deleting Entry...': 'Eliminando entrada...',
      'Add team One to this Match': 'Agregar el equipo uno a este partido',
      'Add team Two to this Match': 'Añade el equipo dos a este partido',
      'Team Added to Seed Successfully': 'Equipo agregado a la semilla con éxito',
      'Score is Required Field': 'La puntuación es un campo obligatorio',
      'Match Score Added Successfully': 'Puntuación del partido agregada con éxito',
      'Match score': 'Puntuación del partido',
      'Edit score': 'Editar partitura',
      'All Draws are hidden': 'Todos los sorteos están ocultos',
      Upload: 'Subir',
      'Upload File': 'Cargar archivo',
      'Upload File in OOP': 'Cargar archivo en programación orientada a objetos',
      'Upload Document': 'Cargar documento',
      'Document Name': 'Nombre del documento',
      'Name is required': 'El nombre es obligatorio',
      'Are you sure you want to delete this document?': '¿Está seguro de que desea eliminar este documento?',
      deleteGroupMatchWarning: '¿Estás seguro de que deseas eliminar esta coincidencia?',
      deleteMultipleResultsWarning: '¿Está seguro de que desea eliminar estos resultados?',
      'How to Set Up a Tournament Draw?': '¿Cómo configurar un sorteo de torneo?',
      setupTournament1: 'Una vez que hayas indicado la categoría del sorteo y el título, sigue estos pasos para configurar el sorteo del torneo:',
      'Select the Segment:': 'Seleccione el segmento:',
      'Choose from': 'Elige entre',
      'Select the Draw Type:': 'Seleccione el tipo de sorteo:',
      'Single Elimination:': 'Eliminación única:',
      'Participants are eliminated after their first loss': 'Los participantes son eliminados después de su primera derrota.',
      'Double Elimination:': 'Doble Eliminación:',
      'Participants are eliminated after two losses': 'Los participantes quedan eliminados tras dos derrotas.',
      'Generate the Draw:': 'Generar el Sorteo:',
      'Choose the source for generating the draw:': 'Elija la fuente para generar el sorteo:',
      'Use the list of registered participants': 'Utilice la lista de participantes registrados',
      'Use standings based on group results': 'Utilice clasificaciones basadas en resultados de grupo',
      setupTournament2: 'Genere un sorteo en blanco y agregue manualmente participantes a las posiciones.',
      setupTournament3: 'es seleccionado, tendrás una opción adicional para generar el sorteo desde el',
      setupTournament4: 'En este caso, debes seleccionar el sorteo a partir del cual generar.',
      setupTournament5: 'El sistema seleccionará automáticamente a los participantes que perdieron su primer partido, con la opción de que los árbitros ajusten la selección según sea necesario.',
      setupTournament6: 'Configure el algoritmo de generación (si la fuente seleccionada no es manual):',
      setupTournament7: 'Sigue las reglas de la federación internacional, incorporando clasificación y aleatorización.',
      setupTournament8: 'Por ejemplo, en un sorteo de 16 participantes, el primer favorito juega contra el 16º, el segundo contra el 15º, y así sucesivamente.',
      'How to Generate the Order of Play?': '¿Cómo generar el orden de juego?',
      'To generate the Order of Play automatically': 'Para generar el Orden de Juego automáticamente',
      Click: 'Hacer clic',
      'in the draw navigation.': 'en la navegación del sorteo.',
      'Select the specific': 'Seleccione el específico',
      'on which the matches will be set': 'en el que se establecerán los partidos',
      'Specify the approximate duration of each match': 'Especifica la duración aproximada de cada partido',
      setupTournament9: 'Una vez generadas las coincidencias, puedes ajustar los detalles en tres vistas diferentes.',
      'Sorted by match time': 'Ordenado por tiempo de partido',
      setupTournament10: 'Canchas como nombres de columnas con partidos enumerados en la cancha asociada',
      setupTournament11: 'Muestra solo nombres y colores de categorías para proporcionar una descripción general del orden de juego.',
      setupTournament12: 'Si necesita agregar detalles para las coincidencias manualmente, haga clic en',
      setupTournament13: 'debajo del partido y establece todos los detalles requeridos',
      'How to Enter Results?': '¿Cómo ingresar resultados?',
      'To enter match results': 'Para ingresar los resultados del partido',
      setupOOP1: 'Selecciona el partido que deseas actualizar (dentro del sorteo u Orden de Juego).',
      'Set the winner and the score': 'Establece el ganador y la puntuación.',
      'Ensure you format the score correctly': 'Asegúrate de formatear la partitura correctamente',
      "Always start with the winner's score": 'Comience siempre con la puntuación del ganador.',
      'Separate sets with a space': 'Separar conjuntos con un espacio.',
      'Separate games within a set with a colon': 'Separar juegos dentro de un conjunto con dos puntos',
      'Indicate a set tiebreak in parentheses without a space after the set': 'Indicar un desempate de set entre paréntesis sin espacio después del set',
      'Indicate a super tiebreak set in square brackets with a space before it': 'Indicar un súper desempate entre corchetes y un espacio delante',
      Example: 'Ejemplo',
      'Draws are not available to the public until you click': 'Los sorteos no están disponibles para el público hasta que hagas clic',
      setupDraw1: 'Puedes publicar todos los sorteos a la vez o individualmente. ',
      'Delete Draws': 'Eliminar sorteos',
      'To delete a draw': 'Para eliminar un sorteo',
      'Check the checkbox next to the draw title': 'Marque la casilla de verificación junto al título del sorteo.',
      'Automatically Submit Final Results (Place / Points)': 'Enviar automáticamente los resultados finales (lugar/puntos)',
      'To submit final results automatically, follow these steps': 'Para enviar los resultados finales automáticamente, siga estos pasos',
      setupDraw2: 'En la sección "Sorteos", busque y haga clic en el botón "Enviar resultados finales" en la esquina superior derecha.',
      'Choose the draw from which you want to get the positions': 'Elige el sorteo del que quieres obtener las posiciones',
      setupDraw3: 'Verifique las posiciones para asegurarse de que todo esté correcto. ',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'Alternativamente: envíe los resultados finales manualmente en la página "Clasificación final"',
      drawExtra1: 'Para mostrar el nombre del club de los participantes y distribuir a los miembros del club en el sorteo, seleccione las casillas de verificación correspondientes.',
      'You can download and print the draw': 'Puedes descargar e imprimir el sorteo.',
      'For pickleball, you can export matches in the correct format for DUPR': 'Para pickleball, puedes exportar partidos en el formato correcto para DUPR',
      wildCardQuestion: '¿Cómo contar con precisión el número de inscripciones para el cuadro principal, clasificaciones, cabezas de serie y comodines (WC)?',
      wildCardAnswer:
        'Para contar con precisión el número de inscripciones para el cuadro principal, las clasificaciones, las posiciones y los comodines (WC), asegúrese de haber configurado correctamente la configuración de la categoría del torneo. ',
      wildCardAnswer1: 'Configuración > Configuración de categoría.',
      wildCardAnswer2: 'En el mismo lugar, puedes ajustar los métodos de pago aceptados, tarifas de inscripción, límites de inscripción y más.',
      'How to auto confirm entries?': '¿Cómo confirmar automáticamente las entradas?',
      confirmEntries1: 'Para confirmar automáticamente las entradas después del registro o pago, vaya a',
      confirmEntries2: 'Configuración del torneo > Registro del torneo',
      'and check the box for': 'y marque la casilla para',
      'Automatically Confirm Entries': 'Confirmar entradas automáticamente',
      'Add manual tournament results': 'Agregar resultados de torneos manuales',
      results1: 'Para agregar manualmente los resultados del torneo (clasificación final), haga clic en el',
      'Add Final Standings Manually': 'Agregar clasificación final manualmente',
      results2: 'botón. ',
      results3: 'Para que los lugares y puntos se muestren correctamente, asegúrese de que estén configurados en el',
      results4: 'Panel de torneos -> Liga -> sección Puntos de clasificación',
      oop1: 'Para que el Orden de Juego (OOP) sea visible para el público, debes publicarlo en la navegación de Orden de Juego.',
      'Edit / Delete Multiple Matches': 'Editar/eliminar varias coincidencias',
      oop2: 'Seleccione varias coincidencias para editarlas o eliminarlas simultáneamente.',
      'You can upload documents related to OOP': 'Puedes subir documentos relacionados con la programación orientada a objetos',
      'You can download and print matches': 'Puedes descargar e imprimir coincidencias.',
      oopExtra: 'Para pickleball, puedes exportar partidos en el formato correcto para DUPR.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": '¿Cómo actualizar las clasificaciones, la siembra y la segmentación de los participantes?',
      'To update rankings, segmentation, or seeding': 'Para actualizar clasificaciones, segmentación o siembra',
      'Click the': 'Haga clic en',
      'button (represented by a three-dot icon) located next to the': '(representado por un icono de tres puntos) ubicado al lado del',
      button: 'botón',
      'from the dropdown menu': 'desde el menú desplegable',
      'Segmentation Explained': 'Segmentación explicada',
      "To ensure participants' rankings are indicated properly": 'Para garantizar que las clasificaciones de los participantes se indiquen correctamente',
      entries1: 'Verifique que un administrador de liga haya configurado correctamente las categorías del torneo en el',
      'This can be found under': 'Esto se puede encontrar en',
      'League -> Tournament Categories.': 'Liga -> Categorías de torneos.',
      entries2: 'Asegúrese de que las categorías del torneo estén conectadas a la categoría de clasificación correcta.',
      entries3:
        'Si cree que las clasificaciones no se actualizan correctamente, asegúrese de que las categorías de clasificación estén configuradas de acuerdo con las reglas de clasificación en el Panel de Tournated. ',
      Note: 'Nota',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': '¿Cómo actualizar o retirar entradas y asignar comodines (WC)?',
      'To Add a New Entry': 'Para agregar una nueva entrada',
      "By default, you need to provide the player's email.": 'De forma predeterminada, debes proporcionar el correo electrónico del jugador.',
      entries4: 'Si su organización tiene licencias habilitadas, puede seleccionar entre atletas que tengan una licencia activa.',
      'To Withdraw an Entry': 'Para retirar una entrada',
      'Select the entries you want to withdraw.': 'Seleccione las entradas que desea retirar.',
      'and then choose': 'y luego elegir',
      'To Assign a Wild Card (WC)': 'Para asignar un comodín (WC)',
      'Next to the entry, click the three-dot icon and select': 'Junto a la entrada, haga clic en el icono de tres puntos y seleccione',
      entries5: 'Asegúrese de que la cantidad de WC esté configurada en la configuración de Categoría; ',
      'Where can I find participant details?': '¿Dónde puedo encontrar los datos de los participantes?',
      'You can find participant details in two ways': 'Puedes encontrar los detalles de los participantes de dos maneras.',
      'From the Entry List': 'De la lista de inscritos',
      entries6: 'Haga clic en el nombre del participante directamente en la Lista de inscripciones para ver sus detalles.',
      'From the Tournament Data Section': 'Desde la sección de datos del torneo',
      entries7: 'Navegue a Datos del torneo -> Participantes en la barra lateral izquierda para ver los detalles de todos los participantes.',
      entries8: 'Además, tiene la opción de exportar todos los detalles de los participantes o sus correos electrónicos por separado desde la sección Participantes.',
      'How to Add Groups?': '¿Cómo agregar grupos?',
      'To add groups, follow these steps': 'Para agregar grupos, sigue estos pasos',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'Navegue a la sección "Grupos" y haga clic en el botón "Agregar grupos".',
      'Select a Category': 'Seleccione una categoría',
      'Choose the category for which you want to add groups.': 'Elija la categoría para la que desea agregar grupos.',
      'Set Number of Groups and Naming Type': 'Establecer número de grupos y tipo de nombre',
      'Specify the number of groups you want to create.': 'Especifique el número de grupos que desea crear.',
      'Choose the naming convention for the groups': 'Elija la convención de nomenclatura para los grupos.',
      'Select the Group Type': 'Seleccione el tipo de grupo',
      'Used when athletes or teams are competing against each other': 'Se utiliza cuando los atletas o equipos compiten entre sí.',
      'Used when single athletes are playing doubles with each other.': 'Se utiliza cuando los atletas individuales juegan dobles entre sí.',
      'Standard Format Options': 'Opciones de formato estándar',
      groups1: 'Si selecciona el formato Estándar, decida si desea generar miembros del grupo de forma automática o manual.',
      'Select an algorithm': 'Seleccione un algoritmo',
      'Based on international rules.': 'Basado en normas internacionales.',
      groups2: 'Por ejemplo, si hay 3 grupos y 9 entradas, el Grupo 1 incluiría a los jugadores clasificados 1.º, 6.º y 7.º; ',
      groups3:
        'Agregue equipos a cada grupo y, una vez que haya terminado, asegúrese de hacer clic en el botón "Crear coincidencias" ubicado al lado del nombre de cada grupo (este paso se automatizará en la próxima versión).',
      'King Format Options': 'Opciones de formato rey',
      'If you select the King format, choose the': 'Si selecciona el formato King, elija el',
      'Display List of Matches': 'Mostrar lista de coincidencias',
      groups4: 'Para mostrar la lista de coincidencias en cada grupo, seleccione la casilla de verificación etiquetada',
      'To generate the Order of Play (OOP)': 'Para generar el Orden de Juego (OOP)',
      'Choose the Scope': 'Elija el alcance',
      groups5:
        'Decida si desea generar la programación orientada a objetos para todos los grupos a la vez (ubicada en la navegación "Grupos") o para cada grupo por separado (que se encuentra junto al nombre de cada grupo).',
      'Generate the OOP': 'Generar la POO',
      'Click the appropriate button.': 'Haga clic en el botón apropiado.',
      'Select the rounds for which you want to generate the OOP.': 'Seleccione las rondas para las que desea generar la programación orientada a objetos.',
      'Set the date and time for the first match.': 'Establece la fecha y hora del primer partido.',
      'Enter the estimated duration for the matches.': 'Introduzca la duración estimada de los partidos.',
      'Adjust Matches': 'Ajustar coincidencias',
      'You can also create matches manually and make adjustments after the OOP is generated.':
        'También puede crear coincidencias manualmente y realizar ajustes después de generar la programación orientada a objetos.',
      'Select the Match': 'Selecciona el partido',
      groups6: 'Elige el partido que deseas actualizar, ya sea dentro del grupo o desde el Orden de Juego.',
      'Enter the Winner and Score': 'Ingrese el ganador y puntúe',
      'Set the winner of the match.': 'Establece el ganador del partido.',
      'Format the score as follows': 'Formatee la partitura de la siguiente manera',
      "Start with the winner's score.": 'Comience con la puntuación del ganador.',
      'Separate sets with a space.': 'Separe los conjuntos con un espacio.',
      'Use parentheses for a set tiebreak (no space after the set)': 'Utilice paréntesis para un desempate de set (sin espacio después del set)',
      'Use square brackets for a super tiebreak (with a space before it)': 'Utilice corchetes para un súper desempate (con un espacio antes)',
      'How to Generate the Final Round of King Format?': '¿Cómo Generar la Ronda Final del Formato Rey?',
      'To generate the final round in King format': 'Para generar la ronda final en formato Rey',
      'Create New Groups': 'Crear nuevos grupos',
      'Set up new groups and select the "King" format.': 'Configure nuevos grupos y seleccione el formato "Rey".',
      'Select the Round': 'Seleccione la ronda',
      'Choose "Final" as the round type.': 'Elija "Final" como tipo de ronda.',
      'Automatic Sorting': 'Clasificación automática',
      groups7: 'La plataforma utilizará automáticamente las ubicaciones y proporciones de los grupos para clasificar a los participantes para la ronda final.',
      'You can download and print groups.': 'Puede descargar e imprimir grupos.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'Para pickleball, puedes exportar partidos en el formato correcto para DUPR.',
    },
  },
  pl: {
    translation: {
      'Preview Tournament': 'Podgląd turnieju',
      'Control Panel': 'Panel sterowania',
      Instructions: 'Instrukcje',
      'All Tournaments': 'Wszystkie turnieje',
      Settings: 'Ustawienia',
      'General Data': 'Dane ogólne',
      'Category Settings': 'Ustawienia kategorii',
      Entries: 'Wpisy',
      Requests: 'Upraszanie',
      Withdrawals: 'Wypłaty',
      Matches: 'Zapałki',
      Pairings: 'Pary',
      Standings: 'Tabele',
      'Custom Metrics': 'Niestandardowe metryki',
      'List & Results': 'Lista i wyniki',
      Groups: 'Grupy',
      Draws: 'Rysuje',
      'Order Of Play': 'Kolejność gry',
      'Live Standings': 'Tabele na żywo',
      'Final Standings': 'Klasyfikacja końcowa',
      'Tournament Data': 'Dane turnieju',
      Participants: 'Uczestnicy',
      Documents: 'Dokumenty',
      Partners: 'Wzmacniacz',
      'No tournament selected': 'Nie wybrano turnieju',
      'Copy Tournament': 'Kopiuj turniej',
      'Make a copy of selected tournament': 'Zrób kopię wybranego turnieju',
      Completed: 'Zakończony',
      Upcoming: 'Nadchodzące',
      Active: 'Aktywny',
      Total: 'Całkowity',
      'Tournaments list': 'Lista turniejów',
      'Tournament Title': 'Tytuł turnieju',
      Search: 'Szukaj',
      'Create new Tournament': 'Utwórz nowy turniej',
      Delete: 'Usuwać',
      Organizer: 'Organizator',
      Location: 'Lokalizacja',
      'List of categories': 'Lista kategorii',
      'Start - End': 'Początek - Koniec',
      Dashboard: 'Panel',
      'Athlete Portal': 'Portalu Sportowca',
      Profile: 'Profil',
      Logout: 'Wyloguj się',
      Create: 'Tworzyć',
      'Add Tournament': 'Dodaj turniej',
      'Add League': 'Dodaj ligę',
      'Add Club': 'Dodaj klub',
      'Add News': 'Dodaj wiadomości',
      'Add Team': 'Dodaj zespół',
      'Edit Tournament': 'Edytuj turniej',
      'Create Tournament': 'Utwórz turniej',
      editAddDesc: 'Skonfiguruj ustawienia ogólne, wygląd, arkusz informacyjny, ustawienia dodatkowe, zarządzanie i nie tylko.',
      'Configure Categories': 'Skonfiguruj kategorie',
      configureCategoriesDesc: 'Określ ustawienia kategorii osobistych: maksymalna liczba uczestników, kwalifikatorów, PŚ, nasiona, metody płatności i inne.',
      'Manage Entries': 'Zarządzaj wpisami',
      manageEntriesDesc: 'Potwierdzaj/odrzucaj zarejestrowane wpisy, importuj wpisy z szablonu CSV, przypisuj rankingi i rozstawienie, aktualizuj/kopiuj/usuwaj wpisy i nie tylko',
      'Go to Requests': 'Przejdź do Żądań',
      'Go to Entries': 'Przejdź do wpisów',
      'Create Groups, Draws, and Live Standings': 'Twórz grupy, losowania i rankingi na żywo',
      groupsDrawsLiveStandingsDesc: 'Dodawaj i generuj grupy, losowania i rankingi na żywo za pomocą kilku kliknięć. ',
      'Go to Groups': 'Przejdź do Grup',
      'Go to Draws': 'Przejdź do losowań',
      'Go to Live Standings': 'Przejdź do rankingów na żywo',
      'Go to Order of Play': 'Przejdź do kolejności gry',
      'Generate Order of Play': 'Wygeneruj kolejność gry',
      generateOrderOfPlayDesc: 'Po utworzeniu grup/losowań ostatnim krokiem przed publikacją meczów jest wygenerowanie meczów (kolejność gry). ',
      'Publish data': 'Publikuj dane',
      publishDataDesc: 'Aby dane były dostępne publicznie, należy zadbać o publikację grup, losowań, rankingów na żywo i kolejności gier. ',
      'Submit Tournament Results': 'Prześlij wyniki turnieju',
      submitTournamentResultsDesc:
        'Ostatnim krokiem do zakończenia turnieju jest przesłanie wyników turnieju i przydzielenie punktów na podstawie tabeli punktacji (jeśli włączona jest funkcja rankingów). ',
      'Add Results Manually': 'Dodaj wyniki ręcznie',
      instructionsPageTitle: 'Panel: Instrukcje',
      controlPanelDesc: 'Uruchom turniej krok po kroku z tego panelu sterowania',
      General: 'Ogólny',
      'Dates & Location': 'Daty i lokalizacja',
      Appearance: 'Wygląd',
      'Tournament Registration': 'Rejestracja do turnieju',
      Extras: 'Dodatki',
      Management: 'Kierownictwo',
      'Fact Sheet': 'Arkusz informacyjny',
      'Select League': 'Wybierz Ligę',
      'Select Categories': 'Wybierz Kategorie',
      'Payment methods': 'Metody płatności',
      'Payment type': 'Rodzaj płatności',
      Currency: 'Waluta',
      'Online Entry Fee': 'Opłata za wstęp on-line',
      'Entry Fee': 'Opłata za wstęp',
      'Onsite Entry Fee': 'Opłata za wstęp na miejscu',
      'Number of Rounds': 'Liczba rund',
      'Number of Courts': 'Liczba sądów',
      'Number of Stages': 'Liczba etapów',
      'Tournament timezone': 'Strefa czasowa turnieju',
      'Registration Start Date': 'Data rozpoczęcia rejestracji',
      'Start Date': 'Data rozpoczęcia',
      'End Date': 'Data zakończenia',
      'Entry Deadline': 'Termin wpisu',
      'Withdrawal Deadline': 'Termin wypłaty',
      'Select Club': 'Wybierz opcję Klub',
      'No clubs found in selected league': 'Nie znaleziono klubów w wybranej lidze',
      'Select Country': 'Wybierz Kraj',
      City: 'Miasto',
      Address: 'Adres',
      Logo: 'Logo',
      logoDesc: 'Zalecamy obraz o wymiarach 256 x 256 pikseli',
      'Tournament title': 'Tytuł turnieju',
      'Enter tournament title': 'Wpisz tytuł turnieju',
      Description: 'Opis',
      tournamentDesc: 'Napisz opis turnieju',
      'Video URL': 'Adres URL filmu',
      videoLink: 'Wstaw tutaj link do filmu',
      tourWebUrl: 'Adres strony internetowej turnieju',
      'Tournament Poster': 'Plakat turniejowy',
      'Upload Poster': 'Prześlij plakat',
      'Cover Banner': 'Baner okładkowy',
      bannerDesc: 'Zalecamy obraz o wymiarach 2400 x 600 pikseli.',
      'Upload Banner': 'Prześlij baner',
      Regulations: 'Regulamin',
      'Upload Regulations': 'Regulamin przesyłania',
      'No Image': 'Brak obrazu',
      dragDropDesc: 'Przeciągnij i upuść plik tutaj lub kliknij, aby wybrać plik',
      dragDropDesc2: 'Przeciągnij i upuść pliki tutaj lub kliknij, aby wybrać pliki',
      'Select File': 'Wybierz opcję Plik',
      'Additional Questions': 'Dodatkowe pytania',
      'Add New Question': 'Dodaj nowe pytanie',
      'Terms and Conditions': 'Regulamin',
      'Start Typing': 'Zacznij pisać',
      'External tournament registration link': 'Zewnętrzny link rejestracyjny do turnieju',
      'Automatically confirm entries': 'Automatycznie potwierdzaj wpisy',
      confirmEntriesInfo: 'Wpisy dotyczące rejestracji na turnieje będą kierowane bezpośrednio do wpisów, a nie do próśb',
      closeRegistration: 'Zamknij rejestrację dla wszystkich kategorii turniejowych',
      'Edit this question': 'Edytuj to pytanie',
      'Add new question': 'Dodaj nowe pytanie',
      'Question updated successfully!': 'Pytanie zostało pomyślnie zaktualizowane!',
      'Question added successfully!': 'Pytanie dodano pomyślnie!',
      Question: 'Pytanie',
      'Field Type': 'Typ pola',
      Price: 'Cena',
      'Field Option': 'Opcja pola',
      removeQuestionError: 'Aby wybrać typ pola, wymagana jest co najmniej 1 opcja pola',
      Remove: 'Usunąć',
      'Add new option': 'Dodaj nową opcję',
      questionInfo: 'Zadaj to pytanie każdemu sportowcowi',
      Submit: 'Składać',
      isFeatured: 'Niech ten turniej będzie wyróżniony',
      partnerSearch: 'Włącz wyszukiwanie partnerów',
      enablePracticeCourtsBooking: 'Włącz rezerwację kortów treningowych',
      showTournamentChessManagerCalendar: 'Pokaż ten turniej w kalendarzu menadżera szachowego',
      localRatedTournament: 'Turniej oceniany lokalnie',
      fideRatedTournament: 'Turniej oceniany przez FIDE',
      enableTransferBooking: 'Włącz rezerwację transferu',
      enableLiveStream: 'Włącz transmisję na żywo (wkrótce)',
      enableLiveScoring: 'Włącz punktację na żywo (wkrótce)',
      'Select Organizer': 'Wybierz Organizator',
      'No Organizer available': 'Brak dostępnego organizatora',
      'Select main referee': 'Wybierz głównego sędziego',
      'Select referee': 'Wybierz sędziego',
      'No referee available': 'Brak sędziego',
      'Select additional referees': 'Wybierz dodatkowych sędziów',
      'Tournament status': 'Stan turnieju',
      'Make tournament private': 'Ustaw turniej jako prywatny',
      'Set Password': 'Ustaw hasło',
      'New Password': 'Nowe hasło',
      'Confirm Password': 'Potwierdź hasło',
      'No facts found': 'Nie znaleziono żadnych faktów',
      Save: 'Ratować',
      Back: 'Z powrotem',
      'Field Title': 'Tytuł pola',
      'Dashboard: Stage Settings': 'Panel: Ustawienia sceny',
      'Edit tournament': 'Edytuj turniej',
      'Change Settings': 'Zmień ustawienia',
      Title: 'Tytuł',
      Status: 'Status',
      'General Limit': 'Limit ogólny',
      'Individual metric limit': 'Indywidualny limit metryczny',
      'Allow participants to add results': 'Zezwalaj uczestnikom na dodawanie wyników',
      'Attachments status': 'Stan załączników',
      'There is no available': 'Nie ma dostępnego',
      'Public for everyone': 'Publiczne dla wszystkich',
      'Public only for participants': 'Publiczne tylko dla uczestników',
      Hidden: 'Ukryty',
      'Hide other participant results': 'Ukryj wyniki innych uczestników',
      'Individual metric limit applied': 'Zastosowano indywidualny limit metryki',
      'Attachments Status': 'Stan załączników',
      'Public to participants only': 'Publiczne tylko dla uczestników',
      Saving: 'Oszczędność',
      'General data': 'Dane ogólne',
      'Category settings': 'Ustawienia kategorii',
      'Dashboard: Category Settings': 'Panel: Ustawienia kategorii',
      'There is no available category!': 'Brak dostępnej kategorii!',
      Category: 'Kategoria',
      'Online Fee': 'Opłata internetowa',
      'Onsite Fee': 'Opłata na miejscu',
      'VAT Amount': 'Kwota VAT',
      'Payment Type': 'Rodzaj płatności',
      'Payment Method': 'Metoda płatności',
      Rounds: 'Rundy',
      Time: 'Czas',
      'Time per move': 'Czas na ruch',
      'Time after move': 'Czas po ruchu',
      'Extra time': 'Dodatkowy czas',
      Pairing: 'Łączenie w pary',
      entries: 'wpisy',
      'Registration Status': 'Stan rejestracji',
      'Additional service fee': 'Dodatkowa opłata za usługę',
      'Registration Limit': 'Limit rejestracji',
      Edit: 'Redagować',
      'Registrations Limit': 'Limit rejestracji',
      'Include VAT amount?': 'Uwzględnić kwotę podatku VAT?',
      displayClubInfo: 'Wyświetl nazwę klubu sportowca/zespołu w szczegółach wpisu',
      Open: 'Otwarte',
      Closed: 'Zamknięte',
      categorySettingsInfo: 'Ustaw tylko te dane, które chcesz zaktualizować dla wybranych kategorii',
      'Select category color': 'Wybierz kolor kategorii',
      'Dashboard: Requests': 'Panel: Żądania',
      'No category found': 'Nie znaleziono kategorii',
      'Add New Request': 'Dodaj nowe żądanie',
      Actions: 'Działania',
      Confirming: 'Potwierdzam',
      'Confirm Selected Requests': 'Potwierdź wybrane żądania',
      NR: 'NR',
      Team: 'Zespół',
      Player: 'Odtwarzacz',
      Rating: 'Ocena',
      Email: 'E-mail',
      'Phone Number': 'Numer telefonu',
      'Reg. time': 'rej. ',
      Paid: 'Płatny',
      'ADDITIONAL QUESTIONS': 'DODATKOWE PYTANIA',
      'Show Answer': 'Pokaż odpowiedź',
      'Download requests list': 'Pobierz listę żądań',
      Decline: 'Spadek',
      'Confirm Decline': 'Potwierdź odmowę',
      requestDeclineWarning: 'Czy na pewno chcesz odrzucić wybrane prośby?',
      'Copy Selected Requests': 'Skopiuj wybrane żądania',
      'No questions found': 'Nie znaleziono żadnych pytań',
      'Answer/Files': 'Odpowiedź/pliki',
      Download: 'Pobierać',
      'Edit Entries successfully': 'Edytuj wpisy pomyślnie',
      'Tournament data or category not available': 'Dane lub kategoria turnieju są niedostępne',
      'Please mark the Checkbox': 'Proszę zaznaczyć pole wyboru',
      'Confirming...': 'Potwierdzam...',
      'Confirmed successfully!': 'Potwierdzono pomyślnie!',
      'Please enter both player emails': 'Podaj adresy e-mail obu graczy',
      'Please enter player email': 'Proszę podać e-mail gracza',
      'Step 1: Select category': 'Krok 1: Wybierz kategorię',
      'Athlete email (user account)': 'E-mail sportowca (konto użytkownika)',
      'Player is not registered in the platform': 'Gracz nie jest zarejestrowany na platformie',
      'First athlete email (user account)': 'Adres e-mail pierwszego sportowca (konto użytkownika)',
      'First player is not registered in the platform': 'Pierwszy gracz nie jest zarejestrowany na platformie',
      'Second athlete email (user account)': 'Adres e-mail drugiego sportowca (konto użytkownika)',
      'Second player is not registered in the platform': 'Drugi gracz nie jest zarejestrowany na platformie',
      Update: 'Aktualizacja',
      'No Team': 'Brak zespołu',
      'Athlete email is required': 'Wymagany jest adres e-mail sportowca',
      'First athlete email is required': 'Wymagany jest adres e-mail pierwszego sportowca',
      'Second athlete email is required': 'Wymagany jest adres e-mail drugiego sportowca',
      'Add Entries successfully': 'Dodaj wpisy pomyślnie',
      'Select category': 'Wybierz kategorię',
      'No category exists': 'Nie istnieje żadna kategoria',
      Registration: 'Rejestracja',
      Register: 'Rejestr',
      'There is no available requests!': 'Brak dostępnych żądań!',
      'Dashboard: Entries Data': 'Panel: Dane wpisów',
      'Request Updated Successfully!': 'Żądanie zostało zaktualizowane pomyślnie!',
      'Import CSV File': 'Importuj plik CSV',
      'Import Entries': 'Importuj wpisy',
      'Add New Entry': 'Dodaj nowy wpis',
      'Entries deleted successfully.': 'Wpisy zostały pomyślnie usunięte.',
      'Confirm Deletion': 'Potwierdź usunięcie',
      deleteEntriesWarning: 'Czy na pewno chcesz usunąć wybrane wpisy?',
      'PLAYER 1': 'GRACZ 1',
      'PLAYER 2': 'GRACZ 2',
      Rank: 'Stopień',
      'Total Ranks': 'Łączne stopnie',
      Priority: 'Priorytet',
      Assign: 'Przydzielać',
      'Limit reached': 'Limit osiągnięty',
      'Assigning...': 'Przypisywanie...',
      'Removing...': 'Usuwanie...',
      'Moving...': 'Poruszający...',
      'Updated successfully': 'Zaktualizowano pomyślnie',
      'Please enter a value': 'Proszę wprowadzić wartość',
      'Updating...': 'Aktualizowanie...',
      'Please enter a rating': 'Proszę wprowadzić ocenę',
      'Edit Rating': 'Edytuj ocenę',
      'Please enter a rank': 'Proszę wprowadzić rangę',
      'Edit Rank': 'Edytuj ranking',
      'Change Category': 'Zmień kategorię',
      'Move selected entries to Requests': 'Przenieś wybrane wpisy do Żądań',
      'Withdraw selected entries': 'Wycofaj wybrane wpisy',
      'Delete rankings': 'Usuń rankingi',
      'Update rankings & seeding': 'Aktualizuj rankingi i rozstawienie',
      'Download entry List': 'Pobierz listę wpisów',
      withdrawEntriesWarning: 'Czy na pewno chcesz wycofać wybrane wpisy?',
      'File uploaded & entries created successfully': 'Plik przesłany i wpisy utworzone pomyślnie',
      'Please select a file to Upload': 'Wybierz plik do przesłania',
      'Import entries via CSV template': 'Importuj wpisy za pomocą szablonu CSV',
      'Download CSV template for Singles format category': 'Pobierz szablon CSV dla kategorii formatu Single',
      'CSV file Downloaded': 'Pobrano plik CSV',
      'Download CSV template for Doubles format category': 'Pobierz szablon CSV dla kategorii formatu gry podwójnej',
      'Upload & create entries': 'Przesyłaj i twórz wpisy',
      'There is no available entries!': 'Brak dostępnych wpisów!',
      'Entry Fee is Required Field': 'Pole wpisowe jest wymagane',
      'Type is Required Field': 'Typ jest polem wymaganym',
      'Payment Information Removed Successfully': 'Informacje o płatności zostały pomyślnie usunięte',
      'Paid Information Added Successfully': 'Informacje o płatnościach dodano pomyślnie',
      'Update Paid information': 'Zaktualizuj informacje o płatnościach',
      'Paid information': 'Informacje płatne',
      'Amount of entry fee': 'Wysokość wpisowego',
      Type: 'Typ',
      'Remove payment': 'Usuń płatność',
      'Drag and Drop a file here, or click to Select file': 'Przeciągnij i upuść plik tutaj lub kliknij, aby wybrać plik',
      'Supported file': 'Obsługiwany plik',
      'Dashboard: Withdrawals Data': 'Panel: Dane dotyczące wypłat',
      'Entries Deleted Successfully!': 'Wpisy zostały usunięte pomyślnie!',
      'Deleting...': 'Usuwanie...',
      'There is no available withdrawals!': 'Nie ma dostępnych wypłat!',
      deleteWithdrawalsWarning: 'Czy na pewno chcesz usunąć wybrane wpisy?',
      'Move selected entries to Entry list': 'Przenieś wybrane wpisy na listę wpisów',
      'Dashboard: Groups Data': 'Pulpit nawigacyjny: Dane grup',
      'Back to tournament': 'Wracając do turnieju',
      'Submit results to Live standings': 'Przesyłaj wyniki do rankingów na żywo',
      'Please select at least one group': 'Proszę wybrać przynajmniej jedną grupę',
      'Live standings submitted successfully': 'Tabele na żywo przesłane pomyślnie',
      'Submit Live Standings': 'Przesyłaj rankingi na żywo',
      'Select Category': 'Wybierz kategorię',
      'Select Groups...': 'Wybierz Grupy...',
      'Please select at least one Group.': 'Proszę wybrać przynajmniej jedną grupę.',
      'Standings Title': 'Tytuł rankingowy',
      Sorting: 'Sortowanie',
      'Removing groups...': 'Usuwanie grup...',
      'Groups removed successfully': 'Grupy zostały usunięte pomyślnie',
      Hiding: 'Ukrywanie',
      Publishing: 'Wydawniczy',
      Group: 'Grupa',
      Successfully: 'Skutecznie',
      Published: 'Opublikowany',
      Hide: 'Ukrywać',
      Publish: 'Publikować',
      'Updating scores...': 'Aktualizowanie wyników...',
      'Scores updated successfully': 'Wyniki zostały pomyślnie zaktualizowane',
      'Deleting group...': 'Usuwanie grupy...',
      'Group Deleted successfully': 'Grupa została pomyślnie usunięta',
      'Deleting entries...': 'Usuwanie wpisów...',
      'Entries deleted successfully': 'Wpisy zostały pomyślnie usunięte',
      'Delete Selected Entries': 'Usuń wybrane wpisy',
      'Delete Selected Teams': 'Usuń wybrane zespoły',
      'Publish this group': 'Opublikuj tę grupę',
      'Hide this group': 'Ukryj tę grupę',
      'Print/Download this group': 'Wydrukuj/pobierz tę grupę',
      Recover: 'Odzyskiwać',
      'this group': 'ta grupa',
      'Add team to this group': 'Dodaj zespół do tej grupy',
      'Add team': 'Dodaj zespół',
      'Update places for entries': 'Aktualizuj miejsca wpisów',
      'Update Places': 'Aktualizuj Miejsca',
      'Generate OOP For This Group Only': 'Wygeneruj OOP tylko dla tej grupy',
      'Create Matches for this group': 'Utwórz dopasowania dla tej grupy',
      'Create Matches': 'Twórz dopasowania',
      POINTS: 'ZWROTNICA',
      SR: 'SR',
      PR: 'PR',
      PLACE: 'MIEJSCE',
      Place: 'Miejsce',
      'Match Time Conflict': 'Konflikt czasowy meczu',
      'Winner is required when score is provided': 'Zwycięzca jest wymagany, gdy zostanie podany wynik',
      'Match cannot be unfinished when score is provided': 'Mecz nie może zostać niedokończony po podaniu wyniku',
      'Space at the end is not allowed': 'Spacja na końcu jest niedozwolona',
      'Please follow the pattern': 'Proszę postępować według wzoru',
      'Date is required Field': 'Data jest wymagana Pole',
      'Time is required Field': 'Wymagany jest czas. Pole',
      'Add Score Successfully': 'Dodaj wynik pomyślnie',
      'Select Winner': 'Wybierz Zwycięzcę',
      Athlete: 'Sportowiec',
      'Additional match status options': 'Dodatkowe opcje statusu dopasowania',
      Deselect: 'Odznacz',
      Date: 'Data',
      'Start Time': 'Czas rozpoczęcia',
      'Match Status': 'Stan dopasowania',
      'Delete match from oop': 'Usuń dopasowanie z oop',
      'This Group is Deleted': 'Ta grupa została usunięta',
      groupRecoverInfo: 'Jeśli uważasz, że grupa została usunięta przez pomyłkę, możesz ją odzyskać, klikając przycisk odzyskiwania, w ciągu zaledwie 1 dnia',
      'There is no available groups': 'Brak dostępnych grup',
      DATE: 'DATA',
      COURT: 'SĄD',
      TEAM1: 'ZESPÓŁ 1',
      TEAM2: 'ZESPÓŁ2',
      RESULT: 'WYNIK',
      Court: 'Sąd',
      Score: 'Wynik',
      'Add Score': 'Dodaj wynik',
      'Delete group Match from oop': 'Usuń dopasowanie grupowe z oop',
      'Edit Match': 'Edytuj mecz',
      'Match Updated Successfully': 'Mecz został pomyślnie zaktualizowany',
      'Edit Match Date & Time': 'Edytuj datę i godzinę meczu',
      'Match Date Updated Successfully': 'Data meczu została pomyślnie zaktualizowana',
      'Date is Required Field': 'Data jest polem wymaganym',
      'Time is Required Field': 'Pole Czas jest wymagane',
      'Order of Play': 'Kolejność gry',
      'Please select at least one court': 'Proszę wybrać przynajmniej jeden sąd',
      'Generate OOP Successfully': 'Pomyślnie wygeneruj OOP',
      'Generate order of play for': 'Wygeneruj kolejność gry dla',
      'Number of teams': 'Liczba drużyn',
      'Number of matches': 'Liczba meczów',
      'Add Rounds...': 'Dodaj rundy...',
      'Please select at least one Round.': 'Proszę wybrać przynajmniej jedną rundę.',
      Add: 'Dodać',
      'Please select at least one.': 'Proszę wybrać przynajmniej jeden.',
      'Approximate match duration': 'Przybliżony czas trwania meczu',
      'in minutes': 'w ciągu kilku minut',
      GENERATE: 'SPOWODOWAĆ',
      'Group Edited Successfully': 'Grupa została edytowana pomyślnie',
      'Edit Group': 'Edytuj grupę',
      'Group Name': 'Nazwa grupy',
      'Display order of play under the group': 'Wyświetl kolejność gry w grupie',
      'Display list of matches under the group': 'Wyświetl listę dopasowań w grupie',
      Comment: 'Komentarz',
      'Group Name is Required Field': 'Nazwa grupy jest polem wymaganym',
      'Entry is required': 'Wejście jest wymagane',
      'Adding Team...': 'Dodawanie zespołu...',
      'Team Added Successfully': 'Zespół został dodany pomyślnie',
      'Add team to group': 'Dodaj zespół do grupy',
      'OOP generated successfully': 'OOP wygenerowano pomyślnie',
      'Matches created successfully': 'Dopasowania zostały utworzone pomyślnie',
      'Generate OOP': 'Wygeneruj OOP',
      Duration: 'Czas trwania',
      'No courts available': 'Brak dostępnych sądów',
      'Update Entry': 'Aktualizuj wpis',
      'Update Entry successfully': 'Zaktualizuj wpis pomyślnie',
      Cancel: 'Anulować',
      deleteGroupWarning: 'Czy na pewno chcesz usunąć tę grupę?',
      deleteGroupEntryWarning: 'Czy na pewno chcesz usunąć ten wpis?',
      deleteGroupEntriesWarning: 'Czy na pewno chcesz usunąć te wpisy?',
      'Groups deleted successfully.': 'Grupy zostały usunięte.',
      'Edit Selected Groups': 'Edytuj wybrane grupy',
      'Delete Selected Groups': 'Usuń wybrane grupy',
      deleteGroupsWarning: 'Czy na pewno chcesz usunąć wybrane grupy?',
      'Select Entries': 'Wybierz opcję Wpisy',
      'PDF being generated...': 'Generowany jest plik PDF...',
      'Failed to generate PDF': 'Nie udało się wygenerować pliku PDF',
      'Generating PDF...': 'Generowanie pliku PDF...',
      'Please wait...': 'Proszę czekać...',
      'All groups Published successfully': 'Wszystkie grupy Opublikowano pomyślnie',
      'All groups Unpublished successfully': 'Wszystkie grupy Pomyślnie cofnięto publikację',
      'PUBLISH GROUPS': 'PUBLIKUJ GRUPY',
      'HIDE GROUPS': 'UKRYJ GRUPY',
      'No segment found': 'Nie znaleziono segmentu',
      'All Segments': 'Wszystkie segmenty',
      'All Categories': 'Wszystkie kategorie',
      'Generate OOP for all groups': 'Wygeneruj OOP dla wszystkich grup',
      'Add Group': 'Dodaj grupę',
      'Download PDF': 'Pobierz PDF',
      'Export matches for DUPR': 'Eksportuj mecze dla DUPR',
      'Publish All Groups': 'Opublikuj wszystkie grupy',
      'Unpublish All Groups': 'Cofnij publikację wszystkich grup',
      'Please select at least one Court.': 'Proszę wybrać przynajmniej jeden Sąd.',
      'Select Groups': 'Wybierz Grupy',
      'All Groups': 'Wszystkie grupy',
      'Match duration is Required Field': 'Czas trwania meczu jest polem wymaganym',
      'Round is Required Field': 'Runda jest polem wymaganym',
      'Courts is Required Field': 'Sądy jest polem wymaganym',
      'Print/Download All group': 'Wydrukuj/Pobierz całą grupę',
      'Category is Required Field': 'Kategoria jest polem wymaganym',
      'Segment is a Required Field': 'Segment jest polem wymaganym',
      'Group Type is Required Field': 'Typ grupy jest polem wymaganym',
      'Groups Name is Required Field': 'Nazwa grupy jest polem wymaganym',
      'Groups Naming is Required Field': 'Nazewnictwo grup jest polem wymaganym',
      'Please enter a 2-digit number.': 'Proszę wprowadzić 2-cyfrowy numer.',
      'No of groups is Required Field': 'Liczba grup jest polem wymaganym',
      'Algorithm is Required Field': 'Algorytm jest polem wymaganym',
      'Generate Groups is Required Field': 'Pole „Generuj grupy” jest wymagane',
      'Add Group Successfully': 'Dodaj grupę pomyślnie',
      'Add Groups': 'Dodaj grupy',
      'Select segment': 'Wybierz segment',
      'Groups type': 'Typ grup',
      'Number of groups': 'Liczba grup',
      'Groups naming': 'Nazewnictwo grup',
      'Select Round': 'Wybierz opcję Runda',
      'Generate Groups': 'Generuj grupy',
      'Select Algorithm': 'Wybierz Algorytm',
      'Tournament copied successfully': 'Turniej został pomyślnie skopiowany',
      'Title is required': 'Tytuł jest wymagany',
      'Start date is required': 'Data rozpoczęcia jest wymagana',
      'End date cannot be before start date': 'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia',
      'Entry deadline is required': 'Wymagany jest termin zgłoszenia',
      'Withdrawl deadline is required': 'Wymagany jest termin wypłaty',
      'Dashboard: Partners': 'Panel: Partnerzy',
      'Add New Partners': 'Dodaj nowych partnerów',
      'No Partners data!': 'Brak danych Partnerów!',
      'Showing dates': 'Wyświetlanie dat',
      Text: 'Tekst',
      Link: 'Połączyć',
      'Dashboard: Documents Data': 'Panel: Dane dokumentów',
      'League Regulations': 'Regulamin Ligi',
      'No regulations uploaded yet': 'Nie przesłano jeszcze żadnego regulaminu',
      'Tournament Regulations': 'Regulamin Turnieju',
      'Uploading...': 'Przesyłanie...',
      'Regulations uploaded successfully': 'Regulamin został przesłany pomyślnie',
      'Error uploading regulations': 'Błąd podczas przesyłania regulaminu',
      'Dashboard: Participants Data': 'Panel: Dane uczestników',
      'Status: ': 'Status: ',
      'Show All': 'Pokaż wszystko',
      'No status found': 'Nie znaleziono stanu',
      'Export User Emails': 'Eksportuj e-maile użytkowników',
      'Export Users': 'Eksportuj użytkowników',
      'PLAYER NAME': 'NAZWA GRACZY',
      COUNTRY: 'KRAJ',
      'There is no participants data!': 'Brak danych uczestników!',
      'Dashboard: Final Standings': 'Pulpit nawigacyjny: Klasyfikacja końcowa',
      'Add Final Results Manually': 'Dodaj wyniki końcowe ręcznie',
      'There is no available tournament results': 'Brak dostępnych wyników turnieju',
      'Final results added successfully': 'Wyniki końcowe zostały pomyślnie dodane',
      Points: 'Zwrotnica',
      'Select place': 'Wybierz miejsce',
      'Result deleted successfully': 'Wynik został pomyślnie usunięty',
      'Duplicate entries & assign points to another category': 'Duplikuj wpisy i przydzielaj punkty do innej kategorii',
      'Duplicate & Assign': 'Duplikuj i przypisz',
      'Hide Results': 'Ukryj wyniki',
      'Publish Results': 'Publikuj wyniki',
      'Delete selected entries': 'Usuń wybrane wpisy',
      'Delete results block': 'Usuń blok wyników',
      Entry: 'Wejście',
      'Download List': 'Pobierz listę',
      'Add new result': 'Dodaj nowy wynik',
      'Entry Deleted Successfully': 'Wpis został pomyślnie usunięty',
      'Points is required': 'Wymagane są punkty',
      'Place is required': 'Miejsce jest wymagane',
      'Please select a entry': 'Proszę wybrać wpis',
      'Updated Successfully': 'Zaktualizowano pomyślnie',
      'Edit Entry': 'Edytuj wpis',
      'Delete Entry': 'Usuń wpis',
      deleteRankingEntryWarning: 'Czy na pewno chcesz usunąć ten wpis?',
      'Select Pair': 'Wybierz opcję Paruj',
      'Select Player': 'Wybierz Gracz',
      'No ranking points found': 'Nie znaleziono punktów rankingowych',
      'Select Ranking Point block': 'Wybierz blok punktów rankingowych',
      'To Category': 'Do kategorii',
      'From Category': 'Z kategorii',
      'Duplicate entries & points to another category': 'Zduplikowane wpisy i punkty do innej kategorii',
      'Entries & points copied successfully': 'Wpisy i punkty zostały pomyślnie skopiowane',
      'From Category is required': 'Z kategorii jest wymagane',
      'To Category is required': 'Do kategorii jest wymagane',
      'Ranking Point is required': 'Punkt rankingowy jest wymagany',
      deleteResultsWarning: 'Czy na pewno chcesz usunąć te wyniki?',
      'Dashboard: Live Standings Data': 'Pulpit nawigacyjny: Dane dotyczące rankingów na żywo',
      'Segment: ': 'Segment: ',
      'No segments found': 'Nie znaleziono segmentów',
      'Add Live Standings': 'Dodaj rankingi na żywo',
      'Submit final Results': 'Prześlij ostateczne wyniki',
      'There is no available live standings': 'Nie ma dostępnych rankingów na żywo',
      'Groups is Required Field': 'Grupy jest polem wymaganym',
      'Standings Title is Required Field': 'Tytuł klasyfikacji jest polem wymaganym',
      'Sorting is Required Field': 'Sortowanie jest polem wymaganym',
      'Live standings added successfully': 'Tabele na żywo dodano pomyślnie',
      'Live Standing is Required Field': 'Pole wymagane to pozycja na żywo',
      'Results added successfully': 'Wyniki dodano pomyślnie',
      'Submit Results to Rankings': 'Przesyłaj wyniki do rankingów',
      'Select Live Standing': 'Wybierz pozycję na żywo',
      'No Live Standings found': 'Nie znaleziono rankingów na żywo',
      'Deleting live standings...': 'Usuwanie rankingów na żywo...',
      'Live standings deleted successfully.': 'Pomyślnie usunięto rankingi na żywo.',
      'Delete Selected Live Standings': 'Usuń wybrane rankingi na żywo',
      deleteLivesStandingsWarning: 'Czy na pewno chcesz usunąć wybrane rankingi na żywo?',
      'Generating CSV...': 'Generowanie pliku CSV...',
      'CSV being generated...': 'Generowany jest plik CSV...',
      'Failed to generate CSV': 'Nie udało się wygenerować pliku CSV',
      'Dashboard: Order Of Play': 'Panel: Kolejność gry',
      Grid: 'Siatka',
      List: 'Lista',
      "Bird's View": 'Widok z lotu ptaka',
      'Hide Order of Play': 'Ukryj kolejność gry',
      'Publish Order of Play': 'Opublikuj kolejność gry',
      'Hide OOP': 'Ukryj OOP',
      'Publish OOP': 'Opublikuj OOP',
      'Enter Name': 'Wprowadź nazwę',
      'There is no available order of play': 'Nie ma dostępnej kolejności gry',
      'Export OOP for DUPR': 'Eksportuj OOP dla DUPR',
      'Deleting Matches...': 'Usuwanie dopasowań...',
      'Matches Deleted Successfully!': 'Dopasowania zostały pomyślnie usunięte!',
      'Delete Selected Matches': 'Usuń wybrane dopasowania',
      deleteMatchesWarning: 'Czy na pewno chcesz usunąć wybrane dopasowania?',
      'Score is not set': 'Wynik nie jest ustawiony',
      'TEAM 1': 'ZESPÓŁ 1',
      'TEAM 2': 'ZESPÓŁ 2',
      RESULTS: 'WYNIKI',
      'Delete Match Successfully': 'Pomyślnie usuń dopasowanie',
      'Court is required': 'Sąd jest wymagany',
      'Edit Score Successfully': 'Edytuj wynik pomyślnie',
      'Edit Draw Match': 'Edytuj remis',
      'Optional match details': 'Opcjonalne szczegóły meczu',
      'Delete match': 'Usuń dopasowanie',
      'Confirm Match Deletion': 'Potwierdź usunięcie meczu',
      deleteDrawMatchWarning: 'Czy na pewno chcesz usunąć ten mecz? ',
      'Add score instructions': 'Dodaj instrukcje dotyczące punktacji',
      scoreInstructionsInfo1: 'Ustaw wynik na puste pole lub zwycięzcę meczu na „Mecz niezakończony”, a status meczu zostanie automatycznie zmieniony na „Nadchodzący”',
      scoreInstructionsInfo2: '*Gry należy oddzielać symbolem „:” (dwukropkiem). ',
      scoreInstructionsInfo3: '**Tiebreak (jeśli został rozegrany) powinien być zawarty w nawiasie „()” i rozpoczynać się po meczu bez spacji. ',
      scoreInstructionsInfo4: '***Decydujący dogrywka (jeśli została rozegrana) powinna być ujęta w nawiasach kwadratowych „[]”. ',
      'Edit Match Successfully': 'Edytuj mecz pomyślnie',
      'Edit Group Match': 'Edytuj mecz grupowy',
      'Dashboard: Draw': 'Deska rozdzielcza: Rysuj',
      'Draw is Required Field': 'Remis jest polem wymaganym',
      'Please add points to submit': 'Proszę dodać punkty do przesłania',
      'Select Draw': 'Wybierz opcję Rysuj',
      'No Draws found': 'Nie znaleziono żadnych losowań',
      'All draws': 'Wszystkie remisy',
      'Publish Draws': 'Publikuj losowania',
      'Hide Draws': 'Ukryj rysunki',
      'Generate Order Of Play': 'Wygeneruj kolejność gry',
      'Add new Draw': 'Dodaj nowe losowanie',
      'Draw templates is Required Field': 'Szablony rysunków to pole wymagane',
      'Draw title is Required Field': 'Tytuł losowania jest polem wymaganym',
      'Generate draw is Required Field': 'Wygeneruj losowanie jest polem wymaganym',
      'Live Standings is Required Field': 'Tabele na żywo jest polem wymaganym',
      'Start Live Standings is Required Field': 'Pole wymagane to Rozpocznij rankingi na żywo',
      'End Live Standings is Required Field': 'Pole wymagane dla klasyfikacji końcowej na żywo',
      'Draw type is Required Field': 'Typ rysowania to pole wymagane',
      'Draw segment is Required Field': 'Rysuj segment jest polem wymaganym',
      'Qual winners is Required Field': 'Zwycięzcy kwalifikacji to pole wymagane',
      'No of seeds is Required Field': 'Liczba nasion jest polem wymaganym',
      'Regenerate Draw Successfully': 'Pomyślnie zregeneruj losowanie',
      'Add Draw Successfully': 'Dodaj remis pomyślnie',
      'Draw title': 'Narysuj tytuł',
      'Draw segment': 'Narysuj odcinek',
      'Templates size': 'Rozmiar szablonów',
      'Add Match for 3rd Place': 'Dodaj mecz o 3. miejsce',
      'Qual Winners': 'Zwycięzcy kwalifikacji',
      'Generate Draw': 'Wygeneruj remis',
      'Main Draw': 'Losowanie główne',
      'No Main Draw found': 'Nie znaleziono losowania głównego',
      'Main Draw Entries': 'Główne losowania',
      'Select Live Standings': 'Wybierz pozycję Tabele na żywo',
      'Loading...': 'Załadunek...',
      'Live Standings from': 'Tabele na żywo z',
      'Live Standings to': 'Tabele na żywo do',
      'Algo Type': 'Typ Algo',
      'Regenerate Draw': 'Regeneruj losowanie',
      'Add Draw': 'Dodaj remis',
      deleteDrawWarning: 'Wszystkie wpisy do losowań i mecze zostaną usunięte i losowanie zostanie nowo wygenerowane. ',
      Warning: 'Ostrzeżenie',
      Confirm: 'Potwierdzać',
      'Generate order of play Successfully': 'Wygeneruj kolejność gry Pomyślnie',
      'Generate order of play': 'Wygeneruj kolejność gry',
      'Select Draws': 'Wybierz opcję Rysunki',
      'Select Rounds': 'Wybierz Rundy',
      'Time Settings': 'Ustawienia czasu',
      Select: 'Wybierać',
      'PDF Generated Successfully!': 'Plik PDF został wygenerowany pomyślnie!',
      'Publishing draw...': 'Publikacja losowania...',
      'Hiding draw...': 'Ukrywam rysunek...',
      'Successfully!': 'Skutecznie!',
      'Regenerate this draw': 'Zregeneruj to losowanie',
      editDrawNameInfo: 'Jeśli chcesz zmienić rozmiar rysowania lub inne ustawienia rysowania, musisz usunąć rysowanie i dodać nowe.',
      'Draw Name': 'Narysuj imię',
      'Edit Draw': 'Edytuj losowanie',
      'Draw Name Edited Successfully': 'Nazwa rysunku została edytowana pomyślnie',
      'Draw Name is required': 'Nazwa losowania jest wymagana',
      'Type is required': 'Typ jest wymagany',
      'Publish this draw': 'Opublikuj to losowanie',
      'Hide this draw': 'Ukryj to losowanie',
      'There is no available draws': 'Brak dostępnych losowań',
      'Draws deleted successfully.': 'Losowania zostały pomyślnie usunięte.',
      'Delete Selected Draws': 'Usuń wybrane losowania',
      deleteDrawsWarning: 'Czy na pewno chcesz usunąć wybrane losowania?',
      deleteDrawEntryWarning: 'Czy na pewno chcesz usunąć ten wpis?',
      'Select a Entry': 'Wybierz wpis',
      'Edit team One': 'Edytuj zespół pierwszy',
      'Edit team Two': 'Edytuj zespół drugi',
      'Team Edited Successfully': 'Zespół edytował pomyślnie',
      'Deleting Entry...': 'Usuwanie wpisu...',
      'Add team One to this Match': 'Dodaj drużynę 1 do tego meczu',
      'Add team Two to this Match': 'Dodaj drużynę drugą do tego meczu',
      'Team Added to Seed Successfully': 'Pomyślnie dodano zespół do rozstawienia',
      'Score is Required Field': 'Wynik jest polem wymaganym',
      'Match Score Added Successfully': 'Wynik meczu dodano pomyślnie',
      'Match score': 'Wynik meczu',
      'Edit score': 'Edytuj wynik',
      'All Draws are hidden': 'Wszystkie losowania są ukryte',
      Upload: 'Wgrywać',
      'Upload File': 'Prześlij plik',
      'Upload File in OOP': 'Prześlij plik w OOP',
      'Upload Document': 'Prześlij dokument',
      'Document Name': 'Nazwa dokumentu',
      'Name is required': 'Imię i nazwisko jest wymagane',
      'Are you sure you want to delete this document?': 'Czy na pewno chcesz usunąć ten dokument?',
      deleteGroupMatchWarning: 'Czy na pewno chcesz usunąć ten mecz?',
      deleteMultipleResultsWarning: 'Czy na pewno chcesz usunąć te wyniki?',
      'How to Set Up a Tournament Draw?': 'Jak ustawić losowanie turniejowe?',
      setupTournament1: 'Po wskazaniu kategorii i tytułu losowania wykonaj poniższe kroki, aby skonfigurować losowanie turnieju:',
      'Select the Segment:': 'Wybierz segment:',
      'Choose from': 'Wybierz z',
      'Select the Draw Type:': 'Wybierz typ rysowania:',
      'Single Elimination:': 'Pojedyncza eliminacja:',
      'Participants are eliminated after their first loss': 'Uczestnicy są eliminowani po pierwszej porażce',
      'Double Elimination:': 'Podwójna eliminacja:',
      'Participants are eliminated after two losses': 'Uczestnicy odpadają po dwóch porażkach',
      'Generate the Draw:': 'Wygeneruj losowanie:',
      'Choose the source for generating the draw:': 'Wybierz źródło generowania losowania:',
      'Use the list of registered participants': 'Skorzystaj z listy zarejestrowanych uczestników',
      'Use standings based on group results': 'Korzystaj z rankingów opartych na wynikach grupowych',
      setupTournament2: 'Wygeneruj puste losowanie i ręcznie dodaj uczestników do stanowisk.',
      setupTournament3: 'zostanie wybrana, będziesz mieć dodatkową opcję wygenerowania losowania z pliku',
      setupTournament4: 'W takim przypadku musisz wybrać losowanie, z którego chcesz wygenerować.',
      setupTournament5: 'System automatycznie wybierze uczestników, którzy przegrali swój pierwszy mecz, z możliwością dostosowania wyboru przez sędziów w razie potrzeby.',
      setupTournament6: 'Ustaw algorytm generowania (jeśli wybrane źródło nie jest ręczne):',
      setupTournament7: 'Przestrzega zasad federacji międzynarodowej, obejmujących rozstawianie i randomizację.',
      setupTournament8: 'Na przykład, w losowaniu 16 uczestników, pierwsze rozstawienie gra z 16. rozstawieniem, drugie rozstawienie z 15. rozstawieniem i tak dalej.',
      'How to Generate the Order of Play?': 'Jak wygenerować kolejność gry?',
      'To generate the Order of Play automatically': 'Aby automatycznie wygenerować kolejność gry',
      Click: 'Trzask',
      'in the draw navigation.': 'w nawigacji losowania.',
      'Select the specific': 'Wybierz konkretny',
      'on which the matches will be set': 'na którym będą rozgrywane mecze',
      'Specify the approximate duration of each match': 'Określ przybliżony czas trwania każdego dopasowania',
      setupTournament9: 'Po wygenerowaniu dopasowań możesz dostosować szczegóły w trzech różnych widokach',
      'Sorted by match time': 'Posortowane według czasu meczu',
      setupTournament10: 'Sądy jako nazwy kolumn z dopasowaniami wymienionymi pod powiązanym sądem',
      setupTournament11: 'Wyświetla tylko nazwy i kolory kategorii, aby zapewnić przegląd kolejności gry',
      setupTournament12: 'Jeśli chcesz ręcznie dodać szczegóły dopasowań, kliknij przycisk',
      setupTournament13: 'pod dopasowaniem i ustaw wszystkie wymagane szczegóły',
      'How to Enter Results?': 'Jak wprowadzić wyniki?',
      'To enter match results': 'Aby wprowadzić wyniki meczu',
      setupOOP1: 'Wybierz mecz, który chcesz zaktualizować (w losowaniu lub w kolejności gry).',
      'Set the winner and the score': 'Ustal zwycięzcę i wynik',
      'Ensure you format the score correctly': 'Upewnij się, że poprawnie sformatowałeś partyturę',
      "Always start with the winner's score": 'Zawsze zaczynaj od wyniku zwycięzcy',
      'Separate sets with a space': 'Oddziel zestawy spacją',
      'Separate games within a set with a colon': 'Oddziel gry w zestawie dwukropkiem',
      'Indicate a set tiebreak in parentheses without a space after the set': 'Wskaż rozstrzygnięcie seta w nawiasie bez spacji po secie',
      'Indicate a super tiebreak set in square brackets with a space before it': 'Wskaż super dogrywkę w nawiasach kwadratowych ze spacją przed nią',
      Example: 'Przykład',
      'Draws are not available to the public until you click': 'Losowania nie są publicznie dostępne, dopóki nie klikniesz',
      setupDraw1: 'Możesz opublikować wszystkie losowania na raz lub pojedynczo. ',
      'Delete Draws': 'Usuń losowania',
      'To delete a draw': 'Aby usunąć losowanie',
      'Check the checkbox next to the draw title': 'Zaznacz pole wyboru obok tytułu losowania',
      'Automatically Submit Final Results (Place / Points)': 'Automatycznie przesyłaj wyniki końcowe (miejsce / punkty)',
      'To submit final results automatically, follow these steps': 'Aby automatycznie przesłać wyniki końcowe, wykonaj poniższe kroki',
      setupDraw2: 'W sekcji „Losowania” znajdź i kliknij przycisk „Prześlij wyniki końcowe” w prawym górnym rogu',
      'Choose the draw from which you want to get the positions': 'Wybierz losowanie, z którego chcesz otrzymać pozycje',
      setupDraw3: 'Sprawdź pozycje, aby upewnić się, że wszystko jest w porządku. ',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'Alternatywnie — prześlij wyniki końcowe ręcznie na stronie „Ostateczne rankingi”.',
      drawExtra1: 'Aby wyświetlić nazwę klubu uczestników i rozdzielić członków klubu w losowaniu, zaznacz odpowiednie pola wyboru',
      'You can download and print the draw': 'Losowanie można pobrać i wydrukować',
      'For pickleball, you can export matches in the correct format for DUPR': 'W przypadku pickleballu możesz eksportować mecze w odpowiednim formacie dla DUPR',
      wildCardQuestion: 'Jak dokładnie policzyć liczbę zgłoszeń do losowania głównego, kwalifikacji, rozstawień i dzikich kart (PŚ)?',
      wildCardAnswer:
        'Aby dokładnie policzyć liczbę zgłoszeń do losowania głównego, kwalifikacji, rozstawień i dzikich kart (WC), upewnij się, że ustawienia kategorii turnieju zostały poprawnie skonfigurowane. ',
      wildCardAnswer1: 'Ustawienia > Ustawienia kategorii.',
      wildCardAnswer2: 'W tym samym miejscu możesz dostosować akceptowane metody płatności, opłaty wpisowe, limity rejestracji i inne.',
      'How to auto confirm entries?': 'Jak automatycznie potwierdzać wpisy?',
      confirmEntries1: 'Aby automatycznie potwierdzać wpisy po rejestracji lub dokonaniu płatności przejdź do',
      confirmEntries2: 'Ustawienia turnieju > Rejestracja do turnieju',
      'and check the box for': 'i zaznacz pole',
      'Automatically Confirm Entries': 'Automatycznie potwierdzaj wpisy',
      'Add manual tournament results': 'Dodaj ręczne wyniki turnieju',
      results1: 'Aby ręcznie dodać wyniki turnieju (klasyfikację końcową), kliknij przycisk',
      'Add Final Standings Manually': 'Dodaj ręcznie rankingi końcowe',
      results2: 'przycisk. ',
      results3: 'Aby miejsca i punkty były wyświetlane poprawnie należy je ustawić w',
      results4: 'Panel turniejowy -> Liga -> Sekcja Punkty rankingowe',
      oop1: 'Aby kolejność gry (OOP) była widoczna publicznie, należy ją opublikować w nawigacji Kolejność gry.',
      'Edit / Delete Multiple Matches': 'Edytuj/usuń wiele dopasowań',
      oop2: 'Wybierz wiele dopasowań, aby je jednocześnie edytować lub usunąć.',
      'You can upload documents related to OOP': 'Możesz przesyłać dokumenty związane z OOP',
      'You can download and print matches': 'Możesz pobrać i wydrukować mecze',
      oopExtra: 'W przypadku pickleballu możesz eksportować mecze w odpowiednim formacie dla DUPR.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": 'Jak zaktualizować rankingi uczestników, rozstawienie i segmentację?',
      'To update rankings, segmentation, or seeding': 'Aby zaktualizować rankingi, segmentację lub rozstawienie',
      'Click the': 'Kliknij',
      'button (represented by a three-dot icon) located next to the': 'przycisk (reprezentowany przez ikonę z trzema kropkami) znajdujący się obok',
      button: 'przycisk',
      'from the dropdown menu': 'z menu rozwijanego',
      'Segmentation Explained': 'Segmentacja wyjaśniona',
      "To ensure participants' rankings are indicated properly": 'Aby zapewnić prawidłowe wskazanie rankingów uczestników',
      entries1: 'Sprawdź, czy Menedżer Ligi poprawnie skonfigurował kategorie turniejów w pliku',
      'This can be found under': 'Można to znaleźć pod',
      'League -> Tournament Categories.': 'Liga -> Kategorie turniejów.',
      entries2: 'Upewnij się, że kategorie turniejów są powiązane z właściwą kategorią rankingów.',
      entries3: 'Jeśli uważasz, że rankingi nie są odpowiednio aktualizowane, upewnij się, że kategorie rankingowe są ustawione zgodnie z zasadami rankingowymi w Panelu Turniejowym. ',
      Note: 'Notatka',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': 'Jak aktualizować lub wycofywać wpisy i przypisywać dzikie karty (WC)?',
      'To Add a New Entry': 'Aby dodać nowy wpis',
      "By default, you need to provide the player's email.": 'Domyślnie musisz podać adres e-mail gracza.',
      entries4: 'Jeśli Twoja organizacja ma włączone licencje, możesz wybierać spośród sportowców, którzy mają aktywną licencję.',
      'To Withdraw an Entry': 'Aby wycofać wpis',
      'Select the entries you want to withdraw.': 'Wybierz wpisy, które chcesz wycofać.',
      'and then choose': 'a następnie wybierz',
      'To Assign a Wild Card (WC)': 'Aby przypisać dziką kartę (WC)',
      'Next to the entry, click the three-dot icon and select': 'Obok wpisu kliknij ikonę z trzema kropkami i wybierz',
      entries5: 'Upewnij się, że w ustawieniach kategorii została ustawiona liczba toalet; ',
      'Where can I find participant details?': 'Gdzie mogę znaleźć dane uczestnika?',
      'You can find participant details in two ways': 'Dane uczestnika można znaleźć na dwa sposoby',
      'From the Entry List': 'Z listy wpisów',
      entries6: 'Kliknij nazwisko uczestnika bezpośrednio na liście zgłoszeń, aby wyświetlić jego szczegóły.',
      'From the Tournament Data Section': 'Z sekcji Dane turnieju',
      entries7: 'Przejdź do Dane turnieju -> Uczestnicy na lewym pasku bocznym, aby zobaczyć szczegóły dotyczące wszystkich uczestników.',
      entries8: 'Dodatkowo masz możliwość wyeksportowania wszystkich danych uczestników lub ich e-maili oddzielnie z sekcji Uczestnicy.',
      'How to Add Groups?': 'Jak dodać grupy?',
      'To add groups, follow these steps': 'Aby dodać grupy, wykonaj następujące kroki',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'Przejdź do sekcji „Grupy” i kliknij przycisk „Dodaj grupy”.',
      'Select a Category': 'Wybierz kategorię',
      'Choose the category for which you want to add groups.': 'Wybierz kategorię, dla której chcesz dodać grupy.',
      'Set Number of Groups and Naming Type': 'Ustaw liczbę grup i typ nazewnictwa',
      'Specify the number of groups you want to create.': 'Określ liczbę grup, które chcesz utworzyć.',
      'Choose the naming convention for the groups': 'Wybierz konwencję nazewnictwa grup',
      'Select the Group Type': 'Wybierz typ grupy',
      'Used when athletes or teams are competing against each other': 'Używane, gdy sportowcy lub drużyny rywalizują ze sobą',
      'Used when single athletes are playing doubles with each other.': 'Używane, gdy pojedynczy sportowcy grają ze sobą w grze podwójnej.',
      'Standard Format Options': 'Opcje formatu standardowego',
      groups1: 'Jeśli wybierzesz format Standard, zdecyduj, czy członkowie grupy mają być generowani automatycznie, czy ręcznie',
      'Select an algorithm': 'Wybierz algorytm',
      'Based on international rules.': 'W oparciu o zasady międzynarodowe.',
      groups2: 'Na przykład, jeśli są 3 grupy i 9 zgłoszeń, Grupa 1 będzie obejmować graczy z 1., 6. i 7. miejscem w rankingu; ',
      groups3:
        'Dodaj drużyny do każdej grupy, a kiedy już skończysz, kliknij przycisk „Utwórz mecze” znajdujący się obok nazwy każdej grupy (ten krok zostanie zautomatyzowany w nadchodzącej wersji).',
      'King Format Options': 'Opcje formatu King',
      'If you select the King format, choose the': 'Jeśli wybierzesz format King, wybierz',
      'Display List of Matches': 'Wyświetl listę dopasowań',
      groups4: 'Aby wyświetlić listę dopasowań w każdej grupie, zaznacz pole wyboru oznaczone',
      'To generate the Order of Play (OOP)': 'Aby wygenerować kolejność gry (OOP)',
      'Choose the Scope': 'Wybierz zakres',
      groups5: 'Zdecyduj, czy chcesz wygenerować OOP dla wszystkich grup jednocześnie (znajduje się w nawigacji „Grupy”), czy dla każdej grupy osobno (znajduje się obok nazwy każdej grupy).',
      'Generate the OOP': 'Wygeneruj plik OOP',
      'Click the appropriate button.': 'Kliknij odpowiedni przycisk.',
      'Select the rounds for which you want to generate the OOP.': 'Wybierz rundy, dla których chcesz wygenerować OOP.',
      'Set the date and time for the first match.': 'Ustaw datę i godzinę pierwszego meczu.',
      'Enter the estimated duration for the matches.': 'Wprowadź szacowany czas trwania meczów.',
      'Adjust Matches': 'Dostosuj dopasowania',
      'You can also create matches manually and make adjustments after the OOP is generated.': 'Możesz także tworzyć dopasowania ręcznie i wprowadzać zmiany po wygenerowaniu OOP.',
      'Select the Match': 'Wybierz dopasowanie',
      groups6: 'Wybierz mecz, który chcesz zaktualizować, w grupie lub w kolejności gry.',
      'Enter the Winner and Score': 'Wprowadź zwycięzcę i wynik',
      'Set the winner of the match.': 'Wyznacz zwycięzcę meczu.',
      'Format the score as follows': 'Sformatuj partyturę w następujący sposób',
      "Start with the winner's score.": 'Zacznij od wyniku zwycięzcy.',
      'Separate sets with a space.': 'Oddziel zestawy spacją.',
      'Use parentheses for a set tiebreak (no space after the set)': 'W przypadku seta dogrywki użyj nawiasów (bez spacji po secie)',
      'Use square brackets for a super tiebreak (with a space before it)': 'Użyj nawiasów kwadratowych, aby określić super dogrywkę (ze spacją przed nią)',
      'How to Generate the Final Round of King Format?': 'Jak wygenerować ostatnią rundę formatu King?',
      'To generate the final round in King format': 'Aby wygenerować rundę finałową w formacie króla',
      'Create New Groups': 'Utwórz nowe grupy',
      'Set up new groups and select the "King" format.': 'Skonfiguruj nowe grupy i wybierz format „King”.',
      'Select the Round': 'Wybierz rundę',
      'Choose "Final" as the round type.': 'Wybierz „Finał” jako typ rundy.',
      'Automatic Sorting': 'Automatyczne sortowanie',
      groups7: 'Platforma automatycznie użyje rozmieszczenia grup i proporcji, aby posortować uczestników do rundy finałowej.',
      'You can download and print groups.': 'Możesz pobierać i drukować grupy.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'W przypadku pickleballu możesz eksportować mecze w odpowiednim formacie dla DUPR.',
    },
  },
  ru: {
    translation: {
      'Preview Tournament': 'Предварительный турнир',
      'Control Panel': 'Панель управления',
      Instructions: 'Инструкции',
      'All Tournaments': 'Все турниры',
      Settings: 'Настройки',
      'General Data': 'Общие данные',
      'Category Settings': 'Настройки категории',
      Entries: 'Записи',
      Requests: 'Запросы',
      Withdrawals: 'Вывод средств',
      Matches: 'Матчи',
      Pairings: 'Пейринги',
      Standings: 'Турнирная таблица',
      'Custom Metrics': 'Пользовательские метрики',
      'List & Results': 'Список и результаты',
      Groups: 'Группы',
      Draws: 'Ничьи',
      'Order Of Play': 'Порядок игры',
      'Live Standings': 'Живая таблица',
      'Final Standings': 'Итоговое положение',
      'Tournament Data': 'Данные турнира',
      Participants: 'Участники',
      Documents: 'Документы',
      Partners: 'Партнеры',
      'No tournament selected': 'Турнир не выбран',
      'Copy Tournament': 'Копировать турнир',
      'Make a copy of selected tournament': 'Сделать копию выбранного турнира',
      Completed: 'Завершенный',
      Upcoming: 'Предстоящие',
      Active: 'Активный',
      Total: 'Общий',
      'Tournaments list': 'Список турниров',
      'Tournament Title': 'Название турнира',
      Search: 'Поиск',
      'Create new Tournament': 'Создать новый турнир',
      Delete: 'Удалить',
      Organizer: 'Организатор',
      Location: 'Расположение',
      'List of categories': 'Список категорий',
      'Start - End': 'Начало - Конец',
      Dashboard: 'Панель управления',
      'Athlete Portal': 'Спортивный портал',
      Profile: 'Профиль',
      Logout: 'Выход из системы',
      Create: 'Создавать',
      'Add Tournament': 'Добавить турнир',
      'Add League': 'Добавить лигу',
      'Add Club': 'Добавить клуб',
      'Add News': 'Добавить новость',
      'Add Team': 'Добавить команду',
      'Edit Tournament': 'Редактировать турнир',
      'Create Tournament': 'Создать турнир',
      editAddDesc: 'Настройте общие параметры, внешний вид, информационный бюллетень, дополнительные настройки, управление и многое другое.',
      'Configure Categories': 'Настроить категории',
      configureCategoriesDesc: 'Укажите персональные настройки категории: максимальное количество участников, квалификации, ЧМ, семена, способы оплаты и многое другое.',
      'Manage Entries': 'Управление записями',
      manageEntriesDesc: 'Подтвердить/отклонить зарегистрированные записи, импортировать записи из шаблона CSV, назначить рейтинг и посев, обновить/копировать/удалить записи и многое другое.',
      'Go to Requests': 'Перейти к запросам',
      'Go to Entries': 'Перейти к записям',
      'Create Groups, Draws, and Live Standings': 'Создавайте группы, проводите жеребьёвки и турнирную таблицу в реальном времени.',
      groupsDrawsLiveStandingsDesc: 'Добавляйте и генерируйте группы, розыгрыши и турнирную таблицу в реальном времени за пару кликов. ',
      'Go to Groups': 'Перейти в группы',
      'Go to Draws': 'Перейти к розыгрышам',
      'Go to Live Standings': 'Перейти к турнирной таблице',
      'Go to Order of Play': 'Перейти к порядку игры',
      'Generate Order of Play': 'Создать порядок игры',
      generateOrderOfPlayDesc: 'После создания групп/розыгрышей последним шагом перед публикацией матчей является генерация матчей (порядок игры). ',
      'Publish data': 'Публикация данных',
      publishDataDesc: 'Чтобы сделать данные общедоступными, вам необходимо обеспечить публикацию групп, жеребьевок, турнирной таблицы и порядка игры. ',
      'Submit Tournament Results': 'Отправить результаты турнира',
      submitTournamentResultsDesc: 'Последним шагом для завершения турнира является представление результатов турнира и присвоение очков на основе таблицы очков (если функция рейтинга включена). ',
      'Add Results Manually': 'Добавить результаты вручную',
      instructionsPageTitle: 'Панель управления: инструкции',
      controlPanelDesc: 'Запускайте турнир шаг за шагом с этой панели управления.',
      General: 'Общий',
      'Dates & Location': 'Даты и место',
      Appearance: 'Появление',
      'Tournament Registration': 'Регистрация на турнир',
      Extras: 'Дополнительно',
      Management: 'Управление',
      'Fact Sheet': 'Информационный бюллетень',
      'Select League': 'Выберите лигу',
      'Select Categories': 'Выберите категории',
      'Payment methods': 'Способы оплаты',
      'Payment type': 'Тип платежа',
      Currency: 'Валюта',
      'Online Entry Fee': 'Плата за онлайн-вступительный взнос',
      'Entry Fee': 'Вступительный взнос',
      'Onsite Entry Fee': 'Плата за вход на месте',
      'Number of Rounds': 'Количество раундов',
      'Number of Courts': 'Количество судов',
      'Number of Stages': 'Количество этапов',
      'Tournament timezone': 'Часовой пояс турнира',
      'Registration Start Date': 'Дата начала регистрации',
      'Start Date': 'Дата начала',
      'End Date': 'Дата окончания',
      'Entry Deadline': 'Срок подачи заявок',
      'Withdrawal Deadline': 'Срок вывода средств',
      'Select Club': 'Выбрать клуб',
      'No clubs found in selected league': 'В выбранной лиге клубов не найдено',
      'Select Country': 'Выберите страну',
      City: 'Город',
      Address: 'Адрес',
      Logo: 'Логотип',
      logoDesc: 'Мы рекомендуем изображение размером 256 x 256 пикселей.',
      'Tournament title': 'Название турнира',
      'Enter tournament title': 'Введите название турнира',
      Description: 'Описание',
      tournamentDesc: 'Напишите описание турнира',
      'Video URL': 'URL-адрес видео',
      videoLink: 'Вставьте сюда ссылку на видео',
      tourWebUrl: 'URL-адрес веб-сайта турнира',
      'Tournament Poster': 'Плакат турнира',
      'Upload Poster': 'Загрузить плакат',
      'Cover Banner': 'Обложка баннера',
      bannerDesc: 'Мы рекомендуем изображение размером 2400 x 600 пикселей.',
      'Upload Banner': 'Загрузить баннер',
      Regulations: 'Правила',
      'Upload Regulations': 'Правила загрузки',
      'No Image': 'Нет изображения',
      dragDropDesc: 'Перетащите файл сюда или нажмите, чтобы выбрать файл.',
      dragDropDesc2: 'Перетащите файлы сюда или нажмите, чтобы выбрать файлы.',
      'Select File': 'Выберите файл',
      'Additional Questions': 'Дополнительные вопросы',
      'Add New Question': 'Добавить новый вопрос',
      'Terms and Conditions': 'Условия и положения',
      'Start Typing': 'Начните печатать',
      'External tournament registration link': 'Ссылка на регистрацию на внешний турнир',
      'Automatically confirm entries': 'Автоматически подтверждать записи',
      confirmEntriesInfo: 'Записи о регистрации на турнирах будут напрямую относиться к заявкам, а не к запросам.',
      closeRegistration: 'Закрыть регистрацию на все категории турниров',
      'Edit this question': 'Изменить этот вопрос',
      'Add new question': 'Добавить новый вопрос',
      'Question updated successfully!': 'Вопрос успешно обновлен!',
      'Question added successfully!': 'Вопрос успешно добавлен!',
      Question: 'Вопрос',
      'Field Type': 'Тип поля',
      Price: 'Цена',
      'Field Option': 'Опция поля',
      removeQuestionError: 'Для выбора типа поля требуется хотя бы 1 вариант поля.',
      Remove: 'Удалять',
      'Add new option': 'Добавить новую опцию',
      questionInfo: 'Задайте этот вопрос каждому спортсмену',
      Submit: 'Представлять на рассмотрение',
      isFeatured: 'Сделайте этот турнир популярным',
      partnerSearch: 'Включить поиск партнеров',
      enablePracticeCourtsBooking: 'Включить бронирование тренировочных кортов',
      showTournamentChessManagerCalendar: 'Показать этот турнир в календаре шахматного менеджера',
      localRatedTournament: 'Местный рейтинговый турнир',
      fideRatedTournament: 'Рейтинговый турнир ФИДЕ',
      enableTransferBooking: 'Включить бронирование трансфера',
      enableLiveStream: 'Включить прямую трансляцию (скоро)',
      enableLiveScoring: 'Включить подсчет очков в реальном времени (скоро)',
      'Select Organizer': 'Выберите Организатор',
      'No Organizer available': 'Нет органайзера',
      'Select main referee': 'Выбор главного судьи',
      'Select referee': 'Выберите судью',
      'No referee available': 'Нет доступного судьи',
      'Select additional referees': 'Выберите дополнительных судей',
      'Tournament status': 'Статус турнира',
      'Make tournament private': 'Сделать турнир закрытым',
      'Set Password': 'Установить пароль',
      'New Password': 'Новый пароль',
      'Confirm Password': 'Подтвердите пароль',
      'No facts found': 'Фактов не обнаружено',
      Save: 'Сохранять',
      Back: 'Назад',
      'Field Title': 'Название поля',
      'Dashboard: Stage Settings': 'Панель инструментов: настройки сцены',
      'Edit tournament': 'Редактировать турнир',
      'Change Settings': 'Изменить настройки',
      Title: 'Заголовок',
      Status: 'Статус',
      'General Limit': 'Общий лимит',
      'Individual metric limit': 'Индивидуальный предел показателей',
      'Allow participants to add results': 'Разрешить участникам добавлять результаты',
      'Attachments status': 'Статус вложений',
      'There is no available': 'Нет доступных',
      'Public for everyone': 'Публичный для всех',
      'Public only for participants': 'Публично только для участников',
      Hidden: 'Скрытый',
      'Hide other participant results': 'Скрыть результаты других участников',
      'Individual metric limit applied': 'Применен индивидуальный предел показателей',
      'Attachments Status': 'Статус вложений',
      'Public to participants only': 'Доступно только участникам',
      Saving: 'Сохранение',
      'General data': 'Общие данные',
      'Category settings': 'Настройки категории',
      'Dashboard: Category Settings': 'Панель управления: настройки категорий',
      'There is no available category!': 'Нет доступной категории!',
      Category: 'Категория',
      'Online Fee': 'Онлайн-плата',
      'Onsite Fee': 'Плата за выезд на место',
      'VAT Amount': 'Сумма НДС',
      'Payment Type': 'Тип оплаты',
      'Payment Method': 'Способ оплаты',
      Rounds: 'Раунды',
      Time: 'Время',
      'Time per move': 'Время за ход',
      'Time after move': 'Время после переезда',
      'Extra time': 'Дополнительное время',
      Pairing: 'Сопряжение',
      entries: 'записи',
      'Registration Status': 'Статус регистрации',
      'Additional service fee': 'Дополнительная плата за обслуживание',
      'Registration Limit': 'Лимит регистрации',
      Edit: 'Редактировать',
      'Registrations Limit': 'Лимит регистраций',
      'Include VAT amount?': 'Включить сумму НДС?',
      displayClubInfo: 'Отображать название спортсмена/командного клуба в деталях заявки.',
      Open: 'Открыть',
      Closed: 'Закрыто',
      categorySettingsInfo: 'Установите для выбранных категорий только те данные, которые вы хотите обновить.',
      'Select category color': 'Выберите цвет категории',
      'Dashboard: Requests': 'Панель управления: запросы',
      'No category found': 'Категория не найдена',
      'Add New Request': 'Добавить новый запрос',
      Actions: 'Действия',
      Confirming: 'Подтверждение',
      'Confirm Selected Requests': 'Подтвердить выбранные запросы',
      NR: 'НР',
      Team: 'Команда',
      Player: 'Игрок',
      Rating: 'Рейтинг',
      Email: 'Электронная почта',
      'Phone Number': 'Номер телефона',
      'Reg. time': 'Рег. ',
      Paid: 'Оплаченный',
      'ADDITIONAL QUESTIONS': 'ДОПОЛНИТЕЛЬНЫЕ ВОПРОСЫ',
      'Show Answer': 'Показать ответ',
      'Download requests list': 'Скачать список запросов',
      Decline: 'Отклонить',
      'Confirm Decline': 'Подтвердить отказ',
      requestDeclineWarning: 'Вы уверены, что хотите отклонить выбранные запросы?',
      'Copy Selected Requests': 'Копировать выбранные запросы',
      'No questions found': 'Вопросов не найдено',
      'Answer/Files': 'Ответ/Файлы',
      Download: 'Скачать',
      'Edit Entries successfully': 'Редактировать записи успешно',
      'Tournament data or category not available': 'Данные о турнире или категория недоступны.',
      'Please mark the Checkbox': 'Пожалуйста, отметьте флажок',
      'Confirming...': 'Подтверждаем...',
      'Confirmed successfully!': 'Подтверждено успешно!',
      'Please enter both player emails': 'Пожалуйста, введите адреса электронной почты обоих игроков',
      'Please enter player email': 'Пожалуйста, введите адрес электронной почты игрока',
      'Step 1: Select category': 'Шаг 1: Выберите категорию',
      'Athlete email (user account)': 'Электронная почта спортсмена (учетная запись пользователя)',
      'Player is not registered in the platform': 'Игрок не зарегистрирован на платформе',
      'First athlete email (user account)': 'Адрес электронной почты первого спортсмена (учетная запись пользователя)',
      'First player is not registered in the platform': 'Первый игрок не зарегистрирован на платформе',
      'Second athlete email (user account)': 'Электронная почта второго спортсмена (учетная запись пользователя)',
      'Second player is not registered in the platform': 'Второй игрок не зарегистрирован на платформе',
      Update: 'Обновлять',
      'No Team': 'Нет команды',
      'Athlete email is required': 'Требуется адрес электронной почты спортсмена',
      'First athlete email is required': 'Требуется адрес электронной почты первого спортсмена.',
      'Second athlete email is required': 'Требуется адрес электронной почты второго спортсмена.',
      'Add Entries successfully': 'Добавление записей успешно',
      'Select category': 'Выберите категорию',
      'No category exists': 'Категория не существует',
      Registration: 'Регистрация',
      Register: 'Зарегистрироваться',
      'There is no available requests!': 'Нет доступных запросов!',
      'Dashboard: Entries Data': 'Панель мониторинга: данные ввода',
      'Request Updated Successfully!': 'Запрос успешно обновлен!',
      'Import CSV File': 'Импортировать CSV-файл',
      'Import Entries': 'Импортировать записи',
      'Add New Entry': 'Добавить новую запись',
      'Entries deleted successfully.': 'Записи успешно удалены.',
      'Confirm Deletion': 'Подтвердить удаление',
      deleteEntriesWarning: 'Вы уверены, что хотите удалить выбранные записи?',
      'PLAYER 1': 'ИГРОК 1',
      'PLAYER 2': 'ИГРОК 2',
      Rank: 'Классифицировать',
      'Total Ranks': 'Всего рангов',
      Priority: 'Приоритет',
      Assign: 'Назначать',
      'Limit reached': 'Достигнут лимит',
      'Assigning...': 'Назначение...',
      'Removing...': 'Удаление...',
      'Moving...': 'Движущийся...',
      'Updated successfully': 'Обновлено успешно',
      'Please enter a value': 'Пожалуйста, введите значение',
      'Updating...': 'Обновление...',
      'Please enter a rating': 'Пожалуйста, введите оценку',
      'Edit Rating': 'Изменить рейтинг',
      'Please enter a rank': 'Пожалуйста, введите ранг',
      'Edit Rank': 'Изменить рейтинг',
      'Change Category': 'Изменить категорию',
      'Move selected entries to Requests': 'Переместить выбранные записи в Запросы',
      'Withdraw selected entries': 'Отозвать выбранные записи',
      'Delete rankings': 'Удалить рейтинги',
      'Update rankings & seeding': 'Обновление рейтингов и посева',
      'Download entry List': 'Скачать список записей',
      withdrawEntriesWarning: 'Вы уверены, что хотите отозвать выбранные записи?',
      'File uploaded & entries created successfully': 'Файл загружен и записи успешно созданы',
      'Please select a file to Upload': 'Пожалуйста, выберите файл для загрузки',
      'Import entries via CSV template': 'Импортировать записи через шаблон CSV',
      'Download CSV template for Singles format category': 'Скачать шаблон CSV для категории формата «Одиночные игры»',
      'CSV file Downloaded': 'CSV-файл загружен.',
      'Download CSV template for Doubles format category': 'Скачать CSV-шаблон для категории парного формата',
      'Upload & create entries': 'Загружайте и создавайте записи',
      'There is no available entries!': 'Нет доступных записей!',
      'Entry Fee is Required Field': 'Вступительный взнос является обязательным полем',
      'Type is Required Field': 'Тип – обязательное поле',
      'Payment Information Removed Successfully': 'Информация о платеже успешно удалена',
      'Paid Information Added Successfully': 'Платная информация успешно добавлена',
      'Update Paid information': 'Обновить платную информацию',
      'Paid information': 'Платная информация',
      'Amount of entry fee': 'Сумма вступительного взноса',
      Type: 'Тип',
      'Remove payment': 'Удалить платеж',
      'Drag and Drop a file here, or click to Select file': 'Перетащите файл сюда или нажмите, чтобы выбрать файл.',
      'Supported file': 'Поддерживаемый файл',
      'Dashboard: Withdrawals Data': 'Панель инструментов: данные о снятии средств',
      'Entries Deleted Successfully!': 'Записи успешно удалены!',
      'Deleting...': 'Удаление...',
      'There is no available withdrawals!': 'Доступного вывода средств нет!',
      deleteWithdrawalsWarning: 'Вы уверены, что хотите удалить выбранные записи?',
      'Move selected entries to Entry list': 'Переместить выбранные записи в список записей',
      'Dashboard: Groups Data': 'Панель инструментов: данные групп',
      'Back to tournament': 'Вернуться к турниру',
      'Submit results to Live standings': 'Публикация результатов в Live турнирной таблице',
      'Please select at least one group': 'Пожалуйста, выберите хотя бы одну группу',
      'Live standings submitted successfully': 'Текущая турнирная таблица успешно отправлена',
      'Submit Live Standings': 'Отправить турнирную таблицу в реальном времени',
      'Select Category': 'Выберите категорию',
      'Select Groups...': 'Выберите группы...',
      'Please select at least one Group.': 'Пожалуйста, выберите хотя бы одну группу.',
      'Standings Title': 'Титул турнирной таблицы',
      Sorting: 'Сортировка',
      'Removing groups...': 'Удаление групп...',
      'Groups removed successfully': 'Группы успешно удалены',
      Hiding: 'Скрытие',
      Publishing: 'Издательский',
      Group: 'Группа',
      Successfully: 'Успешно',
      Published: 'Опубликовано',
      Hide: 'Скрывать',
      Publish: 'Публиковать',
      'Updating scores...': 'Обновление результатов...',
      'Scores updated successfully': 'Результаты успешно обновлены',
      'Deleting group...': 'Удаление группы...',
      'Group Deleted successfully': 'Группа успешно удалена',
      'Deleting entries...': 'Удаление записей...',
      'Entries deleted successfully': 'Записи успешно удалены',
      'Delete Selected Entries': 'Удалить выбранные записи',
      'Delete Selected Teams': 'Удалить выбранные команды',
      'Publish this group': 'Опубликовать эту группу',
      'Hide this group': 'Скрыть эту группу',
      'Print/Download this group': 'Распечатать/скачать эту группу',
      Recover: 'Восстанавливаться',
      'this group': 'эта группа',
      'Add team to this group': 'Добавить команду в эту группу',
      'Add team': 'Добавить команду',
      'Update places for entries': 'Обновить места для записей',
      'Update Places': 'Обновить места',
      'Generate OOP For This Group Only': 'Создать ООП только для этой группы',
      'Create Matches for this group': 'Создать совпадения для этой группы',
      'Create Matches': 'Создать матчи',
      POINTS: 'ОЧКИ',
      SR: 'СР',
      PR: 'пиар',
      PLACE: 'МЕСТО',
      Place: 'Место',
      'Match Time Conflict': 'Конфликт времени матча',
      'Winner is required when score is provided': 'Победитель указывается при указании счета.',
      'Match cannot be unfinished when score is provided': 'Матч не может быть незавершенным, если указан счет.',
      'Space at the end is not allowed': 'Пробел в конце не допускается',
      'Please follow the pattern': 'Пожалуйста, следуйте шаблону',
      'Date is required Field': 'Дата является обязательным Поле',
      'Time is required Field': 'Необходимое время Поле',
      'Add Score Successfully': 'Добавление баллов успешно',
      'Select Winner': 'Выберите победителя',
      Athlete: 'Спортсмен',
      'Additional match status options': 'Дополнительные параметры статуса соответствия',
      Deselect: 'Отменить выбор',
      Date: 'Дата',
      'Start Time': 'Время начала',
      'Match Status': 'Статус матча',
      'Delete match from oop': 'Удалить совпадение из oop',
      'This Group is Deleted': 'Эта группа удалена',
      groupRecoverInfo: 'Если вы считаете, что группа была удалена по ошибке, вы можете восстановить ее, нажав кнопку «Восстановить», всего за 1 день.',
      'There is no available groups': 'Нет доступных групп',
      DATE: 'ДАТА',
      COURT: 'СУД',
      TEAM1: 'КОМАНДА1',
      TEAM2: 'КОМАНДА2',
      RESULT: 'РЕЗУЛЬТАТ',
      Court: 'Суд',
      Score: 'Счет',
      'Add Score': 'Добавить балл',
      'Delete group Match from oop': 'Удалить групповое совпадение из oop',
      'Edit Match': 'Редактировать матч',
      'Match Updated Successfully': 'Матч успешно обновлен',
      'Edit Match Date & Time': 'Изменить дату и время матча',
      'Match Date Updated Successfully': 'Дата матча успешно обновлена',
      'Date is Required Field': 'Дата является обязательным полем',
      'Time is Required Field': 'Время является обязательным полем',
      'Order of Play': 'Порядок игры',
      'Please select at least one court': 'Пожалуйста, выберите хотя бы один суд',
      'Generate OOP Successfully': 'Успешно сгенерируйте ООП',
      'Generate order of play for': 'Создать порядок игры для',
      'Number of teams': 'Количество команд',
      'Number of matches': 'Количество матчей',
      'Add Rounds...': 'Добавить раунды...',
      'Please select at least one Round.': 'Пожалуйста, выберите хотя бы один раунд.',
      Add: 'Добавлять',
      'Please select at least one.': 'Пожалуйста, выберите хотя бы один.',
      'Approximate match duration': 'Приблизительная продолжительность матча',
      'in minutes': 'в минутах',
      GENERATE: 'СОЗДАТЬ',
      'Group Edited Successfully': 'Группа успешно изменена',
      'Edit Group': 'Редактировать группу',
      'Group Name': 'Имя группы',
      'Display order of play under the group': 'Отображение порядка игры в группе',
      'Display list of matches under the group': 'Отображать список матчей под группой',
      Comment: 'Комментарий',
      'Group Name is Required Field': 'Имя группы является обязательным полем',
      'Entry is required': 'Требуется вход',
      'Adding Team...': 'Добавление команды...',
      'Team Added Successfully': 'Команда успешно добавлена',
      'Add team to group': 'Добавить команду в группу',
      'OOP generated successfully': 'ООП сгенерировано успешно',
      'Matches created successfully': 'Матчи успешно созданы',
      'Generate OOP': 'Создать ООП',
      Duration: 'Продолжительность',
      'No courts available': 'Нет доступных судов',
      'Update Entry': 'Обновить запись',
      'Update Entry successfully': 'Обновить запись успешно',
      Cancel: 'Отмена',
      deleteGroupWarning: 'Вы уверены, что хотите удалить эту группу?',
      deleteGroupEntryWarning: 'Вы уверены, что хотите удалить эту запись?',
      deleteGroupEntriesWarning: 'Вы уверены, что хотите удалить эти записи?',
      'Groups deleted successfully.': 'Группы успешно удалены.',
      'Edit Selected Groups': 'Редактировать выбранные группы',
      'Delete Selected Groups': 'Удалить выбранные группы',
      deleteGroupsWarning: 'Вы уверены, что хотите удалить выбранные группы?',
      'Select Entries': 'Выберите записи',
      'PDF being generated...': 'PDF создается...',
      'Failed to generate PDF': 'Не удалось создать PDF-файл.',
      'Generating PDF...': 'Создание PDF-файла...',
      'Please wait...': 'Пожалуйста, подождите...',
      'All groups Published successfully': 'Все группы Опубликовано успешно',
      'All groups Unpublished successfully': 'Все группы Опубликовано успешно',
      'PUBLISH GROUPS': 'ПУБЛИКОВАТЬ ГРУППЫ',
      'HIDE GROUPS': 'СКРЫТЬ ГРУППЫ',
      'No segment found': 'Сегмент не найден',
      'All Segments': 'Все сегменты',
      'All Categories': 'Все категории',
      'Generate OOP for all groups': 'Сгенерировать ООП для всех групп',
      'Add Group': 'Добавить группу',
      'Download PDF': 'Скачать PDF',
      'Export matches for DUPR': 'Экспорт совпадений для DUPR',
      'Publish All Groups': 'Опубликовать все группы',
      'Unpublish All Groups': 'Отменить публикацию всех групп',
      'Please select at least one Court.': 'Пожалуйста, выберите хотя бы один суд.',
      'Select Groups': 'Выберите группы',
      'All Groups': 'Все группы',
      'Match duration is Required Field': 'Продолжительность матча Обязательное поле',
      'Round is Required Field': 'Раунд является обязательным полем',
      'Courts is Required Field': 'Суды – обязательное поле',
      'Print/Download All group': 'Распечатать/Скачать всю группу',
      'Category is Required Field': 'Категория обязательное поле',
      'Segment is a Required Field': 'Сегмент — обязательное поле',
      'Group Type is Required Field': 'Тип группы является обязательным полем',
      'Groups Name is Required Field': 'Имя группы является обязательным полем',
      'Groups Naming is Required Field': 'Имя группы является обязательным полем',
      'Please enter a 2-digit number.': 'Пожалуйста, введите двузначный номер.',
      'No of groups is Required Field': 'Количество групп Обязательное поле',
      'Algorithm is Required Field': 'Алгоритм является обязательным полем',
      'Generate Groups is Required Field': 'Создать группы — обязательное поле',
      'Add Group Successfully': 'Добавить группу успешно',
      'Add Groups': 'Добавить группы',
      'Select segment': 'Выберите сегмент',
      'Groups type': 'Тип группы',
      'Number of groups': 'Количество групп',
      'Groups naming': 'Именование групп',
      'Select Round': 'Выберите раунд',
      'Generate Groups': 'Создание групп',
      'Select Algorithm': 'Выберите алгоритм',
      'Tournament copied successfully': 'Турнир успешно скопирован',
      'Title is required': 'Требуется название',
      'Start date is required': 'Укажите дату начала.',
      'End date cannot be before start date': 'Дата окончания не может быть раньше даты начала.',
      'Entry deadline is required': 'Требуется крайний срок подачи заявки',
      'Withdrawl deadline is required': 'Требуется срок вывода средств',
      'Dashboard: Partners': 'Панель управления: Партнеры',
      'Add New Partners': 'Добавить новых партнеров',
      'No Partners data!': 'Нет данных о партнерах!',
      'Showing dates': 'Показаны даты',
      Text: 'Текст',
      Link: 'Связь',
      'Dashboard: Documents Data': 'Панель инструментов: данные документов',
      'League Regulations': 'Регламент Лиги',
      'No regulations uploaded yet': 'Правила еще не загружены',
      'Tournament Regulations': 'Регламент турнира',
      'Uploading...': 'Загрузка...',
      'Regulations uploaded successfully': 'Правила успешно загружены',
      'Error uploading regulations': 'Ошибка при загрузке правил.',
      'Dashboard: Participants Data': 'Панель инструментов: данные участников',
      'Status: ': 'Статус: ',
      'Show All': 'Показать все',
      'No status found': 'Статус не найден',
      'Export User Emails': 'Экспортировать электронные письма пользователей',
      'Export Users': 'Экспорт пользователей',
      'PLAYER NAME': 'ИМЯ ИГРОКА',
      COUNTRY: 'СТРАНА',
      'There is no participants data!': 'Данных об участниках нет!',
      'Dashboard: Final Standings': 'Панель мониторинга: итоговые результаты',
      'Add Final Results Manually': 'Добавить окончательные результаты вручную',
      'There is no available tournament results': 'Нет доступных результатов турнира',
      'Final results added successfully': 'Окончательные результаты успешно добавлены',
      Points: 'Очки',
      'Select place': 'Выберите место',
      'Result deleted successfully': 'Результат успешно удален',
      'Duplicate entries & assign points to another category': 'Дублируйте записи и перенесите баллы в другую категорию',
      'Duplicate & Assign': 'Дублировать и назначить',
      'Hide Results': 'Скрыть результаты',
      'Publish Results': 'Публикация результатов',
      'Delete selected entries': 'Удалить выбранные записи',
      'Delete results block': 'Удалить блок результатов',
      Entry: 'Вход',
      'Download List': 'Скачать список',
      'Add new result': 'Добавить новый результат',
      'Entry Deleted Successfully': 'Запись успешно удалена',
      'Points is required': 'Требуется баллы',
      'Place is required': 'Место обязательно',
      'Please select a entry': 'Пожалуйста, выберите запись',
      'Updated Successfully': 'Обновлено успешно',
      'Edit Entry': 'Редактировать запись',
      'Delete Entry': 'Удалить запись',
      deleteRankingEntryWarning: 'Вы уверены, что хотите удалить эту запись?',
      'Select Pair': 'Выберите пару',
      'Select Player': 'Выберите игрока',
      'No ranking points found': 'Рейтинговые баллы не найдены',
      'Select Ranking Point block': 'Выберите блок рейтинговых очков',
      'To Category': 'В категорию',
      'From Category': 'Из категории',
      'Duplicate entries & points to another category': 'Повторяющиеся записи и ссылки на другую категорию',
      'Entries & points copied successfully': 'Записи и баллы успешно скопированы',
      'From Category is required': 'Из категории требуется',
      'To Category is required': 'В категорию обязательно',
      'Ranking Point is required': 'Требуется рейтинговое очко',
      deleteResultsWarning: 'Вы уверены, что хотите удалить эти результаты?',
      'Dashboard: Live Standings Data': 'Панель инструментов: данные о турнирной таблице в реальном времени',
      'Segment: ': 'Сегмент: ',
      'No segments found': 'Сегменты не найдены',
      'Add Live Standings': 'Добавить турнирную таблицу в реальном времени',
      'Submit final Results': 'Отправить окончательные результаты',
      'There is no available live standings': 'Нет доступной турнирной таблицы в реальном времени',
      'Groups is Required Field': 'Группы – обязательное поле',
      'Standings Title is Required Field': 'Название турнирной таблицы является обязательным полем',
      'Sorting is Required Field': 'Сортировка является обязательным полем',
      'Live standings added successfully': 'Текущая турнирная таблица успешно добавлена',
      'Live Standing is Required Field': 'Живое положение является обязательным полем',
      'Results added successfully': 'Результаты успешно добавлены',
      'Submit Results to Rankings': 'Отправьте результаты в рейтинги',
      'Select Live Standing': 'Выберите живую стойку',
      'No Live Standings found': 'Живая турнирная таблица не найдена',
      'Deleting live standings...': 'Удаление турнирной таблицы...',
      'Live standings deleted successfully.': 'Текущая турнирная таблица успешно удалена.',
      'Delete Selected Live Standings': 'Удалить выбранную турнирную таблицу в реальном времени',
      deleteLivesStandingsWarning: 'Вы уверены, что хотите удалить выбранную турнирную таблицу в реальном времени?',
      'Generating CSV...': 'Генерация CSV...',
      'CSV being generated...': 'CSV генерируется...',
      'Failed to generate CSV': 'Не удалось создать CSV.',
      'Dashboard: Order Of Play': 'Панель управления: порядок игры',
      Grid: 'Сетка',
      List: 'Список',
      "Bird's View": 'с высоты птичьего полета',
      'Hide Order of Play': 'Скрыть порядок игры',
      'Publish Order of Play': 'Опубликовать порядок игры',
      'Hide OOP': 'Скрыть ООП',
      'Publish OOP': 'Опубликовать ООП',
      'Enter Name': 'Введите имя',
      'There is no available order of play': 'Нет доступного порядка игры',
      'Export OOP for DUPR': 'Экспорт ООП для DUPR',
      'Deleting Matches...': 'Удаление матчей...',
      'Matches Deleted Successfully!': 'Матчи успешно удалены!',
      'Delete Selected Matches': 'Удалить выбранные совпадения',
      deleteMatchesWarning: 'Вы уверены, что хотите удалить выбранные совпадения?',
      'Score is not set': 'Оценка не установлена',
      'TEAM 1': 'КОМАНДА 1',
      'TEAM 2': 'КОМАНДА 2',
      RESULTS: 'РЕЗУЛЬТАТЫ',
      'Delete Match Successfully': 'Удалить совпадение успешно',
      'Court is required': 'Требуется суд',
      'Edit Score Successfully': 'Редактировать партитуру успешно',
      'Edit Draw Match': 'Изменить ничью',
      'Optional match details': 'Дополнительные сведения о матче',
      'Delete match': 'Удалить совпадение',
      'Confirm Match Deletion': 'Подтвердить удаление матча',
      deleteDrawMatchWarning: 'Вы уверены, что хотите удалить это совпадение? ',
      'Add score instructions': 'Добавить инструкции по оценке',
      scoreInstructionsInfo1: 'Установите счет в пустое поле или выберите для победителя матча значение «Матч не завершен», и статус матча будет автоматически изменен на «Предстоящий».',
      scoreInstructionsInfo2: '*Игры должны быть разделены символом «:» (двоеточием). ',
      scoreInstructionsInfo3: '**Тай-брейк (если он состоялся) должен быть заключен в скобки «()» и идти после гейма без пробела. ',
      scoreInstructionsInfo4: '***Решающий тай-брейк (если он был сыгран) должен быть заключен в квадратные скобки «[]». ',
      'Edit Match Successfully': 'Редактировать матч успешно',
      'Edit Group Match': 'Редактировать групповой матч',
      'Dashboard: Draw': 'Панель управления: Рисование',
      'Draw is Required Field': 'Ничья – обязательное поле',
      'Please add points to submit': 'Пожалуйста, добавьте баллы для отправки',
      'Select Draw': 'Выберите «Нарисовать».',
      'No Draws found': 'Ничьих не найдено',
      'All draws': 'Все розыгрыши',
      'Publish Draws': 'Публикация розыгрышей',
      'Hide Draws': 'Скрыть розыгрыши',
      'Generate Order Of Play': 'Создать порядок игры',
      'Add new Draw': 'Добавить новый розыгрыш',
      'Draw templates is Required Field': 'Шаблоны рисунков – обязательное поле',
      'Draw title is Required Field': 'Название розыгрыша обязательно для заполнения.',
      'Generate draw is Required Field': 'Создать розыгрыш – обязательное поле',
      'Live Standings is Required Field': 'Текущая турнирная таблица является обязательным полем',
      'Start Live Standings is Required Field': 'Начать прямую трансляцию Это обязательное поле',
      'End Live Standings is Required Field': 'Завершить турнирную таблицу в реальном времени – обязательное поле',
      'Draw type is Required Field': 'Тип розыгрыша Обязательное поле',
      'Draw segment is Required Field': 'Сегмент рисования является обязательным полем',
      'Qual winners is Required Field': 'Победители квалификации – обязательное поле',
      'No of seeds is Required Field': 'Количество семян является обязательным полем',
      'Regenerate Draw Successfully': 'Регенерировать рисунок успешно',
      'Add Draw Successfully': 'Добавить розыгрыш успешно',
      'Draw title': 'Название рисунка',
      'Draw segment': 'Нарисовать сегмент',
      'Templates size': 'Размер шаблонов',
      'Add Match for 3rd Place': 'Добавить матч за 3-е место',
      'Qual Winners': 'Победители квалификации',
      'Generate Draw': 'Создать розыгрыш',
      'Main Draw': 'Основная сетка',
      'No Main Draw found': 'Основная сетка не найдена',
      'Main Draw Entries': 'Заявки на основную сетку',
      'Select Live Standings': 'Выберите турнирную таблицу в реальном времени',
      'Loading...': 'Загрузка...',
      'Live Standings from': 'Текущее положение из',
      'Live Standings to': 'Живая турнирная таблица',
      'Algo Type': 'Тип алгоритма',
      'Regenerate Draw': 'Регенерировать',
      'Add Draw': 'Добавить розыгрыш',
      deleteDrawWarning: 'Все записи и совпадения розыгрышей будут удалены, а розыгрыш будет сгенерирован заново. ',
      Warning: 'Предупреждение',
      Confirm: 'Подтверждать',
      'Generate order of play Successfully': 'Создать порядок игры успешно',
      'Generate order of play': 'Создать порядок игры',
      'Select Draws': 'Выберите розыгрыши',
      'Select Rounds': 'Выберите раунды',
      'Time Settings': 'Настройки времени',
      Select: 'Выбирать',
      'PDF Generated Successfully!': 'PDF-файл успешно создан!',
      'Publishing draw...': 'Публикация розыгрыша...',
      'Hiding draw...': 'Скрытие розыгрыша...',
      'Successfully!': 'Успешно!',
      'Regenerate this draw': 'Восстановить этот рисунок',
      editDrawNameInfo: 'Если вы хотите изменить размер рисунка или любую другую настройку рисования, вам придется удалить рисунок и снова добавить новый.',
      'Draw Name': 'Имя розыгрыша',
      'Edit Draw': 'Редактировать розыгрыш',
      'Draw Name Edited Successfully': 'Имя рисунка успешно изменено',
      'Draw Name is required': 'Требуется имя розыгрыша',
      'Type is required': 'Тип обязателен',
      'Publish this draw': 'Опубликовать этот розыгрыш',
      'Hide this draw': 'Скрыть этот розыгрыш',
      'There is no available draws': 'Нет доступных розыгрышей',
      'Draws deleted successfully.': 'Розыгрыши успешно удалены.',
      'Delete Selected Draws': 'Удалить выбранные розыгрыши',
      deleteDrawsWarning: 'Вы уверены, что хотите удалить выбранные розыгрыши?',
      deleteDrawEntryWarning: 'Вы уверены, что хотите удалить эту запись?',
      'Select a Entry': 'Выберите запись',
      'Edit team One': 'Редактировать первую команду',
      'Edit team Two': 'Редактировать вторую команду',
      'Team Edited Successfully': 'Команда успешно отредактирована',
      'Deleting Entry...': 'Удаление записи...',
      'Add team One to this Match': 'Добавить первую команду в этот матч',
      'Add team Two to this Match': 'Добавить вторую команду в этот матч',
      'Team Added to Seed Successfully': 'Команда успешно добавлена ​​в начальное число',
      'Score is Required Field': 'Оценка является обязательным полем',
      'Match Score Added Successfully': 'Счет матча успешно добавлен',
      'Match score': 'Счет матча',
      'Edit score': 'Изменить счет',
      'All Draws are hidden': 'Все розыгрыши скрыты',
      Upload: 'Загрузить',
      'Upload File': 'Загрузить файл',
      'Upload File in OOP': 'Загрузить файл в ООП',
      'Upload Document': 'Загрузить документ',
      'Document Name': 'Название документа',
      'Name is required': 'Требуется имя',
      'Are you sure you want to delete this document?': 'Вы уверены, что хотите удалить этот документ?',
      deleteGroupMatchWarning: 'Вы уверены, что хотите удалить это совпадение?',
      deleteMultipleResultsWarning: 'Вы уверены, что хотите удалить эти результаты?',
      'How to Set Up a Tournament Draw?': 'Как организовать розыгрыш турнира?',
      setupTournament1: 'После того, как вы указали категорию и название розыгрыша, выполните следующие действия, чтобы настроить розыгрыш турнира:',
      'Select the Segment:': 'Выберите сегмент:',
      'Choose from': 'Выберите из',
      'Select the Draw Type:': 'Выберите тип розыгрыша:',
      'Single Elimination:': 'Одиночное выбывание:',
      'Participants are eliminated after their first loss': 'Участники выбывают после первого поражения.',
      'Double Elimination:': 'Двойное выбывание:',
      'Participants are eliminated after two losses': 'Участники выбывают после двух поражений',
      'Generate the Draw:': 'Создайте розыгрыш:',
      'Choose the source for generating the draw:': 'Выберите источник для создания розыгрыша:',
      'Use the list of registered participants': 'Воспользоваться списком зарегистрированных участников',
      'Use standings based on group results': 'Используйте турнирную таблицу на основе групповых результатов',
      setupTournament2: 'Создайте пустой розыгрыш и вручную добавьте участников на позиции.',
      setupTournament3: 'выбран, у вас появится дополнительная возможность генерировать розыгрыш из',
      setupTournament4: 'В этом случае вам необходимо выбрать рисунок, из которого будет производиться генерация.',
      setupTournament5: 'Система автоматически выберет участников, проигравших свой первый матч, а судьи смогут при необходимости скорректировать выбор.',
      setupTournament6: 'Установите алгоритм генерации (если источник выбран не вручную):',
      setupTournament7: 'Соблюдает правила международной федерации, включая посев и рандомизацию.',
      setupTournament8: 'Например, при розыгрыше 16 участников первый посевной играет против 16-го посевного, второй посевной - против 15-го посевного и так далее.',
      'How to Generate the Order of Play?': 'Как создать порядок игры?',
      'To generate the Order of Play automatically': 'Автоматическое создание порядка игры',
      Click: 'Нажмите',
      'in the draw navigation.': 'в навигации по рисованию.',
      'Select the specific': 'Выберите конкретный',
      'on which the matches will be set': 'на котором будут проводиться матчи',
      'Specify the approximate duration of each match': 'Укажите примерную продолжительность каждого матча',
      setupTournament9: 'После создания совпадений вы можете настроить детали в трех разных представлениях.',
      'Sorted by match time': 'Сортировка по времени матча',
      setupTournament10: 'Суды в виде названий столбцов с совпадениями, указанными в соответствующем суде.',
      setupTournament11: 'Отображает только названия категорий и цвета, чтобы обеспечить обзор порядка игры.',
      setupTournament12: 'Если вам нужно добавить детали совпадений вручную, нажмите кнопку',
      setupTournament13: 'под матчем и укажите все необходимые детали',
      'How to Enter Results?': 'Как ввести результаты?',
      'To enter match results': 'Для ввода результатов матча',
      setupOOP1: 'Выберите матч, который хотите обновить (внутри жеребьевки или порядка игры).',
      'Set the winner and the score': 'Установите победителя и счет',
      'Ensure you format the score correctly': 'Убедитесь, что вы правильно отформатировали партитуру',
      "Always start with the winner's score": 'Всегда начинайте со счета победителя',
      'Separate sets with a space': 'Отдельные комплекты с пробелом',
      'Separate games within a set with a colon': 'Отдельные игры внутри набора через двоеточие',
      'Indicate a set tiebreak in parentheses without a space after the set': 'Тай-брейк сета указывается в скобках без пробела после сета.',
      'Indicate a super tiebreak set in square brackets with a space before it': 'Укажите супертай-брейк в квадратных скобках с пробелом перед ним.',
      Example: 'Пример',
      'Draws are not available to the public until you click': 'Розыгрыши недоступны для публики, пока вы не нажмете',
      setupDraw1: 'Вы можете опубликовать все розыгрыши сразу или по отдельности. ',
      'Delete Draws': 'Удалить розыгрыши',
      'To delete a draw': 'Чтобы удалить розыгрыш',
      'Check the checkbox next to the draw title': 'Установите флажок рядом с названием розыгрыша.',
      'Automatically Submit Final Results (Place / Points)': 'Автоматически отправлять окончательные результаты (место/очки)',
      'To submit final results automatically, follow these steps': 'Чтобы автоматически представить окончательные результаты, выполните следующие действия.',
      setupDraw2: 'В разделе «Жеребьевки» найдите и нажмите кнопку «Отправить окончательные результаты» в правом верхнем углу.',
      'Choose the draw from which you want to get the positions': 'Выберите розыгрыш, из которого вы хотите получить позиции',
      setupDraw3: 'Проверьте позиции, чтобы убедиться, что все правильно. ',
      'Alternatively - Submit Final Results Manually on the "Final Standings" page': 'Альтернативный вариант: отправьте окончательные результаты вручную на странице «Итоговые результаты».',
      drawExtra1: 'Для отображения названия клуба участников и распределения участников клуба по розыгрышу установите соответствующие флажки',
      'You can download and print the draw': 'Вы можете скачать и распечатать розыгрыш',
      'For pickleball, you can export matches in the correct format for DUPR': 'Для пиклбола вы можете экспортировать матчи в правильном для DUPR формате.',
      wildCardQuestion: 'Как точно подсчитать количество заявок на участие в основной сетке, квалификации, посеве и wild card (WC)?',
      wildCardAnswer:
        'Чтобы точно подсчитать количество заявок на участие в основной сетке, квалификации, посеве и wild card (WC), убедитесь, что вы правильно настроили настройки категории турнира. ',
      wildCardAnswer1: 'Настройки > Настройки категории.',
      wildCardAnswer2: 'Там же вы можете настроить принимаемые способы оплаты, вступительные взносы, лимиты регистрации и многое другое.',
      'How to auto confirm entries?': 'Как автоматически подтвердить записи?',
      confirmEntries1: 'Для автоматического подтверждения записей после регистрации или оплаты перейдите по ссылке',
      confirmEntries2: 'Настройки турнира > Регистрация на турнир',
      'and check the box for': 'и установите флажок',
      'Automatically Confirm Entries': 'Автоматически подтверждать записи',
      'Add manual tournament results': 'Добавить результаты турнира вручную',
      results1: 'Чтобы вручную добавить результаты турнира (итоговое положение), нажмите кнопку',
      'Add Final Standings Manually': 'Добавить итоговое положение вручную',
      results2: 'кнопка. ',
      results3: 'Чтобы места и точки отображались корректно, убедитесь, что они заданы в',
      results4: 'Панель управления турниром -> Лига -> раздел «Рейтинговые очки»',
      oop1: 'Чтобы сделать порядок игры (ООП) общедоступным, вам необходимо опубликовать его в навигации по порядку игры.',
      'Edit / Delete Multiple Matches': 'Редактировать/удалять несколько совпадений',
      oop2: 'Выберите несколько совпадений, чтобы редактировать или удалять их одновременно.',
      'You can upload documents related to OOP': 'Вы можете загружать документы, связанные с ООП.',
      'You can download and print matches': 'Вы можете скачать и распечатать матчи',
      oopExtra: 'Для пиклбола вы можете экспортировать матчи в формате, подходящем для DUPR.',
      "How to Update Participants' Rankings, Seeding, and Segmentation?": 'Как обновить рейтинги, распределение и сегментацию участников?',
      'To update rankings, segmentation, or seeding': 'Чтобы обновить рейтинги, сегментацию или распределение',
      'Click the': 'Нажмите кнопку',
      'button (represented by a three-dot icon) located next to the': 'кнопка (представленная трехточечным значком), расположенная рядом с',
      button: 'кнопка',
      'from the dropdown menu': 'из выпадающего меню',
      'Segmentation Explained': 'Объяснение сегментации',
      "To ensure participants' rankings are indicated properly": 'Для обеспечения правильного указания рейтингов участников.',
      entries1: 'Убедитесь, что менеджер лиги правильно настроил категории турниров в',
      'This can be found under': 'Это можно найти под',
      'League -> Tournament Categories.': 'Лига -> Категории турниров.',
      entries2: 'Убедитесь, что категории турниров связаны с правильной категорией рейтинга.',
      entries3: 'Если вы считаете, что рейтинги не обновляются должным образом, убедитесь, что категории рейтинга установлены в соответствии с правилами ранжирования на панели Tournated Dashboard. ',
      Note: 'Примечание',
      'How to Update or Withdraw Entries and Assign Wild Cards (WCs)?': 'Как обновить или отозвать заявки и назначить подстановочные знаки (WC)?',
      'To Add a New Entry': 'Чтобы добавить новую запись',
      "By default, you need to provide the player's email.": 'По умолчанию вам необходимо указать адрес электронной почты игрока.',
      entries4: 'Если в вашей организации включены лицензии, вы можете выбрать спортсменов, у которых есть активная лицензия.',
      'To Withdraw an Entry': 'Отозвать запись',
      'Select the entries you want to withdraw.': 'Выберите записи, которые вы хотите отозвать.',
      'and then choose': 'а затем выбери',
      'To Assign a Wild Card (WC)': 'Чтобы назначить Wild Card (WC)',
      'Next to the entry, click the three-dot icon and select': 'Рядом с записью щелкните трехточечный значок и выберите',
      entries5: 'Убедитесь, что количество туалетов установлено в настройках категории; ',
      'Where can I find participant details?': 'Где я могу найти информацию об участниках?',
      'You can find participant details in two ways': 'Информацию об участниках можно найти двумя способами.',
      'From the Entry List': 'Из списка участников',
      entries6: 'Нажмите на имя участника прямо в списке участников, чтобы просмотреть его подробную информацию.',
      'From the Tournament Data Section': 'Из раздела турнирных данных',
      entries7: 'Перейдите в «Данные турнира» -> «Участники» на левой боковой панели, чтобы просмотреть подробную информацию обо всех участниках.',
      entries8: 'Кроме того, у вас есть возможность экспортировать все сведения об участниках или их электронные письма отдельно из раздела «Участники».',
      'How to Add Groups?': 'Как добавить группы?',
      'To add groups, follow these steps': 'Чтобы добавить группы, выполните следующие действия.',
      'Navigate to the "Groups" section and click the "Add Groups" button.': 'Перейдите в раздел «Группы» и нажмите кнопку «Добавить группы».',
      'Select a Category': 'Выберите категорию',
      'Choose the category for which you want to add groups.': 'Выберите категорию, для которой вы хотите добавить группы.',
      'Set Number of Groups and Naming Type': 'Установите количество групп и тип именования',
      'Specify the number of groups you want to create.': 'Укажите количество групп, которые вы хотите создать.',
      'Choose the naming convention for the groups': 'Выберите соглашение об именах для групп.',
      'Select the Group Type': 'Выберите тип группы',
      'Used when athletes or teams are competing against each other': 'Используется, когда спортсмены или команды соревнуются друг с другом.',
      'Used when single athletes are playing doubles with each other.': 'Используется, когда одиночные спортсмены играют друг с другом в парном разряде.',
      'Standard Format Options': 'Стандартные параметры формата',
      groups1: 'Если вы выберете стандартный формат, решите, создавать ли участников группы автоматически или вручную.',
      'Select an algorithm': 'Выберите алгоритм',
      'Based on international rules.': 'На основе международных правил.',
      groups2: 'Например, если есть 3 группы и 9 участников, в группу 1 будут входить игроки с 1-м, 6-м и 7-м местами; ',
      groups3:
        'Добавьте команды в каждую группу, и как только вы закончите, обязательно нажмите кнопку «Создать матчи», расположенную рядом с названием каждой группы (этот шаг будет автоматизирован в следующем выпуске).',
      'King Format Options': 'Параметры формата King',
      'If you select the King format, choose the': 'Если вы выберете формат King, выберите',
      'Display List of Matches': 'Отобразить список матчей',
      groups4: 'Чтобы отобразить список совпадений в каждой группе, установите флажок с надписью',
      'To generate the Order of Play (OOP)': 'Для создания порядка игры (ООП)',
      'Choose the Scope': 'Выберите область применения',
      groups5: 'Решите, хотите ли вы сгенерировать ООП для всех групп одновременно (находится в навигации «Группы») или для каждой группы отдельно (находится рядом с названием каждой группы).',
      'Generate the OOP': 'Создайте ООП',
      'Click the appropriate button.': 'Нажмите соответствующую кнопку.',
      'Select the rounds for which you want to generate the OOP.': 'Выберите раунды, для которых вы хотите сгенерировать ООП.',
      'Set the date and time for the first match.': 'Установите дату и время первого матча.',
      'Enter the estimated duration for the matches.': 'Введите предполагаемую продолжительность матчей.',
      'Adjust Matches': 'Корректировать совпадения',
      'You can also create matches manually and make adjustments after the OOP is generated.': 'Вы также можете создавать совпадения вручную и вносить изменения после создания ООП.',
      'Select the Match': 'Выберите матч',
      groups6: 'Выберите матч, который хотите обновить, либо внутри группы, либо в порядке игры.',
      'Enter the Winner and Score': 'Введите победителя и наберите очки',
      'Set the winner of the match.': 'Установите победителя матча.',
      'Format the score as follows': 'Отформатируйте партитуру следующим образом',
      "Start with the winner's score.": 'Начните со счета победителя.',
      'Separate sets with a space.': 'Отдельные комплекты с пробелом.',
      'Use parentheses for a set tiebreak (no space after the set)': 'Используйте круглые скобки для тай-брейка сета (без пробела после сета)',
      'Use square brackets for a super tiebreak (with a space before it)': 'Используйте квадратные скобки для супертай-брейка (с пробелом перед ним)',
      'How to Generate the Final Round of King Format?': 'Как создать финальный раунд формата King?',
      'To generate the final round in King format': 'Для создания финального раунда в формате King',
      'Create New Groups': 'Создать новые группы',
      'Set up new groups and select the "King" format.': 'Настройте новые группы и выберите формат «Король».',
      'Select the Round': 'Выберите раунд',
      'Choose "Final" as the round type.': 'В качестве типа раунда выберите «Финальный».',
      'Automatic Sorting': 'Автоматическая сортировка',
      groups7: 'Платформа автоматически будет использовать групповые места и соотношения для сортировки участников финального раунда.',
      'You can download and print groups.': 'Вы можете скачать и распечатать группы.',
      'For pickleball, you can export matches in the correct format for DUPR.': 'Для пиклбола вы можете экспортировать матчи в формате, подходящем для DUPR.',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
