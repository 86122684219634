import { gql } from 'src/__generated__/gql';

const ENTRIES = gql(`
  query tournamentRegistrations($filter: ListTournamentRegistrationInput) {
    tournamentRegistrations(filter: $filter) {
      id
      type
      status
      rankType
      createdAt
      assignedSegment
      additionalSegment
      seed
      amount
      segment
      paymentMethod
      categoryId {
        mdwc
        qwc
        qs
      }
      team {
        title
        country
        image
      }
      users {
        id
        fideTitle
        rank
        blitzElo
        standardElo
        rapidElo
        duprRating
        user {
          rank
          id
          name
          phone
          surname
          avatar
          nation
          citizenship
          email
          gender
          dob
          duprId
        }
      }
    }
  }
`);

export default ENTRIES;
