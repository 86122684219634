import { gql } from 'src/__generated__/gql';

const CREATE_DRAW_FROM_GROUPS = gql(`
  mutation createDrawFromGroups($tournament: Int!, $tournamentCategory: Int, $size: Int, $numberOfEntries: Int, $title: String, $entriesPosition: String, $finalDraws: Boolean, $groupIds: [Int!]!, $genDraw: String) {
    createDrawFromGroups(
      createDrawInput: {
        tournament: $tournament
        tournamentCategory: $tournamentCategory
        size: $size
        numberOfEntries: $numberOfEntries
        title: $title
        entriesPosition: $entriesPosition
        finalDraws: $finalDraws
        genDraw: $genDraw
      }
      groupIds: $groupIds
    ) {
      id
      title
    }
  }
`);

export default CREATE_DRAW_FROM_GROUPS;
