import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetFlag from 'src/components/shared/GetFlag';
import CustomSelect from '../CustomComponents/CustomSelect';

const languageOptions = {
  en: { icon: 'united kingdom', label: 'English', value: 'English' },
  la: { icon: 'latvia', label: 'Latviešu', value: 'Latvian' },
  // eng: { icon: '/images/flag-uk.svg', label: 'English', value: 'English' },
  esp: { icon: 'spain', label: 'Español', value: 'Spanish' },
  ru: { icon: 'russia', label: 'Русский', value: 'Russia' },
  // pt: { icon: 'portugal', label: 'Português', value: 'Portuguese' },
  // ja: { icon: 'japan', label: '日本語', value: 'Japanese' },
  // el: { icon: 'greece', label: 'Ελληνικά', value: 'English' },
  // ee: { icon: 'estonia', label: 'eesti keel', value: 'Estonian' },
  // lt: { icon: 'lithuania', label: 'lietuvių', value: 'English' },
  // fr: { icon: 'france', label: 'Français', value: 'French' },
  pl: { icon: 'poland', label: 'Polski', value: 'Polish (Polski)' },
  ka: { icon: 'Georgia', label: 'Georgian', value: 'Georgian' },
};

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const selectedLang = Cookies.get('lang') ?? null;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    setOpen(false);
    Cookies.set('lang', lang);
  };

  useEffect(() => {
    if (selectedLang) {
      i18n.changeLanguage(selectedLang);
    }
  }, [selectedLang, i18n]);

  const selectedOption = languageOptions[i18n.language];

  return (
    <CustomSelect
      placeholder={t('Language')}
      selectedValue={i18n.language}
      options={Object.keys(languageOptions)?.map((language) => ({
        value: language,
        leftIcon: (
          <GetFlag
            country={languageOptions[language]?.icon}
            style={{
              width: '15px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '2px',
            }}
          />
        ),
        title: languageOptions[language]?.label,
      }))}
      onChange={(value) => handleChangeLanguage(value?.toString())}
      buttonProps={{
        variant: 'transparent',
      }}
    />
  );
};

export default LanguagePopover;
