import { gql } from '@apollo/client';

const UPDATE_TOURNAMENT = gql`
  mutation updateTournament(
    $id: Float!
    $tournamentId: Float
    $title: String
    $startDate: DateTime
    $endDate: DateTime
    $country: String
    $city: String
    $address: String
    $club: Int
    $entryFee: String
    $onsiteEntryFee: Float
    $registerLink: String
    $description: String
    $entryDeadline: DateTime
    $timeZone: String
    $additionalReferee: [Int!]
    $termsAndConditions: String
    $coverPhoto: Upload
    $organizer: Float
    $regulations: Upload
    $website: String
    $registeredUsersInTournament: Boolean
    $closeRegistration: Boolean
    $approvedEntries: Boolean
    $isFeatured: Boolean
    $partnerSearch: Boolean
    $loggedInUser: Boolean
    $withdrawlDeadline: DateTime
    $additionalQuestion: [JSONObject!]
    $currency: String
    $referee: Float
    $status: String
    $videoUrl: String
    $contactUrl: String
    $makePrivate: Boolean
    $password: String
    $enablePracticeCourtsBooking: Boolean
    $enableTransferBooking: Boolean
    $enableLiveStream: Boolean
    $enableLiveScoring: Boolean
    $paymentMethod: String
    $paymentType: String
    $facts: [JSONObject!]
    $logo: Upload
    $poster: Upload
    $resourceTitle: String
    $timeControl: String
    $showTournamentChessManagerCalendar: Boolean
    $localRatedTournament: Boolean
    $fideRatedTournament: Boolean
    $registrationStartDate: DateTime
    $autoConfirmEntries: Boolean
    $isDuprRatingEnabled: Boolean
    $isDuprRequired: Boolean
    $discounts: [JSONObject!]
    $sortRequestBy: String
    $events: String
  ) {
    updateTournament(
      updateTournamentInput: {
        tournamentId: $tournamentId
        id: $id
        title: $title
        logo: $logo
        startDate: $startDate
        description: $description
        entryDeadline: $entryDeadline
        timeZone: $timeZone
        termsAndConditions: $termsAndConditions
        endDate: $endDate
        country: $country
        address: $address
        additionalReferee: $additionalReferee
        referee: $referee
        club: $club
        city: $city
        entryFee: $entryFee
        onsiteEntryFee: $onsiteEntryFee
        registerLink: $registerLink
        coverPhoto: $coverPhoto
        website: $website
        registeredUsersInTournament: $registeredUsersInTournament
        closeRegistration: $closeRegistration
        approvedEntries: $approvedEntries
        partnerSearch: $partnerSearch
        loggedInUser: $loggedInUser
        withdrawlDeadline: $withdrawlDeadline
        organizer: $organizer
        currency: $currency
        additionalQuestion: $additionalQuestion
        status: $status
        videoUrl: $videoUrl
        contactUrl: $contactUrl
        makePrivate: $makePrivate
        password: $password
        enablePracticeCourtsBooking: $enablePracticeCourtsBooking
        enableTransferBooking: $enableTransferBooking
        enableLiveStream: $enableLiveStream
        isFeatured: $isFeatured
        enableLiveScoring: $enableLiveScoring
        paymentMethod: $paymentMethod
        paymentType: $paymentType
        facts: $facts
        poster: $poster
        regulations: $regulations
        resourceTitle: $resourceTitle
        timeControl: $timeControl
        showTournamentChessManagerCalendar: $showTournamentChessManagerCalendar
        localRatedTournament: $localRatedTournament
        fideRatedTournament: $fideRatedTournament
        registrationStartDate: $registrationStartDate
        autoConfirmEntries: $autoConfirmEntries
        isDuprRatingEnabled: $isDuprRatingEnabled
        isDuprRequired: $isDuprRequired
        discounts: $discounts
        sortRequestBy: $sortRequestBy
        events: $events
      }
    ) {
      id
      title
      startDate
      description
      entryDeadline
      timeZone
      termsAndConditions
      regulations
      registeredUsersInTournament
      closeRegistration
      approvedEntries
      partnerSearch
      loggedInUser
      status
      onlinePayments
      endDate
      country
      courts {
        name
      }
      address
      events
    }
  }
`;

export default UPDATE_TOURNAMENT;
