import { gql } from '@apollo/client';

const TOURNAMENT_BY_ID = gql`
  query tournamentDetailById($id: Int!, $userId: Int, $isManager: Boolean) {
    tournamentDetailById(id: $id, userId: $userId, isManager: $isManager) {
      id
      title
      startDate
      description
      entryDeadline
      timeZone
      termsAndConditions
      regulations
      courts {
        id
        name
      }
      discounts {
        discount
        numberOfCategories
        id
      }
      events
      registeredUsersInTournament
      registrationStartDate
      closeRegistration
      isDuprRatingEnabled
      isDuprRequired
      approvedEntries
      partnerSearch
      loggedInUser
      status
      onlinePayments
      autoConfirmEntries
      endDate
      country
      address
      currency
      type
      club {
        id
      }
      city
      entryFee
      onsiteEntryFee
      license
      registerLink
      website
      withdrawlDeadline
      coverPhoto
      regulations
      status
      videoUrl
      contactUrl
      makePrivate
      password
      enablePracticeCourtsBooking
      enableTransferBooking
      enableLiveStream
      enableLiveScoring
      isFeatured
      paymentMethod
      paymentType
      timeControl
      showTournamentChessManagerCalendar
      localRatedTournament
      fideRatedTournament
      logo
      poster
      facts {
        id
        answer
        factSheet {
          id
          fieldTitle
          fieldType
          options
        }
      }
      tournamentCategory {
        category {
          moveToSingles
          name
          type
          gender
          order
          enableMetrics
          team
          isAllowed
          moveToSingles
          categoryEntries
          enableLicense
          status
          ageLimit
          ageType
          id
          license {
            id
            license {
              id
              title
            }
          }
        }
        id
        method
        onlinePayments
        fee
        onsiteFee
        currency
      }
      additionalQuestion {
        id
        question
        fieldType
        fieldOptions
        askEachAthlete
        isMandatory
        tournamentCategories {
          id
          category {
            id
            name
          }
        }
      }
      league {
        league {
          id
          title
          abbreviationLogo
          enableLicense
          sports {
            id
            title
            resourceTitle
          }
        }
      }
      organizer {
        id
        member {
          id
          name
        }
      }
      referee {
        referee {
          id
          name
        }
      }
      additionalReferee {
        referee {
          id
          name
          email
        }
      }
    }
  }
`;

export default TOURNAMENT_BY_ID;
